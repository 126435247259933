export const defaultForm = {
  "payPriceReal": 0,//支付金额
  "orderSource": "",//订单来源(0商城1询比价2联合采购3招标)
  "demandCode": "",//询比价/招标单单号
  "demandName": "",//询比价/招标单名称
  "demandId": "",//询比价/招标单id
  "isInvoice": "2",//是否需要发票1是2否
  "orderType": "",//商品订单类型(1普通订单2拼团订单)
  "addressId": "",//收货地址ID
  "orderLeaveWord": "",//给卖家留言
  "invoiceDTO": {//发票实体
    "invoiceType": "",//发票要求 1增值税普通发票0增值税专用发票
    "invoiceClass": "",//发票种类(1个人，2企业)
    "invoiceTitle": "",//发票标题
    "invoiceRateCode": "",//税号
    "bankName": "",//开户银行
    "bankAccount": "",//银行账户
    "companyAddress": "",//企业地址
    "companyPhone": ""//企业电话

  },
  "shopDTOList": [//店铺列表
    {
      "goodsDTOList": [//商品列表
        {
          "id": "",//商品id
          "skuName": "",//商品名称
          "skuSpec": "",//商品规格
          "productCategoryId": "",//商品分类id
          "productCategoryCode": "",//商品分类code
          "unit": "",//单位
          "skuNum": "",//购买商品数量
          "skuPriceReal": "",//商品价格(元)
          "discountAmount": "",//折扣金额 
        }
      ],
      "orderLeaveWord": "",//留言
      "freightPriceReal": "",//运费(元)
      "shopName": "",//卖家名称
      "shopId": "",//卖家ID
      "memberInfoId": "",//卖家用户ID
      "businessId": "",//卖家企业ID
    }
  ]
}

export const defaultRules = {
  addressId: [
    { required: true, message: '请选择地址', trigger: 'change' },
  ],
  isInvoice: [
    { required: true, message: '请选择是否需要发票', trigger: 'change' },
  ],
  'invoiceDTO.invoiceType': [
    { required: true, message: '请选择发票要求', trigger: 'change' },
  ],
  'invoiceDTO.invoiceClass': [
    { required: true, message: '请选择发票种类', trigger: 'change' },
  ],
  'invoiceDTO.invoiceTitle': [
    { required: true, message: '请输入发票抬头', trigger: 'blur' },
  ],
  'invoiceDTO.invoiceRateCode': [
    { required: true, message: '请输入税号', trigger: 'blur' },
  ],
  orderLeaveWord: [
    { required: true, message: '请输入卖家留言', trigger: 'blur' },
  ]
}

