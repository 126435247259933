// 引入
import topView from '@/components/topView.vue'
import customList from '@/components/list-table/index.vue'
import mDialog from '@/components/dialog/src/mDialog.vue'
import tablePage from '@/components/tablePage/table-page.vue'
import mTree from '@/components/tree/mTree.vue'
import telCode from '@/components/tel-code/index'
import svgBtn from '@/components/svgBtn/index'
//采购商复用页面组件
import orderWater from '@/components/order-components-purcharse/order-water.vue'
import choseAddress from '@/components/order-components-purcharse/chose-address.vue'
import sureConfirmOrder from '@/components/order-components-purcharse/sure-confirm-order/index.vue'
import surePay from '@/components/order-components-purcharse/sure-pay/index.vue'
import orderDetail from '@/components/order-components-purcharse/order-detail/index.vue'
import refundDetail from '@/components/order-components-purcharse/order-detail/refund.vue'
import corporateTransfer from '@/components/order-components-purcharse/corporate-transfer/index.vue'
import paySuccess from '@/components/order-components-purcharse/pay-success/index.vue'
import scrabbleDough from '@/components/order-components-purcharse/scrabbleDough/index.vue'
import scrabbleDoughCountdown from '@/components/order-components-purcharse/scrabbleDoughCountdown/index.vue'
//供应商复用页面组件
import orderDetailSupplier from '@/components/order-components-supplier/order-detail/index.vue'
import refundDetailSupplier from '@/components/order-components-supplier/order-detail/refund.vue'
import pageTopTitle from '@/components/pageTopTitle/page-top-title'
import breadcrumb from '@/components/breadcrumb'
import UploadAttachmentFileOss from '@/components/mUpload/UploadAttachmentFileOss'
import UploadAttachmentFileOssSingle from '@/components/mUpload/UploadAttachmentFileOssSingle'
import UploadAttachmentFileOssSingleImage from '@/components/mUpload/UploadAttachmentFileOssSingleImage'
import UploadAttachmentFileOssSinglePro from '@/components/mUpload/UploadAttachmentFileOssSinglePro'
import UploadAttachmentFileOssSingleFile from '@/components/mUpload/UploadAttachmentFileOssSingleFile'
import TitleBar from '@/components/pageTopTitle/title-bar'



export default {
  install (Vue) {
    // 注册全局组件
    Vue.component('topView', topView)
    Vue.component('customList', customList)
    Vue.component('mDialog', mDialog)
    Vue.component('tablePage', tablePage)
    Vue.component('mTree', mTree)
    Vue.component('telCode', telCode)
    Vue.component('svgBtn', svgBtn)
    Vue.component('orderWater', orderWater)
    Vue.component('choseAddress', choseAddress)
    Vue.component('sureConfirmOrder', sureConfirmOrder)
    Vue.component('surePay', surePay)
    Vue.component('orderDetail', orderDetail)
    Vue.component('refundDetail', refundDetail)
    Vue.component('corporateTransfer', corporateTransfer)
    Vue.component('pay-success', paySuccess)
    Vue.component('orderDetailSupplier', orderDetailSupplier)
    Vue.component('refundDetailSupplier', refundDetailSupplier)
    Vue.component('pageTopTitle', pageTopTitle)
    Vue.component('scrabbleDough', scrabbleDough)
    Vue.component('scrabbleDoughCountdown', scrabbleDoughCountdown)
    Vue.component('breadCrumb', breadcrumb)
    Vue.component('UploadAttachmentFileOss', UploadAttachmentFileOss)
    Vue.component('UploadAttachmentFileOssSingle', UploadAttachmentFileOssSingle)
    Vue.component('UploadAttachmentFileOssSingleImage', UploadAttachmentFileOssSingleImage)
    Vue.component('UploadAttachmentFileOssSinglePro', UploadAttachmentFileOssSinglePro)
    Vue.component('UploadAttachmentFileOssSingleFile', UploadAttachmentFileOssSingleFile)
    Vue.component('TitleBar', TitleBar)
  }
}