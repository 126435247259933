import request from '@/utils/request'
/**
 * 文件上传前调用
 * @param data
 * @returns {AxiosPromise}
 */
export const ossStsRegister = (data) => {
  return request({
    url: '/api/huizhuyun-support/upload/resource/sts/register',
    method: 'post',
    data: data
  })
}

/**
 * 文件上传后回调
 * @param data
 * @returns {AxiosPromise}
 */
export const ossStsRegisterCallback = (data) => {
  return request({
    url: '/api/huizhuyun-support/upload/resource/sts/register/callback',
    method: 'post',
    params: {
      id: data
    }
  })
}

export const commonBusinessExport = (url, data) => {
  return request({
    url: url,
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

/**
 * 获取业务附件列表
 * @param params 查询条件
 */
export const getAttachmentBusinessList = (params) => {
  return request({
    url: '/api/huizhuyun-management/oa-common-attachment/list/business',
    method: 'get',
    params: {
      ...params
    }
  })
}
