<template>
  <div class="hello">
    <div id="container">
 
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      myIcon:'./pin.png'
    };
  },
  mounted(){
    this.mapdata();
  },
  methods: {
    mapdata() {
      var that=this
      var map = new BMap.Map("container",{ enableMapClick: false});// 创建地图实例
      new BMap.Geolocation().getCurrentPosition(function(r){
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          //通过Geolocation类的getStatus()可以判断是否成功定位。
          var point = new BMap.Point(r.point.lng, r.point.lat); // 创建点坐标  
          map.centerAndZoom(point, 15);// 初始化地图，设置中心点坐标和地图级别
          map.enableScrollWheelZoom(true);//开启鼠标滚轮缩放  
          var pt=r.point
          var icon = new BMap.Icon("./markers.png", new BMap.Size(300,157), {  
              anchor: new BMap.Size(10, 30)  
          });
          var mkr =new BMap.Marker(new BMap.Point(r.point.lng, r.point.lat), {  
              icon: icon  
          });  
          mkr.setIcon(icon);
          map.addOverlay(mkr); 
          new BMap.Geocoder().getLocation(pt, (rs)=>{
              // var addComp = rs.address; 
                var addComp=rs.surroundingPois[0].address
              var city=rs.addressComponents.city
              var district=rs.addressComponents.district
              var province=rs.addressComponents.province
              var street=rs.addressComponents.street
              var streetNumber=rs.addressComponents.streetNumber
          var sContent =
          `<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>${addComp}
          <div style="padding: 10Px 20Px;margin: 10Px;line-height:18Px;color: white;background-color: #0079FE;text-align:center"  
          id="btn_test"
          city="${city}" district="${district}" province="${province}" street="${street}" streetNumber="${streetNumber}" title="${addComp}">确定</div></p>`;
          var infoWindow = new BMap.InfoWindow(sContent);  // 创建信息窗口对象
          setTimeout(() => {
            document.getElementById('btn_test').onclick=function () {
              var val={}
              val.province=province;
              val.city=city;
              val.district=district;
              val.street=street;
              val.streetNumber=streetNumber;
              val.addComp=addComp;
              that.$emit('change', val);
          }},300)
          map.openInfoWindow(infoWindow,point);
        }); 
          
        }

      },{ enableHighAccuracy: true }

    );
     map.addEventListener('click', function(e) {
      map.clearOverlays()
        var point = new BMap.Point(e.point.lng, e.point.lat)
        var icon = new BMap.Icon("./markers.png", new BMap.Size(300,157), {  
              anchor: new BMap.Size(10, 30)  
          });
          var mkr =new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat), {  
              icon: icon  
          });  
          mkr.setIcon(icon);
          map.addOverlay(mkr);
            new BMap.Geocoder().getLocation(point, (rs)=>{
              var addComp=rs.surroundingPois[0].address;
              var city=rs.addressComponents.city;
              var district=rs.addressComponents.district;
              var province=rs.addressComponents.province;
              var street=rs.addressComponents.street;
              var streetNumber=rs.addressComponents.streetNumber;
          var sContent =
          `<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>${addComp}
          <div style="padding: 10Px 20Px;margin: 10Px;line-height:18Px;color: white;background-color: #0079FE;text-align:center" id="btn_test"
          title="${addComp}">确定</div></p>`;
          var infoWindow = new BMap.InfoWindow(sContent);  // 创建信息窗口对象
          setTimeout(() => {
            document.getElementById('btn_test').onclick=function () {
              var val={}
              val.province=province;
              val.city=city;
              val.district=district;
              val.street=street;
              val.streetNumber=streetNumber;
              val.addComp=addComp;
              that.$emit('change', val);
              }},300)
          map.openInfoWindow(infoWindow,point);
        }); 
    }); 
  }
}
}
</script>
 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  margin: 0 auto;
}
#container {
  width: 100%;
  height: 480px;
}
 
</style>