<template>
    <!-- v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)" -->
    <el-dialog class="el-dialog-cus" :class="b()" v-dialogDrag :modal-append-to-body="false" :modal="config.modal" v-bind="configNew"
        :visible="visible" :before-close="beClose" :close-on-click-modal="false" v-on="on">
        <div class="dialog-content-ovh">
            <div class="dialog-content" :style="{ 'min-height': configNew.minHeight }">
                <slot v-if="visibleSlot"></slot>
            </div>
        </div>
        <div slot="footer">
            <el-button v-if="configNew.btnText[0]" type="info" :size="$Hzy.size" round @click="cancel" plain>{{
                    configNew.btnText[0]
            }}</el-button>
            <el-button ref="submit"  :disabled="isSubmitting" v-if="configNew.btnText[1]" :size="$Hzy.size" round @click="confirm"
                type="primary">{{ configNew.btnText[1] }}
            </el-button>
            <slot name="footerbtn"></slot>
        </div>
    </el-dialog>
</template>

<script>
import { KEY_COMPONENT_NAME } from "../../../global/variable";
import bem from "../../../utils/bem";
/** 示例
 * <m-dialog ref="dialog">内容</m-dialog>
 * this.$refs.dialog.open((cancel, loading) => {
        loading() //调用遮罩层
        setTimeout(()=>{
            cancel(); // 手动执行关闭弹窗， 也配置成自动关闭
        },3000)
    });
 */

export default {
    name: KEY_COMPONENT_NAME + "Dialog",
    inheritAttrs: false,
    mixins: [bem],
    props: {
        isSubmitting: {
            //是否点击确定后，自动关闭弹窗
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => { },
        },
        autoClose: {
            //是否点击确定后，自动关闭弹窗
            type: Boolean,
            default: false,
        },
        beforeClose: {
            //关闭按钮-关闭前
            type: Function,
            default: () => { },
        },
    },
    data() {
        return {
            visible: false,
            attributes: { // 配置项包含el-dialog所有Attributes的配置
                top: "20vh",
                title: "提示",
                width: "70%",
                minHeight: "600px",
                center: false,
                btnText: ["取消", "保存"],
                modal: true
            },
            loading: false,
            on: this.getDialogEvents(),
            visibleSlot: false,
        };
    },
    computed: {
        configNew() {
            return Object.assign(this.attributes, this.config);
        },
    },
    methods: {
        //显示
        open(ok) {
            this.ok = ok || function () { };
            this.visible = true;
            this.visibleSlot = true; // v-if控制是否渲染,防止嵌套的组件生命周期只会执行一次
        },
        //关闭
        cancel() {
            this.visible = false;
            this.beforeClose();
            this.loading = false;
        },
        // 确定或提交按钮时触发的
        confirm() {
            // this.loading = true;
            let cancel = () => this.cancel();
            let loadingBg = () => this.loadingBg();
            this.ok(cancel, loadingBg);
            this.autoClose && cancel();
        },
        //弹窗组件的关闭前
        beClose(done) {
            if (this.visible) {
                done();
                this.cancel();
            }
        },
        // 防重提
        loadingBg() {
            this.loading = false
        },
        getDialogEvents() {
            // closed: () => this.visibleSlot = false是为了防止弹窗中的内容先于弹窗消失而造成弹窗在关闭时有一个突然向上缩小的情况
            let { close } = this.config || {},
                events = { closed: () => (this.visibleSlot = false) };

            if (close && typeof close == "function") {
                Object.assign(events, {
                    close,
                });
            }
            return events;
        },

    },
};
</script>
<style lang="scss">
.el-dialog{
    border-radius: 8px;
}
.el-dialog__title {
    line-height:50px;
    font-size: 24px;
    font-weight: 400;
    color: #212121;
}
.el-dialog-cus {

    // .el-dialog__header {
    //     border-bottom: 1px solid rgb(233, 233, 233);
    // }
    .dialog-content-ovh {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .dialog-content {
            max-height: 83vh;
            width: 100%;
            padding-right: 8px;
            overflow: auto;
            box-sizing: border-box;
        }
    }

    .el-dialog__body {
        box-sizing: border-box;
        padding: 12px 16px;
        letter-spacing: 0.5px;
    }

    .el-dialog__footer {
        .el-button {
            padding: 10px 30px;
        }
    }
}
</style>