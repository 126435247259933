import request from '@/utils/request'
// 添加分账账号
export function profitsharingaddreceiver(type,account,relationType,name) {
    return request({
        url: `/api/rs-fund-service/ecomm/wx/profitsharingaddreceiver/${type}/${account}/${relationType}/${name}`,
        method: 'get'
    })
}


// 删除分账账号
export function profitRemove() {
    return request({
        url: `/api/rs-fund-service/ecomm/wx/profitRemove`,
        method: 'get'
    })
}



// 删除分账账号
export function getSplitDetail() {
    return request({
        url: `/api/rs-fund-service/ecomm/wx/getSplitDetail`,
        method: 'get'
    })
}

// 获取微信商户类型字典
export function getWxCategorycode() {
    return request({
        url: '/api/rs-cms-service/ecomm/front/dict-biz/list?code=wx_relation_type',
        method: 'get',
    })
}

// 获取微信商户类型字典
export function showSellAccount(data) {
    return request({
        url: '/api/rs-fund-service/ecomm/wx/showSellAccount',
        method: 'post',
        data: data
    })
}