import Vue from 'vue'
import Vuex from 'vuex'
import permission from './modules/permission'
import go from './modules/go'
import sys from './modules/sys'


Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    roles: ['admin'],
    params: {},
    platformConfigData: null
  },
  mutations: {
    SET_PARAMS: (state, params) => {
      state.params = { ...params }
      window.localStorage.setItem('TheParams', JSON.stringify(params))
    },
    SET_PLATFORMCONFIGDATA(state, val) {
      state.platformConfigData = val
    },
  },
  modules: {
    permission,
    go,
    sys
  }
})

export default store
