import request from '@/utils/request'

//分页
export function getList (data) {
  return request({
    url: '/api/rs-cms-service/ecomm/ecomCenterNotice/list',
    method: 'get',
    params: data
  })
}

//详情
export function getDetail (data) {
  return request({
    url: '/api/rs-cms-service/ecomm/ecomCenterNotice/detail',
    method: 'get',
    params: data
  })
}

//消息通知-邀请详情-审批
export function getApproval(data) {
    return request({
        url: '/api/rs-member-service/ecomm/businessMember/approval',
        method: 'get',
        params: data
    })
}

//消息通知-未读数量
export function getNoReadCount (data) {
  return request({
    url: '/api/rs-cms-service/ecomm/ecomCenterNotice/noReadCount',
    method: 'get',
    params: data
  })
}

export function platformSiteList () {
  return request({
    url: '/api/rs-cms-service/ecomm/front/platformConfig/list',
    method: 'get',
  })
}
export function getNavigateTree (requestSource) {
  return request({
    url: '/api/rs-cms-service/ecomm/front/cmsNewsArticle/navigateTree?menuCode=INDUSTRY_NAVIGATE_FOOT',
    method: 'get',
    headers: {'RequestSource': requestSource},
  })
}