/**
 * 判断对象、数组、字符串是否为空
 */
export function validatenull(val) {
  if (val instanceof Date || typeof val === 'boolean' || typeof val === 'number') return false;
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    for (var o in val) {
      return false;
    }
    return true;
  } else {
    if (
      val === 'null' ||
      val == null ||
      val === 'undefined' ||
      val === undefined ||
      val === ''
    ) {
      return true;
    }
    return false;
  }
  return false;
}

/**
 * 比较两个时间大小
 * @param {date1,date2} 
 * date1是否大于data2.是则返回true
 */

export function compDateTime(date1, date2) {
  var oDate1 = new Date(date1);
  var oDate2 = new Date(date2);
  if (oDate1.getTime() > oDate2.getTime()) {
    return true
  } else {
    return false
  }
}


/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}


/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}


/**
 * 判断手机号码是否正确
 */
export function isMobile(phone) {
  let obj = {
    status: true,
    msg: ''
  };
  var isPhone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  //增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]  
  if (!validatenull(phone)) {
    if (phone.length == 11) {
      if (!isPhone.test(phone)) {
        obj.msg = '手机号码格式不正确';
        obj.status = false;
      }
    } else {
      obj.msg = '手机号码长度不为11位';
      obj.status = false;
    }
  } else {
    obj.msg = '手机号码不能为空';
    obj.status = false;
  }

  return obj;
}

/**
 * 判断是否为整数
 */
export function validatenum(num, type) {
  let regName = /[^\d.]/g;
  if (type == 1) {
    if (!regName.test(num)) return false;
  } else if (type == 2) {
    regName = /[^\d]/g;
    if (!regName.test(num)) return false;
  }
  return true;
}

/**
 * 判断是否为小数
 */
export function validatenumord(num, type) {
  let regName = /[^\d.]/g;
  if (type == 1) {
    if (!regName.test(num)) return false;
  } else if (type == 2) {
    regName = /[^\d.]/g;
    if (!regName.test(num)) return false;
  }
  return true;
}

/**
 * 验证不可输入特殊字符
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export function checkInputString(rule, value, callback) {
  if (value) {
    if (value.match(/^[\u4E00-\u9FA5A-Za-z0-9_]+?$/)) {
      callback()
    } else {
      callback(new Error('不可输入特殊字符'))
    }
  } else {
    callback()
  }
}

/**
 * 字符串数据类型转化
 */
export const detailDataType = (value, type) => {
  if (validatenull(value)) return value
  if (type === 'number') {
    return Number(value);
  } else if (type === 'string') {
    return value + '';
  } else {
    return value;
  }
};

