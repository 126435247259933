<template>
    <div :class="b('pagination')">
        <div class="total">共 {{ defaultPage.total }} 项数据</div>
        <el-pagination :disabled="defaultPage.disabled" :hide-on-single-page="defaultPage.simplePage"
            :pager-count="defaultPage.pagerCount" :current-page.sync="defaultPage.currentPage"
            :background="defaultPage.background" :page-size="defaultPage.pageSize" :page-sizes="defaultPage.pageSizes"
            @size-change="sizeChange" @prev-click="prevClick" @next-click="nextClick" @current-change="currentChange"
            :layout="defaultPage.layout" :total="defaultPage.total"></el-pagination>
    </div>
</template>

<script>
import { KEY_COMPONENT_NAME } from "../../global/variable";
import bem from "../../utils/bem";
export default {
    name: KEY_COMPONENT_NAME + "pagination", // table 分页
    mixins: [bem],
    props: {
        page: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            defaultPage: {
                total: 0, // 总页数
                pagerCount: 7, //超过多少条隐藏
                currentPage: 1, // 当前页数
                pageSize: 10, // 每页显示多少条
                pageSizes: [10, 20, 30, 40, 50, 100],
                layout: "sizes, prev, pager, next",
                simplePage: false,
                background: true, // 背景颜色
            },
        };
    },
    created() {
        this.pageInit();
    },
    watch: {
        page: {
            handler() {
                this.pageInit();
            },
            deep: true,
        },

        //如果当前页面删除没数据了调用第一页
        "defaultPage.total"(val) {
            if (
                this.defaultPage.total ===
                (this.defaultPage.currentPage - 1) *
                this.defaultPage.pageSize &&
                this.defaultPage.total != 0
            ) {
                this.defaultPage.currentPage = this.defaultPage.currentPage - 1;
                this.$emit("current-change", this.defaultPage.currentPage);
                this.updateValue();
            }
        },
    },

    methods: {
        pageInit() {
            this.defaultPage = Object.assign(this.defaultPage, this.page);
            this.updateValue();
        },
        updateValue() {
            this.$emit("update:page", this.defaultPage);
        },
        //下一页事件
        nextClick(val) {
            this.$emit("next-click", val);
        },
        //上一页事件
        prevClick(val) {
            this.$emit("prev-click", val);
        },
        // 页大小回调
        sizeChange(val) {
            console.log('[ val ]-105', val)
            this.defaultPage.currentPage = 1;
            this.defaultPage.pageSize = val;
            this.updateValue();
            this.$emit("size-change", val);
        },
        // 页码回调
        currentChange(val) {
            console.log('[ val ]-113', val)
            this.updateValue();
            this.$emit("current-change", val);
        },
    },
};
</script>
<style lang="scss" scoped>
.m-pagination__pagination {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .total {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
    }

    // ::v-deep .el-pagination.is-background .el-pager li {
    //     color: rgba(0, 0, 0, 0.6);
    // }
    // ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    //     background-color: $blue;
    // }
}
</style>