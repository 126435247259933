import router from './router'
import store from './store'
import { getToken, clearCookie } from '@/utils/auth'
import { TheUrl } from '@/main.js'
import {
  login, goLogin
  // , choseIdent
  , queryMenuList
} from '@/api/login'
import { setMemberToken, setSwitchToCompanyId, setToken, setUserInfo, setHuizhuyunToken } from '@/utils/auth';
import { getQueryString } from '@/utils/util.js'
import { menuList } from '@/views/menu'
import md5 from 'js-md5'
import {
  getUserInfo
} from '@/api/userinfo/userinfo'

const whiteList = ['/404', '/login', '/register', '/forget'] //未登录白名单
const noLoginList = ['/Purchaser/findDevices/index', '/Purchaser/findDevices/index',
  '/Purchaser/findProject/index', '/Purchaser/preferentialInquiry/index', '/Purchaser/groundMaterialMap/index']

let hasPermission = (resRoles, metaRoles) => {
  if (resRoles.length == 0 || resRoles.includes('admin') || metaRoles == undefined) { //用户身份为超级管理员或者随意访问页面时
    return false
  } else { //用户身份权限有限且浏览本身无权访问页面时
    let ins = new Set([...resRoles].filter(x => { metaRoles.has(x) }))
    if (Array.from(ins).length == 0) {
      return true
    } else {
      return false
    }
  }
}

router.beforeEach(async (to, from, next) => {

  if (to.fullPath == '/handleLogout') {
    next()
    return
  }

  // 百度统计
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
     }
  }

  let login_access_token = getCookie('login_access_token');

  if (getToken() && getToken() == login_access_token && window.localStorage.getItem('menu')) {
    if (to.path === '/login' || to.path === '/register') {
      next({ path: '/' })
    } else {
      if (hasPermission(store.state.roles, to.meta.roles)) { //访问各种情况权限页面
        next({ path: '/404', replace: true })
      } else {
        if (to.fullPath.includes('isLogin')) {
          // let params = getUrlParams(to.fullPath)
          let params = JSON.parse(getCookie('params'));
          window.localStorage.setItem('params', JSON.stringify(params));
          next()
        } else {
          next()
        }
      }
    }
  } else {
    
    if (whiteList.indexOf(to.path) !== -1) {
      next()
      return;
    }

    try {
      let huizhuyun_token = getCookie('huizhuyun_token');
      let login_access_token = getCookie('login_access_token');
      let login_dept_id = getCookie('login_dept_id');
      let params = JSON.parse(getCookie('params') || '{}');
      window.localStorage.setItem('params', JSON.stringify(params));
      setMemberToken(params.chooseIdx)
      if(!login_access_token || !login_dept_id) {
        const res = await goLogin({
          tenantId: params.choseCom,
          username: params.username,
          password: params.password,
          grant_type: 'password',
          scope: 'all',
          type: 'account',
          identityCode: params.chooseIdx
        })
        login_access_token = res.access_token
        login_dept_id = res.dept_id
      }
      setToken(login_access_token)
      setSwitchToCompanyId(login_dept_id)
      setHuizhuyunToken(huizhuyun_token)

      if (to.path == '/commons/user/new-vip') {
        if (getToken() && getToken() == login_access_token) {
          next()
        } else {
          clearCookie();
          window.localStorage.clear();
          window.open(`${process.env.VUE_APP_SHARE_FRONT_WEB_URL}/login?target=${encodeURIComponent(`${window.location.href}`)}`, '_self')
          return
        }
      }
    }
    catch(e) {
      console.error(e);
      if(getQueryString('target')){
        window.location.href = TheUrl+getQueryString('target')
      }else{
        window.location.href = TheUrl
      }
      
    }

    //防止页面显示 但是userInfo无值   
    getUserInfo().then(userInfoRes => {
      setUserInfo(JSON.stringify(userInfoRes.data))
      queryMenuList().then(menuListRes => {
        if(!menuListRes.success) {
          return
        }
        const menu = menuList //上线换成 menuListRes
        window.localStorage.setItem("menu", JSON.stringify(menu));
        window.localStorage.setItem("showMenu", JSON.stringify(menuListRes.data));
        store.commit("SET_ROUTES", menu);
        next(to.fullPath)
      })
    })

    // if (to.fullPath.includes('isLogin')) {
    //   let params = getUrlParams(to.fullPath)
    //   window.localStorage.setItem('params', JSON.stringify(params));
    //   setMemberToken(params.chooseIdx)
    //   login({ 'username': params.username, 'password': params.password })
    //   goLogin({
    //     tenantId: params.choseCom,
    //     username: params.username,
    //     password: params.password,
    //     grant_type: 'password',
    //     scope: 'all',
    //     type: 'account',
    //     identityCode: params.chooseIdx
    //   }).then(async res => {
    //     // getUserInfo().then(res => {
    //     //   setUserInfo(JSON.stringify(res.data))
    //     // })     

    //     setToken(res.access_token)
    //     setSwitchToCompanyId(res.dept_id)
    //     //防止页面显示 但是userInfo无值   
    //     const userInfoRes = await getUserInfo()
    //     setUserInfo(JSON.stringify(userInfoRes.data))
    //     window.localStorage.removeItem("showMenu")
    //     queryMenuList().then(menuListRes => {
    //       const menu = menuList //上线换成 menuListRes
    //       window.localStorage.setItem("menu", JSON.stringify(menu));
    //       window.localStorage.setItem("showMenu", JSON.stringify(menuListRes.data));
    //       store.commit("SET_ROUTES", menu);
    //       next(to.fullPath)
    //     })
    //   });
    // } else {
    //   if (to.fullPath !== '/') {
    //     window.open(`${TheUrl}/preferentialInquiry?ihl=1`, '_self')
    //   }
    //   if (noLoginList.indexOf(to.path) !== -1) {
    //     setMemberToken(1)
    //     login({ 'username': '15555555555', 'password': md5('123456') })
    //     goLogin({
    //       tenantId: '411673',
    //       username: '15555555555',
    //       password: md5('123456'),
    //       grant_type: 'password',
    //       scope: 'all',
    //       type: 'account',
    //       identityCode: 1
    //     }).then(res => {
    //       let params = {
    //         isLogin: '111',
    //         username: '15555555555',
    //         password: md5('123456'),
    //         chooseIdx: '1',
    //         choseCom: '411673',
    //         userstatus: ''
    //       }
    //       window.localStorage.setItem('params', JSON.stringify(params));
    //       getUserInfo().then(res => {
    //         setUserInfo(JSON.stringify(res.data))
    //       })
    //       setToken(res.access_token)
    //       setSwitchToCompanyId(res.dept_id)
    //       window.localStorage.removeItem("showMenu")
    //       queryMenuList().then(menuListRes => {
    //         const menu = menuList //上线换成 menuListRes
    //         window.localStorage.setItem("menu", JSON.stringify(menu));
    //         window.localStorage.setItem("showMenu", JSON.stringify(menuListRes.data));
    //         store.commit("SET_ROUTES", menu);
    //         next(to.fullPath)
    //       })
    //     });
    //   } else {
    //     if (whiteList.indexOf(to.path) !== -1) {
    //       next()
    //     } else {
    //       if (to.fullPath == '/handleLogout') {
    //         window.open(
    //           `${TheUrl}/preferentialInquiry?ihl=1&handleLogout=true`,
    //           '_self'
    //         )
    //       }
    //     }
    //   }
    // }
  }
})

function getUrlParams(url) {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split('?')[1]
  // 创建空对象存储参数
  let obj = {};
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split('&')
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split('=')
    obj[arr[0]] = arr[1];
  }
  return obj
}


function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
}

router.afterEach((to, from, next) => {
})
