<template>
    <div :class="b()">
        <span class="block1"></span>
        <span class="block2"></span>
        <div class="title">
            {{ name }}
            <span class="number" v-if="number">{{ number }}</span>
            <span class="more" v-if="moreShow" @click="more">{{ moreText }}</span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
// name > components > mixins > props > data > computed > watch > filter
import { KEY_COMPONENT_NAME } from "../../global/variable";
import bem from "../../utils/bem";
export default {
    name: KEY_COMPONENT_NAME + "page-top-title", // 页面上方title
    components: {},
    mixins: [bem],
    props: {
        name: {
            type: String,
        },
        number: { // 单号
            type: String,
        },
        moreShow: {
            type: Boolean,
            default: (() => {
                return false
            })
        },
        moreText: {
            type: String,
            default: (() => {
                return '查看更多>>'
            })
        },
    },
    data() {
        return {};
    },
    methods: {
        // 查看更多
        more() {
            this.$emit('moreClick')
        }
    }
};
</script>

<style lang='scss' scoped>
.m-page-top-title {
    width: 100%;

    .block1 {
        display: inline-block;
        width: 37px;
        height: 7px;
        background-color: rgba(0, 121, 254, 1);
        border: none;
        border-radius: 10px;
    }

    .block2 {
        display: inline-block;
        margin-left: 5px;
        width: 8px;
        height: 8px;
        background-color: rgba(0, 121, 254, 1);
        border-radius: 100%;
    }

    .title {
        position: relative;
        font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 26px;
        margin-top: 5px;
        margin-bottom: 17px;
    }

    .number {
        font-size: 13px;
        color: $info;
        margin-left: 5px;
    }

    .more {
        position: absolute;
        right: 5px;
        font-size: 14px;
        color: #0079FE;
        font-weight: normal;
        cursor: pointer;
    }
}
</style>
