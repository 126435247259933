export function initData () {
  return {
    txtArray: [],
    orderCode: "",
    price: "",
    datalist: [
      {
        value: "银行卡号",
        label: "1109 1426 6410 707",
      }, {
        value: "开户银行",
        label: "招商银行股份有限公司沈阳分行营业部",
      }, {
        value: "订单号",
        label: "JD252891485754",
      }]
  }
}