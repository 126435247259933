<template>
  <div>
    <page-top-title name="确认下单"></page-top-title>
    <order-water :txtArray="txtArray" />
    <div class="box">
      <el-form
        :model="invoiceForm"
        ref="invoiceForm"
        :rules="rules"
        :inline="false"
      >
        <el-form-item label="" prop="addressId">
          <chose-address
            v-if="skuList.length > 0"
            v-model="invoiceForm.addressId"
            @chose="choseAddress"
          />
        </el-form-item>
        <el-form-item label="" prop="isInvoice">
          <el-row class="supplymode-menu" type="flex">
            <el-col
              :class="invoiceForm.isInvoice == '2' ? 'active' : ''"
              @click.native="noInvoice"
              >无需发票</el-col
            >
            <el-col
              :class="invoiceForm.isInvoice == '1' ? 'active' : ''"
              @click.native="openInvoice"
              >开具发票</el-col
            >
          </el-row>
        </el-form-item>
        <div v-if="invoiceForm.isInvoice == '1'" class="box">
          <el-form-item label="发票要求" prop="invoiceDTO.invoiceType">
            <el-select
              v-model="invoiceForm.invoiceDTO.invoiceType"
              placeholder="请选择发票要求"
              clearable
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="invoiceDTO.invoiceClass">
            <el-row class="supplymode-menu" type="flex">
              <el-col
                :class="
                  invoiceForm.invoiceDTO.invoiceClass == 2 ? 'active' : ''
                "
                @click.native="choseCom"
                >企业</el-col
              >
              <el-col
                :class="
                  invoiceForm.invoiceDTO.invoiceClass == 1 ? 'active' : ''
                "
                @click.native="chosePerson"
                >个人</el-col
              >
            </el-row>
          </el-form-item>
          <div style="height: 10px"></div>
          <div v-if="invoiceForm.invoiceDTO.invoiceClass == 2">
            <el-form
              :model="invoiceForm"
              ref="invoiceFormInline"
              :rules="rules"
              :inline="true"
            >
              <el-form-item label="发票抬头 : " prop="invoiceDTO.invoiceTitle">
                <el-input
                  v-model="invoiceForm.invoiceDTO.invoiceTitle"
                  placeholder="请输入发票抬头"
                ></el-input>
              </el-form-item>
              <el-form-item label="税号 : " prop="invoiceDTO.invoiceRateCode">
                <el-input
                  v-model="invoiceForm.invoiceDTO.invoiceRateCode"
                  placeholder="请输入税号"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="企业地址 : "
                prop="invoiceDTO.companyAddress"
              >
                <el-input
                  v-model="invoiceForm.invoiceDTO.companyAddress"
                  placeholder="请输入企业地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业电话 : " prop="invoiceDTO.companyPhone">
                <el-input
                  v-model="invoiceForm.invoiceDTO.companyPhone"
                  placeholder="请输入企业电话"
                ></el-input>
              </el-form-item>
              <el-form-item label="开户银行 : " prop="invoiceDTO.bankName">
                <el-input
                  v-model="invoiceForm.invoiceDTO.bankName"
                  placeholder="请输入开户银行"
                ></el-input>
              </el-form-item>
              <el-form-item label="银行账户 : " prop="invoiceDTO.bankAccount">
                <el-input
                  v-model="invoiceForm.invoiceDTO.bankAccount"
                  placeholder="请输入银行账户"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="invoiceForm.invoiceDTO.invoiceClass == 1">
            <el-form-item label="发票抬头 : " prop="invoiceDTO.invoiceTitle">
              <el-input
                v-model="invoiceForm.invoiceDTO.invoiceTitle"
                placeholder="请输入发票抬头"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="">
          <div style="margin: 10px">
            <div class="flex-table borderMain">
              <div>商品名称</div>
              <div>规格-型号</div>
              <div>数量</div>
              <div>单价（元）</div>
              <div>合计（元）</div>
            </div>
            <div v-for="(i, d) in invoiceForm.shopDTOList" :key="d">
              <div class="bord-bottom">{{ i.shopName }}</div>
              <div>
                <div
                  v-for="(v, k) in i.goodsDTOList"
                  :key="k"
                  class="flex-table"
                >
                  <div
                    :class="isWidthEnough ? 'name-box' : 'name-box-small'"
                    style="margin-left: -20px"
                  >
                    <div><img :src="v.fileUrl" @error="dealErr($event)" /></div>
                    <div class="txt">{{ v.skuName }}</div>
                  </div>
                  <div>{{ v.skuSpec }}</div>
                  <div>{{ v.skuNum }}</div>
                  <div>{{ v.skuPriceReal?v.skuPriceReal:'' }}</div>
                  <div>{{ v.skuPriceReal?(v.skuPriceReal * v.skuNum).toFixed(2):'' }}</div>
                </div>
              </div>
              <div class="box">
          <div class="title">给卖家留言 :</div>
          <div>
            <el-form-item label="">
              <el-input
                v-model="i.orderLeaveWord"
                placeholder="选填，字数不可多于500"
                type="textarea"
                maxlength="500"
                show-word-limit
                rows="7"
              ></el-input>
            </el-form-item>
          </div>
        </div>
            </div>
          </div>
        </el-form-item>

        
      </el-form>

      <div class="box">

        <div class="flex">
          <div style="align-self: flex-start;">
            <div class="">
              <div style="color: #303133;font-size: 14px;margin: 12px 0;">优惠券</div>
              <div>
                <el-select v-model="curVoucher" placeholder="请选择优惠券" @change="voucherChange">
                  <el-option
                    v-for="item in voucherList"
                    :key="item.id"
                    :label="item.voucherName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>

            </div>
            <div v-if="addressVo.province" style="margin-top: 20px;">
              <div class="title" style="margin-left: 0;">
                寄送至：{{ addressVo.province }}-{{ addressVo.city }}-{{
                  addressVo.district
                }}-{{ addressVo.address || "暂未选定" }}
              </div>
              <div class="title" style="margin-left: 0;">
                收货人：{{ addressVo.name || "暂未选定" }}
                {{ addressVo.contactPhone || "暂未选定" }}
              </div>
            </div>
          </div>
          <div>
            <div class="backBox">
              <div class="flex-end title">
                <div>商品金额(元)：</div>
                <div class="">￥ {{ orderResData.sellingPrice }}</div>
              </div>
              <div class="flex-end title">
                <div>运费金额(元)：</div>
                <div class="">￥ {{ orderResData.carriage }}</div>
              </div>
              <div v-if="curVoucherModel&&curVoucherModel.id!=-999" class="flex-end title">
                <div>{{curVoucherModel.voucherName}}抵扣(元)：</div>
                <div class="" style="color: #EA3553;">-￥ {{ calculateDiscountVal }}</div>
                <div style="color: #888;margin-left: 5px;">(可用余额: {{ highPrecisionReduce(curVoucherModel.balance, calculateDiscountVal)}})</div>
              </div>
              <!-- <div class="flex-end title">
                <div>折扣金额(元)：</div>
                <div class="">￥ {{ (+discountAmount || 0).toFixed(2) }}</div>
              </div> -->
              <!-- <div class="flex-end title">
                <div>折扣后金额(元)：</div>
                <div class="">￥ {{ (+(totalPrice - discountAmount) || 0).toFixed(2) }}</div>
              </div> -->
              <div class="flex-end title">
                <div style="line-height: 36px">实付款(元)：</div>
                <div class="orange">
                  ￥ {{ newPayPriceReal }}
                </div>
              </div>
            </div>
            <div class="flex">
              <div></div>
              <el-button type="primary" @click="go">确认下单</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { submitOrder, choseAddress, calOrderData, calculateDiscount } from "@/api/purchaser/myBidding";
import { defaultRules, defaultForm } from "./utils/default";
import def from "./utils/prosses";
export default {
  data() {
    return {
      skuList: [],
      txtArray: [],
      invoiceForm: { ...defaultForm },
      options: [
        // { value: 2, label: "无需发票" },
        { value: 1, label: "增值税普通发票" },
        { value: 0, label: "增值税专用发票" },
      ],
      rules: { ...defaultRules },
      isWidthEnough: true,
      addressVo: {},
      freightPrice: 0,
      choseProvinceCode: "",
      totalPrice: 0,
      discountAmount: 0, // 折扣金额
      orderResData: {},
      voucherList: [{voucherName: "不使用优惠券", id: '-999'}],
      curVoucher: null,
      curVoucherModel: null,
      calculateDiscountVal: null,
      newPayPriceReal: '--'
    };
  },
  components: {},
  mounted() {
    if (document.body.offsetWidth < 1450) {
      this.isWidthEnough = false;
    }
    this.switchType(this);
    this.noInvoice()
  },
  methods: {
    async voucherChange() {
      this.newPayPriceReal = '--'
      // 获取折扣 
      this.curVoucherModel = this.voucherList.find(item=> item.id == this.curVoucher)
      if(this.curVoucher != -999) {
        const userInfo = JSON.parse(window.localStorage.getItem('user-info'));
        const paramsList = this.invoiceForm.shopDTOList.reduce((val, cur)=> {
          const temp = cur.goodsDTOList.map(item=> {
            return {
              skuId: item.id,
              count: item.skuNum,
              shopType: cur.shopType
            }
          })
          return val.concat(temp)
        }, [])
        const params = {
          account: userInfo.account,
          tenantId: userInfo.tenantId,
          transactionAmount: this.orderResData.sellingPrice,
          id: this.curVoucher,
          list: paramsList
        }
        const res = await calculateDiscount(params)
        this.calculateDiscountVal = res.data.discountPrice
      }
      const addVal1 = this.highPrecisionAdd(this.orderResData.carriage, this.orderResData.sellingPrice)
      if(this.curVoucher == -999) {
        this.newPayPriceReal = addVal1
        return
      } 
      this.newPayPriceReal = this.highPrecisionReduce(addVal1, this.calculateDiscountVal)
    },
    async calcOrderInfo() {
      this.newPayPriceReal = '--'
      this.voucherList = []
      this.curVoucher = '-999'
      this.curVoucherModel = {voucherName: "不使用优惠券", id: '-999'}
      this.calculateDiscountVal = null
      const paramsList = this.invoiceForm.shopDTOList.reduce((val, cur)=> {
        const temp = cur.goodsDTOList.map(item=> {
          return {
            skuId: item.id,
            count: item.skuNum,
            shopType: cur.shopType
          }
        })
        return val.concat(temp)
      }, [])
      const params = {
        list: paramsList,
        cityCode: this.addressVo.cityCode,
        provinceCode: this.addressVo.provinceCode,
        districtCode: this.addressVo.districtCode,
        // account: 15727394194,
        // tenantId: 1
      }
      const res = await calOrderData(params);
      this.orderResData = res.data
      this.voucherList = [{voucherName: "不使用优惠券", id: '-999'}, ...(res.data.voucherList ?? [])]
      this.newPayPriceReal = this.highPrecisionAdd(res.data.carriage, res.data.sellingPrice)
    },
    dealErr(e) {
      e.target.src = require("@/assets/error.svg");
    },
    choseAddress(val) {
      this.choseProvinceCode = val.provinceCode;
      if (!val.provinceCode) {
        this.$message.warning("请维护地址信息");
        return;
      }
      
      // if (this.invoiceForm.shopDTOList.length == 1) {
      //   if (this.$route.query.orderSource == 0) {
      //     this.invoiceForm.payPriceReal -= Number(this.freightPrice);
      //     choseAddress({
      //       list: this.skuList,
      //       provinceCode: val.provinceCode,
      //       cityCode: val.cityCode,
      //       districtCode: val.districtCode
      //     }).then((res) => {
      //       this.freightPrice = res.data;
      //       for (let val of this.invoiceForm.shopDTOList) {
      //         val.freightPriceReal = this.freightPrice;
      //       }
      //       this.invoiceForm.payPriceReal += Number(this.freightPrice);
      //     });
      //   }
      // } else {
      //   this.freightPrice = 0;
      //   for (let i of this.invoiceForm.shopDTOList) {
      //     if (this.$route.query.orderSource == 0) {
      //       let skuList = [];
      //       for (let a of i.goodsDTOList) {
      //         skuList.push({
      //           skuId: a.id,
      //           count: a.skuNum,
      //         });
      //       }
      //       choseAddress({
      //         list: skuList,
      //         provinceCode: val.provinceCode,
      //         cityCode: val.cityCode,
      //         districtCode: val.districtCode
      //       }).then((res) => {
      //         this.freightPrice += res.data;
      //         i.freightPriceReal = res.data;
      //         // this.invoiceForm.payPriceReal =
      //         //   this.freightPrice + this.totalPrice;
      //         this.invoiceForm.payPriceReal =
      //           Number(this.freightPrice) + Number(this.invoiceForm.payPriceReal);
      //       });
      //     }
      //   }
      // }
      this.addressVo = val;
      this.calcOrderInfo()
    },
    switchType(_this) {
      const { switchType } = def(this.$data);
      console.log(this.invoiceForm.payPriceReal)
      switchType(_this);
      console.log(this.invoiceForm.payPriceReal)
      this.totalPrice = this.invoiceForm.payPriceReal;
    },
    go() {
      if (!this.choseProvinceCode) {
        this.$message.warning("请维护地址信息");
        return;
      }
      if(this.newPayPriceReal == '--') return;
      // if (isNaN(this.freightPrice)) {
      //   this.$message.warning("运费查询失败，请重试");
      //   return;
      // }
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          if (this.invoiceForm.invoiceDTO.invoiceClass == 2) {
            this.$refs.invoiceFormInline.validate((valids) => {
              if (valids) {
                this.sure();
              }
            });
          } else {
            this.sure();
          }
        }
      });
    },
    //确认下单
    sure() {
      // this.invoiceForm.shopDTOList.forEach((el) => {
      //   el.orderLeaveWord = this.invoiceForm.orderLeaveWord;
      //   delete el.orderTime;
      //   delete this.invoiceForm.orderLeaveWord;
      // });
      console.log('this.invoiceForm',this.invoiceForm)
      let params = {...this.invoiceForm, payPriceReal: this.newPayPriceReal}
      if(this.curVoucherModel && this.curVoucherModel.id != -999) {
        params.isUseVoucher = 1
        params.voucherType = this.curVoucherModel.type
        params.payVoucherAmount = this.calculateDiscountVal
        params.voucherId = this.curVoucherModel.id
      } else {
        params.isUseVoucher = 0
      }
      submitOrder(params)
        .then((res) => {
          if (res.code == 200) {
            debugger
            if(this.newPayPriceReal === '0.00') {//账期抵扣完直接跳列表页
              this.$router.push({
                path: "/Purchaser/hybrid-order/index",
              });
              return;
            }
            this.$store.commit("SET_PARAMS", {
              txtArray: this.txtArray,
              shopName: this.invoiceForm.shopDTOList[0].shopName,
              orderCode: res.data, //res.data
              price: this.newPayPriceReal,
              orderSource: this.invoiceForm.orderSource,
            });
            debugger
            this.$router.push({
              path: "/commons/sure-pay/index",
            });
          } else {
            // this.$message.warning(res.msg);
          }
        })
        // .catch((e) => {
        //   this.$message.warning(res.msg);
        // });
    },
    //无需发票
    noInvoice() {
      this.invoiceForm.isInvoice = 2;
    },
    //开具发票
    openInvoice() {
      this.invoiceForm.isInvoice = 1;
    },
    //选择企业
    choseCom() {
      this.invoiceForm.invoiceDTO.invoiceClass = 2;
    },
    //选择个人
    chosePerson() {
      this.invoiceForm.invoiceDTO.invoiceClass = 1;
    },
  },
};
</script>
<style scoped lang='scss'>
.box {
  box-sizing: border-box;
  padding: 15px 0px;

  .supplymode-menu {
    color: #606266;
    text-align: center;
    margin-bottom: 10px;

    .el-col {
      height: 31px;
      line-height: 31px;
      width: 110px;
      margin-right: 20px;
      cursor: pointer;

      i {
        font-size: 20px;
        color: #409eff;
        vertical-align: -0.1em;
      }
    }

    .el-col:nth-child(1),
    .el-col:nth-child(2) {
      position: relative;
      line-height: 28px;
      border: 2px solid #ccc;

      &.active {
        border: 2px solid #409eff;
        color: #409eff;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          border: 7.5px solid #409eff;
          border-top-color: transparent;
          border-left-color: transparent;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          border: none;
          width: 8px;
          height: 7px;
          background: url(./images/selected.svg) center center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-table {
    margin: 10px -40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #606266;

    div {
      width: 100%;
      text-align: center;
    }

    .name-box {
      box-sizing: border-box;
      padding: 0 20px;
      line-height: 20px;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 13px;

      .txt {
        text-align: left;
      }

      img {
        width: 100px;
        height: 100px;
        margin-right: 5px;
      }
    }

    .name-box-small {
      box-sizing: border-box;
      padding: 0 20px;
      line-height: 20px;
      text-align: left;
      font-size: 13px;

      .txt {
        text-align: left;
      }

      img {
        width: 150px;
        height: 80px;
        margin-bottom: 5px;
      }
    }
  }

  .borderMain {
    margin: 10px -50px;
    border-top: 1px solid #f2f6fc;
    border-bottom: 8px solid #f2f6fc;
  }

  .bord-bottom {
    margin: 0 -40px;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    border-bottom: 2px solid #f2f6fc;
  }

  .title {
    color: #303133;
    font-size: 14px;
    margin: 12px;
  }

  .backBox {
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(235, 120, 12, 0.2);
    margin: 10px 0;
    width: 420px;
    text-align: right;

    .flex-end {
      display: flex;
      justify-content: flex-end;

      .orange {
        display: block;
        color: #e65a09;
        font-size: 22px;
      }
    }

    .title {
      color: #606266;
      font-size: 14px;
      margin: 6px;
    }
  }

  .intrudct {
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #409eff;
    background-color: rgba(184, 213, 240, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      margin: 4px;
    }

    .imgbox {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      img {
        height: 140px;
        width: 140px;
      }
    }

    .post {
      position: absolute;
      right: 10px;
      bottom: 5px;
      text-align: center;

      .orange {
        color: #e6a23c;
        font-size: 26px;
      }
    }
  }

  .el-input {
    width: 330px;
  }

  .el-select {
    width: 420px;
  }
}
</style>