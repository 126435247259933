const TokenKey = 'Admin-Token'
const HzyTokenKey = 'Huizhuyun-Auth'
const MemberTokenKey = 'Member-Token'
const SwitchToCompanyIdKey = 'SwitchToCompanyId'
const UserInfoKey = 'user-info'

export function getToken () {
  // return Cookies.get(TokenKey)
  return window.localStorage.getItem(TokenKey)
}

export function setToken (token) {
  // return Cookies.set(TokenKey, token)
  return window.localStorage.setItem(TokenKey,token)
}


export function getMemberToken () {
  return window.localStorage.getItem(MemberTokenKey)
}

export function setMemberToken (token) {
  return window.localStorage.setItem(MemberTokenKey, token)
}

export function getSwitchToCompanyId () {
  return window.localStorage.getItem(SwitchToCompanyIdKey)
}

export function setSwitchToCompanyId (token) {
  return window.localStorage.setItem(SwitchToCompanyIdKey, token)
}

export function setUserInfo (data) {
  return window.localStorage.setItem(UserInfoKey, data)
}

export function getUserInfo () {
  return window.localStorage.getItem(UserInfoKey)
}


export function getHuizhuyunToken () {
  return window.localStorage.getItem(HzyTokenKey)
}

export function setHuizhuyunToken (token) {
  return window.localStorage.setItem(HzyTokenKey,token)
}

export function clearCookie () {
  window.localStorage.clear()
}


