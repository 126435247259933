<template>
  <div class="hov">
    <el-breadcrumb separator="/"
                   separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item, idx) of pathList"
                          :key="idx">
        <span class="label-item"
              @click="goPath(item.path)">{{ item.name }}
        </span>
      </el-breadcrumb-item>
    </el-breadcrumb>

  </div>
</template>
<script>
import { menuList } from '@/views/menu'
export default {
  data() {
    return {
      pathList: [],
      showBreadCrumb: true,
      routePath: ''
    }
  },
  computed: {
    Obj() {
      return this.$store.state.go.breadCrumbList
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.pathList = []
        const route = menuList.find(x => x.path == val.path)
        if(!route) return
        this.pathList.push({
          path: route.path,
          name: route.meta.title
        })
        if(!route.parentPath) return
        const parent = menuList.find(x => x.path == route.parentPath)
        if(!parent) return
        this.pathList.unshift({
          path: parent.path,
          name: parent.meta.title
        })
      },
      immediate: true
    },
  },
  mounted() {
    this.routePath = this.$route.fullPath
    this.$store.commit('CLEAR_SUBLEVEL', this.routePath)
    if (window.location.href.includes('?ii=')) {
      this.$store.commit('RESET_BREAD')
      this.pathList = []
    }
  },
  methods: {
    goPath(p) {
      if(p == this.$route.path) return
      this.$router.push(p)
    },
  }
}
</script>
<style lang="scss" scoped>
.label-item {
  font-size: 15px;
  &:hover {
    opacity: 1;
    color: #1f4f8f;
    cursor: pointer;
  }
}
.current-item {
  color: #1f4f8f;
}
.hov {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}
.sonDown {
  font-size: 12px;
}

.sonDown:hover {
  cursor: pointer;
}

.hidden {
  opacity: 0;
}

.item:hover {
  cursor: pointer;

  .hidden {
    opacity: 1;
    color: #0079fe;
  }
}
</style>
