import { basketList } from '@/api/purchaser/myBiddingOrder'
import { getDiscountNum } from '@/api/purchaser/myBidding'
import Vue from 'vue'
function timestampToTime (timestamp) {
  var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

function getShopList (data, _this, selectedIds, discountRatio) {
  _this.txtArray = [timestampToTime(new Date().getTime()), '未支付']
  //所有已勾选的商品数据
  const targets = data.data.valid.reduce((val, cur) => {
    const selectedCurList = cur.list.filter(item => selectedIds.includes(item.skuId))
    return val.concat(selectedCurList)
  }, [])

  let totalPrice = 0;
  for (let val of targets) {
    _this.skuList.push({ skuId: val.skuId, count: val.basketCount })
    totalPrice = Vue.prototype.highPrecisionAdd(totalPrice, val.totalPrice)
    // totalPrice += val.totalPrice
  }
  _this.invoiceForm.payPriceReal = totalPrice
  _this.totalPrice = totalPrice

  let discount = 0;
  data.data.valid.filter(shop => {
    return shop.list.find(good => targets.find(targetGood => targetGood.skuId == good.skuId))
  }).forEach(shop => {
    // 自营商品算折扣
    if(shop.shopType == 2) {
      var amount = shop.list.reduce((ctx, cur) => ctx + cur.totalPrice, 0)
      const temp = Vue.prototype.highPrecisionMul(amount, discountRatio)
      discount = Vue.prototype.highPrecisionAdd(discount, temp)
      // discount += amount * discountRatio
    }
  })
  _this.discountAmount = discount
  // _this.invoiceForm.payPriceReal -= _this.discountAmount

  //数据格式处理
  let targetShopItems = data.data.valid.filter(shop => {
    return shop.list.find(good => targets.find(targetGood => targetGood.skuId == good.skuId))
  }).map(shop => {
    let validGoods = shop.list.filter(good => targets.find(targetGood => targetGood.skuId == good.skuId)).map(good => {
      return {
        "id": good.skuId,//商品skuId
        'fileUrl': good.fileUrl || '',
        "skuName": good.productName,//商品名称
        "skuSpec": good.skuSpecsName + '-' + good.skuSpecsInfo,//商品规格
        "productCategoryId": good.productCategoryId,//商品分类id
        "productCategoryCode": good.productCategoryCode,//商品分类code
        "unit": "",//单位
        "skuNum": good.basketCount,//购买商品数量
        "skuPriceReal": good.skuPrice,//商品价格(元)
        "productFreightExplain": good.productFreightExplain
      }
    })
    return {
      "shopName": shop.shopName,//卖家名称
      "shopId": shop.id,//卖家ID
      "memberInfoId": shop.memberId,//卖家用户ID
      "businessId": shop.bissinessId,//卖家企业ID
      "goodsDTOList": validGoods
    }
  })
  return targetShopItems
}

export async function isShop (ctx, globalWind) {

  // const resDiscount = await getDiscountNum()
  let discountRatio = 0
  // if(resDiscount.success) {
  //   discountRatio = Vue.prototype.highPrecisionReduce(1, resDiscount.data)
  //   if(isNaN(discountRatio)) {
  //     discountRatio = 0
  //   }
  // }
  ctx.invoiceForm.orderSource = globalWind.$route.query.orderSource
  let res;
  //购物车
  if (globalWind.$route.query.from == 'car') {
    ctx.invoiceForm.orderType = '1'
    res = await basketList()
    ctx.invoiceForm.shopDTOList = getShopList(res, ctx, globalWind.$route.query.ids, discountRatio);
  } 
  //发起拼团
  else if (globalWind.$route.query.from == 'group') {
    ctx.invoiceForm.orderType = '2';
    ctx.invoiceForm.shopDTOList = JSON.parse(globalWind.$route.query.shopDTOList);
    ctx.invoiceForm.payPriceReal =
      ctx.invoiceForm.shopDTOList[0].goodsDTOList[0].skuPriceReal * ctx.invoiceForm.shopDTOList[0].goodsDTOList[0].skuNum;
    ctx.totalPrice = ctx.invoiceForm.payPriceReal
    
    // 自营商品算折扣
    if(ctx.invoiceForm.shopDTOList[0].shopType == 2) {
      ctx.discountAmount = Vue.prototype.highPrecisionMul(ctx.totalPrice, discountRatio)
    }
    // ctx.invoiceForm.payPriceReal -= ctx.discountAmount

    for (let val of ctx.invoiceForm.shopDTOList) {
      for (let idx of val.goodsDTOList) {
        ctx.skuList.push({ skuId: idx.id, count: idx.skuNum })
      }
    }
  } 
  //shopDetail 商品详情
  else if (globalWind.$route.query.from == 'shopDetail') {
    ctx.invoiceForm.orderType = '1';
    ctx.invoiceForm.shopDTOList = JSON.parse(globalWind.$route.query.shopDTOList);
    let price = 0;
    let discount = 0;
    for (let val of ctx.invoiceForm.shopDTOList) {
      for (let idx of val.goodsDTOList) {
        price += idx.skuPriceReal * idx.skuNum
        // 自营商品算折扣
        if(val.shopType == 2) {
          // discount += idx.skuPriceReal * idx.skuNum * discountRatio
          const temp1 = Vue.prototype.highPrecisionMul(idx.skuPriceReal, idx.skuNum)
          discount = discount + Vue.prototype.highPrecisionMul(temp1, discountRatio)
        }
      }
    }
    ctx.invoiceForm.payPriceReal = price
    ctx.totalPrice = ctx.invoiceForm.payPriceReal
    ctx.discountAmount = discount
    // ctx.invoiceForm.payPriceReal -= ctx.discountAmount

    for (let val of ctx.invoiceForm.shopDTOList) {
      for (let idx of val.goodsDTOList) {
        ctx.skuList.push({ skuId: idx.id, count: idx.skuNum })
      }
    }


  } 
  //参与拼团
  else if (globalWind.$route.query.from == 'joinGroup') {
    ctx.invoiceForm.orderType = '2';
    ctx.invoiceForm.pinOrderId = globalWind.$route.query.groupId;
    ctx.invoiceForm.shopDTOList = JSON.parse(globalWind.$route.query.shopDTOList);
    ctx.invoiceForm.payPriceReal =
      ctx.invoiceForm.shopDTOList[0].goodsDTOList[0].skuPriceReal * ctx.invoiceForm.shopDTOList[0].goodsDTOList[0].skuNum
    ctx.totalPrice = ctx.invoiceForm.payPriceReal
    // 自营商品算折扣
    if(ctx.invoiceForm.shopDTOList[0].shopType == 2) {
      ctx.discountAmount = Vue.prototype.highPrecisionMul(ctx.totalPrice, discountRatio)
    }
    // ctx.invoiceForm.payPriceReal -= ctx.discountAmount

    for (let val of ctx.invoiceForm.shopDTOList) {
      for (let idx of val.goodsDTOList) {
        ctx.skuList.push({ skuId: idx.id, count: idx.skuNum })
      }
    }
  }
  ctx.invoiceForm.demandId = ''
  ctx.invoiceForm.demandName = ''
  ctx.invoiceForm.demandCode = ''
  ctx.txtArray = [timestampToTime(new Date().getTime()), '未支付']

  if(ctx.discountAmount > 0) {
    ctx.discountAmount = Math.floor(ctx.discountAmount * 100) / 100
    // ctx.invoiceForm.payPriceReal -= ctx.discountAmount
    ctx.invoiceForm.payPriceReal = Vue.prototype.highPrecisionReduce(ctx.invoiceForm.payPriceReal, ctx.discountAmount)
  }
}