<template>
  <div v-if="this.pinStatus == 1">
    <div class="scrabbleDough">
    	<div class="scrabbleDough_t">拼团</div><div class="countdown">剩余 <span><count v-if="outTime" :endTime="this.outTime" /></span> 结束</div>
    	<div class="scrabbleDough_t_cue"><div>仅剩<span>{{this.pinLastNum}}</span>人，快呼唤小伙伴参加吧！</div></div>
    	<div class="scrabbleDough_list">
    		<div v-for="(item,index) in userArr" :key="index">
    			<img :src="item.accountHeader" alt=""><span>{{item.nickName}}</span>
    		</div>
    		<!-- <div>
    			<img src="../images/jiahao.png" alt="">
    		</div>
    		<div>
    			<img src="../images/jiahao.png" alt="">
    		</div>
    		<div>
    			<img src="../images/jiahao.png" alt="">
    		</div> -->
    	</div>
    	<div class="procedure"><img style="height: 80px;" src="../images/liucheng.png" alt=""></div>
    </div>
  </div>
</template>

<script>
import { detail } from '@/api/purchaser/myBiddingOrder'
import count from '@/components/coutDown/index'
export default {
  data () {
    return {
		status:"",
		userArr:[],
		pinStatus:"",
    }
  },
  components: {
		count
	},
  created () {

  },
  mounted () {
	this.detail()
  },
  methods: {
    handleChange () { },
	detail () {
      detail({ id: this.$route.query.id }).then(res => {
		let time = parseInt(new Date().getTime() / 1000);
        console.log(res,999888777)
		this.status = res.data.orderInfo.status
		this.userArr = res.data.pinOrderMember
		this.pinLastNum = res.data.pinLastNum
		this.pinStatus = res.data.pinStatus
		if(res.data.outTime){
			this.outTime = (res.data.outTime/1000) + time
		}
      })
    },
  },
}

</script>
<style scoped lang='scss'>
.scrabbleDough {
	width: 100%;
	padding-top: 15px;
	height: 292px;
	padding-bottom: 15px;
	position: relative;
	.scrabbleDough_t {line-height: 30px;height: 30px;float: left;margin-left: 35px;font-size: 16px;color: #666;border-bottom: 2px solid #1367ff;}
	.countdown {line-height: 30px;height: 30px;float: right;margin-right: 25px;font-size: 16px;color: #666;
		span {font-size: 14px;color: #FA1C2A;font-weight: bold;}
	}
	.scrabbleDough_t_cue {line-height: 54px;float: left;font-size: 16px;color: #666;width: 100%;
		div {margin-left: 25px;
		span{color: #FD1830;}
		}
	}
	.scrabbleDough_list {width: 100%;float: left;margin-top: 20px;
		div {width: 78px;float: left;margin-left: 35px;
			img {width: 78px;height: 78px;float: left;}
			span {width: 78px;float: left;font-size: 12px;color: #333;line-height: 34px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;text-align: center;}
		}
	}
	.procedure {width: 100%;float: left;margin-top: 15px;text-align: center;}
}
</style>