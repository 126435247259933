import Vue from 'vue'
Vue.prototype.highPrecisionAdd = function (data1, data2, digit = 2) {// 加法
    let r1, r2, m;
    // 获取每个参数的小数的位数
    try { r1 = data1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = data2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    // 计算底数为10以最大小数位数为次幂的值
    m = Math.pow(10, Math.max(r1, r2));
    // 把所有参数转为整数后相加再除以次幂的值
    let num = (data1 * m + data2 * m) / m;
    return sgToFixed(num, digit);
};

Vue.prototype.highPrecisionReduce = function (data1, data2, digit = 2) {// 减法
    let r1, r2, m, n;
    // 获取每个参数的小数的位数
    try { r1 = data1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = data2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    // 计算底数为10以最大小数位数为次幂的值
    m = Math.pow(10, Math.max(r1, r2));
    //精度长度以最大小数位数为长度
    n = (r1 >= r2) ? r1 : r2;
    let num = ((data1 * m - data2 * m) / m).toFixed(n);
    return sgToFixed(num, digit);
};
Vue.prototype.highPrecisionMul = function (data1, data2, digit = 2) {// 乘法
    let m = 0, s1 = data1.toString(), s2 = data2.toString();
    // 获取所有参数小数位长度之和
    try { m += s1.split(".")[1].length } catch (e) { }
    try { m += s2.split(".")[1].length } catch (e) { }
    // 替换掉小数点转为数字相乘再除以10的次幂值
    let num = Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    return sgToFixed(num, digit);
};
Vue.prototype.highPrecisionDiv = function (data1, data2, digit = 2) {// 除法
    let t1 = 0, t2 = 0, r1, r2;
    // 获取每个参数的小数的位数
    try { t1 = data1.toString().split(".")[1].length } catch (e) { }
    try { t2 = data2.toString().split(".")[1].length } catch (e) { }
    // 把所有参数的小数点去掉转为整数
    r1 = Number(data1.toString().replace(".", ""));
    r2 = Number(data2.toString().replace(".", ""));
    let num = (r1 / r2) * Math.pow(10, t2 - t1);
    return sgToFixed(num, digit);
};

/*数值补0
* num是数值；digit是保留有效数字位数
*/
let sgToFixed = (num, digit = 2) => {
    let nums = (num || 0).toString().split('.');
    return nums[0] + '.' + (nums[1] || '0').padEnd(digit, "0");
}
Vue.prototype.toChineseNumber=function (value) {
    const chineseNumbers = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      const chineseUnits = ['', '十', '百', '千', '万', '亿'];
  
      if (value === 0) {
          return chineseNumbers[0];
      }
  
      let result = '';
      let unitIndex = 0;
  
      const valueOle = value;
  
      while (value > 0) {
          const digit = value % 10;
          if (digit !== 0 || unitIndex === 1) {
              result = chineseNumbers[digit] + chineseUnits[unitIndex] + result;
          }
          value = Math.floor(value / 10);
          unitIndex++;
      }
  
      if (valueOle > 9 && valueOle < 20) {
          // 把10：十一，处理为：十
          result = result.slice(1);
          console.log('111', result.slice(1));
      }
  
      return result;
  }