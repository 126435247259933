import Vue from 'vue'

// 定义 loading示例
let loading


function getLoadOptions (loadFull = true) {
  return {
    lock: true,
    text: '拼命加载中...',
    spinner: 'el-icon-loading',  // 自定义图标
    background: 'rgba(0, 0, 0, 0.6)',
    target: loadFull == false ? document.querySelector('.loading-area') : 'body'
  }
}

// loading开始 方法
function startLoading (loadFull) {
  // element-ui loading 服务调用方式  
  loading = Vue.prototype.$loading(getLoadOptions(loadFull))
}

// loading结束 方法
function endLoading () {
  loading.close()
}

let needLoadingRequestCount = 0 //定义并行异步请求临时变量
export function showFullScreenLoading (loadFull) {
  if (needLoadingRequestCount === 0) {
    startLoading(loadFull)
  }
  needLoadingRequestCount++ //多个异步请求
}

export function tryHideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }

}

