import ElementUI from "element-ui";

// 分页默认
ElementUI.Pagination.props.layout.default = "total, sizes, prev, pager, next, jumper";
ElementUI.Pagination.props.pageSize.default = 10;
ElementUI.Pagination.props.pageSizes.default = () => { return [10, 80, 100, 200] };

// table默认
ElementUI.TableColumn.props.align = { type: String, default: "center" };
ElementUI.TableColumn.props.headerAlign = { type: String, default: "center" };

export default ElementUI