<template>
    <div :class="b()">
        <div :class="b('filter')" v-if="vaildData(option.filter, false)">
            <el-input :placeholder="
                vaildData(option.filterText, '输入关键字进行过滤')
            " :disabled="vaildData(disabledAll, false)" :size="size" v-model="filterValue">
                <slot name="addBtn" slot="append"></slot>
            </el-input>
        </div>
        <el-scrollbar :class="b('content')">
            <el-tree ref="tree" :data="data" :lazy="lazy" :load="treeLoad" :props="treeProps" :icon-class="iconClass"
                :indent="indent" :highlight-current="!multiple" :show-checkbox="multiple" :accordion="accordion"
                :node-key="valueKey" :check-strictly="checkStrictly" :check-on-click-node="checkOnClickNode"
                :filter-node-method="filterNode" v-loading="loading" :expand-on-click-node="expandOnClickNode"
                @check-change="handleCheckChange" @node-click="nodeClick" :default-expand-all="defaultExpandAll"
                :default-expanded-keys="defaultExpandedKeys">
                <slot slot-scope="{ node, data }" :node="node" v-if="$scopedSlots.default" :data="data"></slot>

                <span class="el-tree-node__label" slot-scope="{ node }" v-else>
                    <span>{{ node.label }}</span>
                </span>
            </el-tree>
        </el-scrollbar>
    </div>
</template>

<script>
import { KEY_COMPONENT_NAME, DIC_PROPS } from "../../global/variable";
import bem from "../../utils/bem";
export default {
    name: KEY_COMPONENT_NAME + "tree",
    mixins: [bem],
    props: {
        indent: Number,
        filterNodeMethod: Function,
        checkOnClickNode: Boolean,
        iconClass: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        expandOnClickNode: {
            type: Boolean,
            default: false,
        },
        option: {
            type: Object,
            default: () => {
                return {};
            },
        },
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
        value: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            filterValue: "",
            client: {
                x: 0,
                y: 0,
                show: false,
            },
            type: "",
            node: {},
        };
    },
    computed: {
        treeProps() {
            return Object.assign(this.props, {
                isLeaf: this.leafKey,
                disabled: this.disabledAll ? this.labelKey : false
            });
        },
        menu() {
            return this.vaildData(this.option.menu, true);
        },
        title() {
            return this.option.title;
        },
        treeLoad() {
            return this.option.treeLoad;
        },
        checkStrictly() {
            return this.option.checkStrictly;
        },
        accordion() {
            return this.option.accordion;
        },
        multiple() {
            return this.option.multiple;
        },
        lazy() {
            return this.option.lazy;
        },
        size() {
            return this.option.size || "medium";
        },
        props() {
            return this.option.props || {};
        },
        disabledAll() {
            return this.vaildData(this.option.disabledAll, false);
        },
        leafKey() {
            return this.props.leaf || DIC_PROPS.leaf;
        },
        valueKey() {
            return this.props.value || DIC_PROPS.value;
        },
        labelKey() {
            return this.props.label || DIC_PROPS.label;
        },
        childrenKey() {
            return this.props.children || DIC_PROPS.children;
        },
        nodeKey() {
            return this.option.nodeKey || DIC_PROPS.nodeKey;
        },
        defaultExpandAll() {
            return this.option.defaultExpandAll;
        },
        defaultExpandedKeys() {
            return this.option.defaultExpandedKeys;
        },
    },
    mounted() {
        this.initFun();
    },
    watch: {
        "option.disabled": function () {
            // this.data.map(item=>{
            //     console.log('[ item.disabled ]-135', item.disabled)
            //     item.disabled = this.option.disabled || false
            // })
            console.log('[ this.option. ]-138', this.option.disabled)
            return this.option.disabled;
        },
        filterValue(val) {
            this.$refs.tree.filter(val);
        },
        value(val) {
            this.form = val;
        },
        form(val) {
            this.$emit("input", val);
        },
    },
    methods: {
        initFun() {
            [
                "filter",
                "updateKeyChildren",
                "getCheckedNodes",
                "setCheckedNodes",
                "getCheckedKeys",
                "setCheckedKeys",
                "setChecked",
                "getHalfCheckedNodes",
                "getHalfCheckedKeys",
                "getCurrentKey",
                "getCurrentNode",
                "setCurrentKey",
                "setCurrentNode",
                "getNode",
                "remove",
                "append",
                "insertBefore",
                "insertAfter",
            ].forEach((ele) => {
                this[ele] = this.$refs.tree[ele];
            });
        },

        handleCheckChange(data, checked, indeterminate) {
            this.$emit("check-change", data, checked, indeterminate);
        },

        nodeClick(data, node, nodeComp) {
            this.$emit("node-click", data, node, nodeComp);
        },
        filterNode(value, data) {
            if (typeof this.filterNodeMethod === "function") {
                return this.filterNodeMethod(value, data);
            }
            if (!value) return true;
            return data[this.labelKey].indexOf(value) !== -1;
        },
        // 递归遍历所有子节点,设置反选
        inverse: function (root) {
            for (let i = 0; i < root.length; i++) {
                const item = root[i];
                if (item.children !== undefined && item.children.length > 0) {
                    for (let i = 0; i < item.children.length; i++) {
                        let child = item.children[i];
                        let node = this.$refs.tree.getNode(child);
                        this.$refs.tree.setChecked(node, !node.checked);
                        this.inverse(child);
                    }
                }
            }
        },
    },
};
</script>
