import Vue from 'vue'
import { validatenull } from './validate'
/**
 * 转义字典类数据
 * val: 需要转义的值
 * dict: 字典数据 [{value: 0, label: '不错'}]
 * label: 含义的对应key值
 */
Vue.filter("escape", function (val = '', dict = [], label = 'label', value = 'value') {
  if (!validatenull(val) && dict.length != 0) {
    for (let i = 0; i < dict.length; i++) {
      const item = dict[i];
      if (item?.constructor === Object && item[value] == val) {
        return item[label]
      }
    }
  }
  return '';
})
