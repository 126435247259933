import request from '@/utils/request'

// 共享云-个人中心-个人信息-地址管理	详情	get	/ecomm/memberAddress/detail
export function addressDetail (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberAddress/detail',
    method: 'get',
    params: data
  })
}
// 	自定义分页	get	/ecomm/memberAddress/page
export function addressPage (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberAddress/page',
    method: 'get',
    params: data
  })
}
// 	新增	post	/ecomm/memberAddress/save
export function addressSave (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberAddress/save',
    method: 'post',
    data: data
  })
}
// 	修改	post	/ecomm/memberAddress/update
export function addressUpdate (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberAddress/update',
    method: 'post',
    data: data
  })
}
// 	删除	post	/ecomm/memberAddress/remove
export function addressRemove (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberAddress/remove',
    method: 'post',
    params: data
  })
}
// 	分页	get	/ecomm/memberAddress/list
export function addressList (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberAddress/list',
    method: 'get',
    params: data
  })
}
// 	地址
export function GetaddressList (data) {
  return request({
    url: '/api/rs-cms-service/ecomm/region/lazy-tree',
    method: 'get',
    params: data
  })
}
// /api/rs-cms-service/ecomm/region/lazy-tree
// 地址识别 /api/rs-member-service/ecomm/memberInfo/addressRead?address=地址
export function addressRead (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberInfo/addressRead',
    method: 'get',
    params: {address:data}
  })
}