import axios from 'axios'
import store from '@/store'
import { getToken, getMemberToken, getSwitchToCompanyId, getHuizhuyunToken } from '@/utils/auth'
import { getQueryString } from '@/utils/util.js'
import { MessageBox, Message } from 'element-ui'
import { showFullScreenLoading, tryHideFullScreenLoading } from './loading'
import Vue from 'vue'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['RequestSource'] = 'HUIZHUYUN'
let baseUrl = '';
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseUrl,
  // 超时
  timeout: 1000 * 60,
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false

  config.headers['Authorization'] = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
  if (getToken() && !isToken) {
    config.headers['Blade-Auth'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (getMemberToken()) {
    config.headers['identityCode'] = getMemberToken()
  }
  if (getSwitchToCompanyId()) {
    config.headers['SwitchToCompanyId'] = getSwitchToCompanyId()
  }
  if (getHuizhuyunToken()) {
    config.headers['Huizhuyun-Auth'] = getHuizhuyunToken()
  }
  //调管理云的接口参数加companyId
  if(config.url.indexOf('/api/huizhuyun-management') != -1) {
    // config[config.method == 'get'?'params':'data'].companyId = getSwitchToCompanyId()
    config[config.method == 'get'?'params':'data'].tenantId = JSON.parse(window.localStorage.getItem('params')).chooseTenantId
  }
  if (config.showLoad == undefined || config.showLoad) {
    // 请求显示loading 效果
    // showFullScreenLoading();
  }
  //数据来源根据域名修改
  const platformConfig = store.state.platformConfigData
  if(platformConfig && !config.headers['RequestSource']) {
    config.headers['RequestSource'] = platformConfig.platformCode;
  }else {
    config.headers['RequestSource'] = 'HUIZHUYUN';
  }
  return config
}, error => {
  tryHideFullScreenLoading(config.loadFull);
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(response => {
  
  tryHideFullScreenLoading();
  // return
  /**
   * 对响应数据判断:
   *  如果成功返回数据，就通过return把数据返出去
   *  如果请求不成功，就在拦截器这里统一处理（组件的代码就不用关注错误的情况了）
   */ 
  if (response.status == 200 && ( response.config.headers.ignoreMsg||response.data instanceof Blob||response.data?.code == 200)) {
    return response.data;
  } else {
    handleErrorData(response.data);
  }
  return response;

},
async error => {  
  if(error.response.data?.type=='application/json') {
     await error.response.data.text().then(text => {
        try {
          error.response.data = JSON.parse(text); 
        } catch (e) {
          console.error("Parsing error:", e);
        }
      });
  } 
   
    //登录接口有种未激活的状态，statusCode是400
    if(error.response.config.url.includes('/api/blade-auth/oauth/token')) {
      return error.response.data
    }
    tryHideFullScreenLoading();
    if(!error.response.config.headers.ignoreMsg){
      error.response?.data && handleErrorData(error.response?.data);
    }
    return Promise.reject(error.response?.data)
  }
)


function logOutConfirm () {
  MessageBox.confirm(
    '登录状态已过期，请重新登录',
    '系统提示',
    {
      confirmButtonText: '重新登录',
      type: 'warning',
      showCancelButton: false,
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false
    }
  ).then(() => {
    // window.localStorage.clear()
    // window.location.reload()
    window.localStorage.clear();
    // console.log('登录状态已过期，请重新登录',Vue.query.target)
    if(getQueryString('target')){
      window.open(
      `${Vue.prototype.$JumpUrl}` + getQueryString('target'),
      '_self'
    )
    }else{
      window.open(
      `${Vue.prototype.$JumpUrl}/preferentialInquiry?ihl=1&handleLogout=true`,
      '_self'
      )
    }
  })
}


//对错误信息的处理函数
function handleErrorData (errMes) {
  debugger
  switch (errMes.code) {
    case 401:
      logOutConfirm();
      break;
    case 403:
      Message.error("拒绝访问");
      break;
    case 404:
      Message.error("很抱歉，资源未找到!");
      break;
    case 500:
      Message.error("服务器错误!");
      break;
    case 504:
      Message.error("网络超时!");
      break;
    default:
      Message.error(errMes.message || errMes.msg || '操作失败');
      break;
  }
}

export default service
