// 我的询价订单
import request from '@/utils/request'
// 列表
export function list (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/list',
    method: 'get',
    params: data
  })
}
// 评价
export function evaluate (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderEvaluate/evaluate',
    method: 'post',
    data: data
  })
}

// 详情
export function detail (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/detail',
    method: 'get',
    params: data
  })
}

// 取消
export function cancelOrder (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/cancelOrder',
    method: 'post',
    data: data
  })
}

// 批量收货
export function batchReceiving (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/batchReceiving',
    method: 'post',
    data: data
  })
}

//退款详情
export function refundDetail (data) {
  return request({
    url: `/rs-order-service/ecomm/refund/refund/detail/${data}`,
    method: 'get'
  })
}

//申请退款
export function refundSure (data) {
  return request({
    url: `/api/rs-order-service/ecomm/refund/buyer/order/refund`,
    method: 'post',
    data: data
  })
}

//购物车列表
export function basketList () {
  return request({
    url: '/api/rs-product-service/ecomm/basket/list',
    method: 'get'
  })
}
//微信支付接口
export function wxpay (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/wxpay`,
    method: 'post',
    data: data
  })
}
//微信支付接口
export function wxpayResult (key) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/wxpay/payResult?key=${key}`,
    method: 'post',
    data: {
      key: key
    }
  })
}

//银联支付
export function otherSignPay (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/otherSignPay`,
    method: 'post',
    data: data
  })
}

//银联支付签约短信
export function otherSignSms (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/otherSignSms`,
    method: 'post',
    data: data
  })
}

//银联支付签约
export function otherSignContract (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/otherSignContract`,
    method: 'post',
    data: data
  })
}

//银联支付签约查询
export function otherSignSelect (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/otherSignSelect`,
    method: 'post',
    data: data
  })
}

//银联支付短信
export function otherPaySms (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/otherPaySms`,
    method: 'post',
    data: data
  })
}

//银联支付
export function otherPayBack (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/otherPayBack`,
    method: "post",
    data: data
  })
}

//银联支付解绑
export function otherSignRelieve (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/otherSignRelieve`,
    method: 'post',
    data: data
  })
}

// 物流明细
export function logisticsDetail (data) {
  return request({
    url: `/api/rs-order-service/ecomm/orderLogistics/logisticsDetail/${data}`,
    method: 'get'
  })
}

//银联支付短信 购买会员
export function sendGrowthPaySms (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/sendGrowthPaySms`,
    method: 'post',
    data: data
  })
}

//银联支付 购买会员
export function growthPay (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/pay/growthPay`,
    method: "post",
    data: data
  })
}

//银联支付 购买次数
export function demandOrderPaySms (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/demandPay/paySms`,
    method: "post",
    data: data
  })
}

//银联支付 购买次数-支付
export function demandOrderPay (data) {
  return request({
    url: `/api/rs-fund-service/ecomm/demandPay/pay`,
    method: "post",
    data: data
  })
}
