import Vue from 'vue'
// 自定义指令防止按钮重复点击 v-reClick
export default {
  install(Vue) {
    // 防止重复点击
    Vue.directive('reClick', {
      inserted: function (el, binding) {
        el.addEventListener('click', () => {
          if (el.style['pointer-events'] != 'none') {
            el.style['pointer-events'] = 'none'
            setTimeout(() => {
              el.style['pointer-events'] = 'all'
            }, binding.value || 1500)
          }
        })
      },
    })
  },
}
