<template>
  <div> 
    <!-- :header-cell-style="rowClass"
      :cell-style="changeCellStyle"
      :row-class-name="tableRowClassName"
       :height="dataSourceOpt.height || ''" -->
    <el-table :data="dataSource"
              :border="dataSourceOpt.border || true"
              :stripe="dataSourceOpt.stripe || false"
              @sort-change="sortChange"
              @selection-change="handleSelectionChange"
              :height="myHeight">
      <!--数据源-->
      <el-table-column v-if="dataSourceOpt.showSelect"
                       type="selection"
                       width="55"
                       align="center"
                       :fixed="dataSourceOpt.fixedSelect || false">
      </el-table-column>
      <template v-for="column of dataSourceOpt.columns">
        <template v-if="column.type == 'text' || column.type == undefined">
          <el-table-column :sortable="column.sort || false"
                           :header-align="column.headderAlign || 'center'"
                           :key="column.prop"
                           :prop="column.prop"
                           :label="column.label"
                           :align="column.align || 'center'"
                           :width="column.width || ''"
                           :fixed="column.fixed || false">
          </el-table-column>
        </template>

        <!-- 关于特殊类型的处理 -->
        <el-table-column :sortable="column.sort || false"
                         :header-align="column.headderAlign || 'center'"
                         :key="column.prop"
                         :prop="column.prop"
                         :label="column.label"
                         :align="column.align || 'center'"
                         :width="column.width || ''"
                         :fixed="column.fixed || false"
                         v-else>
          <template slot-scope="scope">
            <slot name="special"
                  :row="scope.row"
                  :column="scope.column"></slot>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作"
                       align="center"
                       :width="dataSourceOpt.btnWidth || '170'"
                       :fixed="dataSourceOpt.fixedRight || false">
        <template slot-scope="scope">
          <div
               :class="(dataSourceOpt.flexRight == undefined || dataSourceOpt.flexRight == true) ? 'flexBtn' : ''">
            <slot name="btn"
                  :row="scope.row"></slot>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    //列表横行数据源，必传，后端传递
    dataSource: {
      type: Array,
      default: () => []
    },
    dataSourceOpt: {
      type: Object,
      default: () => {}
    },
    myHeight:{
      type: [String,Number] ,
      default: 0
    }
  },
  watch: {
    dataSourceOpt: {
      handler(nV, oV) {
        //  `newValue` 和 `oldValue`
      }
      // deep: true,
      // immediate: true
    }
  },
  methods: {
    //@排序事件
    sortChange({ column, prop, order }) {
      this.$emit('sortChange', { column, prop, order })
    },
    //@排序事件
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    }

    // //某一列的css
    // changeCellStyle(row, column, rowIndex, columnIndex) {
    // },
    // //表头或者某一格的css
    // rowClass({ row, column, rowIndex, columnIndex }) {
    // },
    // //某一行的css
    // tableRowClassName({ row, rowIndex }) {
    // },
  }
}
</script>
<style scoped>
.flexBtn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>