import request from '@/utils/request'

//分页
export function getList (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberBank/list',
    method: 'get',
    params: data
  })
}

//新增
export function saveList (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberBank/submit',
    method: 'post',
    data: data
  })
}

//删除
export function deleteList (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberBank/remove?ids='+data,
    method: 'post',
    data: data
  })
}

//字典省市
export function getRegionTree (data) {
  return request({
    url: '/api/blade-system/region/lazy-list',
    method: 'get',
    params: data
  })
}

//字典省市
export function getCode (data) {
  return request({
    url: '/api/blade-system/region/lazy-tree',
    method: 'get',
    params: data
  })
}

//省市区
export function findAreaListByParentId (data) {
  return request({
    url: '/api/huizhuyun-management/areasinfo/findAreaListByParentId',
    method: 'get',
    params: data
  })
}

//省
export function listProvince (data) {
  return request({
    url: '/api/huizhuyun-management/evection-manage-info/specialDetail',
    method: 'get',
    params: data
  })
}
// 订单详情
export function getOrderDetail (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/getPayMethod',
    method: 'get',
    params: data
  })
} 