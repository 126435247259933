export const validPayPassword = (rule, value, callback) => {
  let reg = /^[0-9]{6,6}$/gim;
  if (!reg.test(value)) {
    callback(new Error('请输入6位数字！'))
  } else {
    callback()
  }
};
export const validPassword = (rule, value, callback) => {
  let reg = /^[0-9A-Za-z]{6,20}$/
  if (!reg.test(value)) {
    callback(new Error('请输入6-20位的数字或字母，不支持特殊符号！'))
  } else {
    callback()
  }
};

export const validPhoneNumber = (rule, value, callback) => {
  let reg = /^1[3456789]\d{9}$/
  if (!reg.test(value)) {
    callback(new Error('请输入11位手机号！'))
  } else {
    callback()
  }
}

export const isTrue = (o, k, t = '暂无') => {
  if (o[k] !== '' && o[k] != null && o[k] != undefined && JSON.stringify(o[k]) != '{}') {
    return o[k]
  } else {
    return t
  }
}
export function filterFields(OrigForm, nowForm) {
  return Object.entries(OrigForm).reduce(
    (a, [key, val]) => {
      if (key in nowForm) a[key] = val;
      return a;
    }, {}
  );
}