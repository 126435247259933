import { bidCommodityList } from '@/api/purchaser/myBidding'

function getZhaoBiaoList (data, _this) {
  _this.txtArray = [data.shopDTOList.orderTime, '未支付']
  _this.invoiceForm.payPriceReal = data.shopDTOList.pay
  let str = [];
  str.push({
    shopName: data.shopDTOList.shopName,
    goodsDTOList: [],
    freightPriceReal: data.shopDTOList.freightPrice,
    orderTime: data.shopDTOList.orderTime,
    businessId: data.shopDTOList.businessId,
    memberInfoId: data.shopDTOList.memberInfoId
  });
  data.shopDTOList.goodsDTOList.forEach(el => {
    str[0].goodsDTOList.push({
      fileUrl: el.fileUrl || '',
      skuName: el.procurementName,
      skuSpec: el.procurementModel,
      unit: el.procurementUnit,
      skuNum: el.procurementNumber,
      skuPriceReal: el.unitPrice,
      id: el.id
    })
  })
  return str
}

export async function isZhaobiao (ctx, globalWind) {
  ctx.invoiceForm.orderSource = globalWind.$route.query.orderSource
  ctx.invoiceForm.orderType = globalWind.$route.query.orderType
  ctx.invoiceForm.demandId = globalWind.$route.query.demandId
  ctx.invoiceForm.demandName = globalWind.$route.query.demandName
  ctx.invoiceForm.demandCode = globalWind.$route.query.demandCode
  let res = await bidCommodityList({ tenderId: globalWind.$route.query.id });
  ctx.invoiceForm.shopDTOList = getZhaoBiaoList(res.data, ctx);
}