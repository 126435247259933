import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * Note: 路由配置项
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: "/handleLogout",
    component: (resolve) => require(['@/views/handleLogout/index'], resolve)
  },
  // {
  //   path: '/login',
  //   component: (resolve) => require(['@/views/login'], resolve)
  // },
  {
    path: '/register',
    component: (resolve) => require(['@/views/register/index'], resolve)
  },
  {
    path: '/forget',
    component: (resolve) => require(['@/views/forget/index'], resolve)
  },
]


const newRouter = new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

export default newRouter
// 重置路由
export function resetRouter () {
  router.matcher = newRouter.matcher // 重新设置路由的可匹配路径
}
