<template>
  <div>
    <page-top-title name="确认付款"></page-top-title>
    <order-water :step="2" :txtArray="txtArray" :isPayed="isPayed" />
    <div class="box">
      <div class="intrudct">
        <div>
          <div class="flex">
            <div class="big">
              <i class="el-icon-circle-check cost"></i>
              <span v-if="!isPayed">订单已提交，请尽快完成支付！</span>
              <span v-else>{{ msg?msg:'订单正在处理中！' }}</span>
            </div>
            <!-- <div class="title">
              逾期订单将被取消
            </div> -->
          </div>
          <div>
            订单编号：{{ orderCode }} &nbsp;&nbsp;&nbsp;供应商：{{ shopName }}
          </div>
        </div>
        <div class="flex">
          <div>实付款（元）:</div>
          <div class="orange">￥ {{ (+price || 0).toFixed(2) }}</div>
        </div>
      </div>
    </div>
    <div class="box">
      <el-collapse v-if="!isPayed" v-model="activeNames" @change="handleChange" accordion>
        <el-collapse-item v-if="showBank&& ispayMethod!=4" title="银行卡支付" name="1">
          <div class="flexBox">
            <div class="flex-btn" v-for="item in bankList" :key="item.id" @click="signPay(item)">
              <div>
                <img src="@/images/ylpay.jpg" />
              </div>
              <div>
                <div>{{ item.name || '暂无' }}</div>
                <div class="stx">{{ jadeCode(item.bankCode) }}</div>
              </div>
            </div>
            <div class="flex-btn" @click="$router.push('/supplier/bankcard/index')">
              <div>
                <i class="el-icon-plus" style="font-size:22px"></i>
              </div>
              <div>
                <div>添加银行卡</div>
              </div>
            </div>
          </div>
          <!-- <div class="box">
                                                    <div class="title">支付密码</div>
                                                    <div>
                                                      <el-input type="password" v-model="password" placeholder="请输入密码" show-password clearable></el-input>
                                                      <span class="forget" @click="forgetPassword">忘记密码?</span>
                                                    </div>
                                                    <div style="margin:10px">
                                                      <el-button type="primary" round @click="sure">确认支付</el-button>
                                                    </div>
                                                  </div> -->
        </el-collapse-item> 
        <!-- && ispayMethod!=4 -->
        <el-collapse-item v-if="showB2b&& ispayMethod!=4" title="公对公支付" name="3">
          <div class="flexBox">
            <div class="flex-btn" @click="btbt">
              <div>
                <i :class="['iconfont', 'icon-feiyongzhifu', 'svg-style']" style="color:#409EFF"></i>
              </div>
              <div>
                <div class="stx">公对公支付</div>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="线下汇款" name="2">
          <div class="flexBox" v-if="false">
            <div class="flex-btn" v-for="item in corporList" :key="item.id"
              @click="go('/commons/corporate-transfer/index', item)">
              <div>
                <i :class="['iconfont', 'icon-feiyongzhifu', 'svg-style']" style="color:#409EFF"></i>
              </div>
              <div>
                <div>{{ item.bankName || '暂无' }}</div>
                <div class="stx">{{ jadeCode(item.bankCode) }}</div>
              </div>
            </div>
            <div class="flex-btn" @click="$router.push('/supplier/bankcard/index?bankType=transfer')">
              <div>
                <i class="el-icon-plus cost" style="font-size:22px"></i>
              </div>
              <div>
                <div>添加线下汇款账户</div>
              </div>
            </div>
          </div>
          <div class="flexBox">
            <div class="flex-btn" @click="go('/commons/corporate-transfer/index')">
              <div>
                <i :class="['iconfont', 'icon-feiyongzhifu', 'svg-style']" style="color:#409EFF"></i>
              </div>
              <div>
                <div class="stx">线下汇款</div>
              </div>
            </div>
          </div>
        </el-collapse-item>

        
        <el-collapse-item v-if="showWx&& ispayMethod!=4" title="其他支付" name="4">
          <el-button :loading="isPaying" @click="wxpay" class="pay-btn" v-reClick="3000"><i
              :class="['iconfont', 'icon-weixin', 'svg-style']" style="color:#67C23A"></i>微信支付</el-button>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-dialog title="微信支付" :visible="dialogVisible" width="50%" append-to-body :show-close="false">
      <div class="code-img" id="qrcode" v-if="dialogVisible"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" size="mini" plain @click="cancelPay">取 消 支 付</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="isShowSignDialog" :close-on-click-modal="false" append-to-body width="27%" title="支付-银行卡">
      <el-form :model="phoneForm" :rules="rules">
        <el-form-item label="手机号">
          <el-input v-model="signItem.bankPhone" placeholder="请输入手机号" style="width:60%" disabled></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="verification">
          <el-input v-model="phoneForm.verification" placeholder="请输入验证码" style="width:30%"></el-input>
          <el-button type="primary" style="margin-left:20px" plain @click="sendCode">{{
            btnTxt
          }}</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="sureConfirmSign" plain :disabled="sureBtnDis">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getList ,getOrderDetail} from '@/api/bankcard/bankcard' 
import { pay, getPayStatus, getDGZZList ,otherPayB2B,getPayStatusAll} from '@/api/purchaser/myBidding'
import {
  wxpay,
  wxpayResult,
  otherSignPay,
  otherSignSelect,
  otherPaySms,
  otherPayBack,
  detail
} from '@/api/purchaser/myBiddingOrder'

import {showSellAccount} from '@/api/fund/fund'

import QRCode from 'qrcodejs2'
export default {
  data () {
    return {
      timer:null,
      sureBtnDis: true,
      msg: '',
      isPaying: false,
      isPayed: false,
      signItem: {},
      btnTxt: '发送',
      rules: {
        verification: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      phoneForm: {
        verification: ''
      },
      isShowSignDialog: false,
      txtArray: [],
      shopName: '',
      orderCode: '',
      price: '',
      activeNames: '',
      password: '',
      form: {
        payMethod: '', //支付方式(1银行支付,2支付宝3微信4对公转账5余额
        payType: '购买商品', //购买会员,充值,购买商品
        money: '', //支付金额(单位元)
        orderCodeList: [] //订单号
      },
      bankList: [],
      corporList: [],
      dialogVisible: false,
      timeInter: '',
      orderSource: '',
      showB2b : true,
      showWx: true,
      showBank: true,
      ispayMethod:''
    }
  },
  components: {},
  destroyed () { },

  activated () { },
  mounted () {
    window.localStorage.setItem('wxPayQrCodeUrl', '')
    this.txtArray = this.$store.state.params.txtArray
    this.shopName = this.$store.state.params.shopName
    this.orderCode = this.$store.state.params.orderCode
    let orderCodes = this.orderCode ? this.orderCode.split(',') : '' 
    for (let i in orderCodes) {
      let code = {}
      if (orderCodes[i]) {
        code.orderCode = orderCodes[i]
        this.form.orderCodeList.push(code)
      }
    }
    // this.getPayStatusData(orderCodes)
    // this.form.orderCodeList[0].orderCode = this.$store.state.params.orderCode

    this.price = this.$store.state.params.price
    this.form.money = this.$store.state.params.price
    this.orderSource = this.$store.state.params.orderSource
    this.getList() 
    showSellAccount(orderCodes).then((res) => {
      if (res.code == 200) {
        this.showB2b = res.data.b2b ? res.data.b2b:false
        this.showWx = res.data.wx ? res.data.wx:false
        this.showBank = res.data.bank ? res.data.bank:false
      }
    })
    this.getDetail()
  },
  computed: {
    jadeCode () {
      return function (val) {
        if (val)
          return val.substring(0, 4) + '******' + val.substring(13, val.length)
      }
    }
  },
  methods: {
    async getDetail(){
      getOrderDetail({orderCode:this.form.orderCodeList[0].orderCode}).then(res=>{
        if(res.success){ 
          this.ispayMethod=res.data.payMethod
          this.form.payMethod = res.data.payMethod
          if(res.data.payMethod==4){
            this.$message.error('您选择的支付方式为线下汇款，无法更改付款方式。')
          }
        }
        
      })
      // detail({id:}).then(res=>{})
    },
    async  btbt(){
      var that=this
      let p = {
        money: this.price,
        orderCodeList: this.form.orderCodeList,
        payType: '购买商品'
      }
      await otherPayB2B({ ...p }).then((res) => {
        if (res.code == 200) {
          // this.selectResult(res.data.key)
          if(res.data){
            // this.$store.commit('SET_PARAMS', {
            //   txtArray: that.txtArray,
            //   price: that.price,
            //   orderCode: that.orderCode,
            //   orderSource: that.orderSource
            // })
            let { href } = this.$router.resolve({
              path: "/commons/btbt-pay/index",
              query: { HTMLtext: res.data }
            })
              var orderInfoDTOList = this.orderCode.split(',').map((item) => {
                return { orderCode: item }
              })
            this.timer=setInterval(()=>{
              console.log()
              getPayStatusAll({orderInfoDTOList}).then((timerRes)=>{
                  console.log(timerRes.data.status)
                if (timerRes.data.status == 2) {
                  this.isPayed = true
                }
                if (timerRes.data.status == 3) {
                  console.log(this.orderCode)
                  debugger
                  this.$store.commit('SET_PARAMS', {
                    txtArray: this.txtArray,
                    price: this.price,
                    orderCode: this.orderCode,
                    orderSource: this.orderSource
                  })
                  clearInterval(this.timer)
                  this.$router.push({
                    path: '/commons/pay-success/index'
                  })
                }
                if (timerRes.data.status == 4) {
                  window.location.reload()
                }
              })

            },2000)
            window.open(href, '_blank')
          }

        } else {
          // this.$message.warning(res.msg)
          this.isShowSignDialog = false
          setTimeout(() => {
            window.location.reload()
          }, 1200)
        }
      })
    //  const res = await otherPayB2B(p)
    //  console.log(res.data)
    //
    },
    async getPayStatusData (orderCodes) {
      if (orderCodes && orderCodes.length) {
        let orderInfoDTOList = orderCodes.map((item) => {
          return { orderCode: item }
        })
        const res = await getPayStatus({ orderInfoDTOList })
        if (res.code == 200) {
          this.isPayed = false
        } else if (res.code == 400) {
          this.msg = res.msg
          this.isPayed = true
        }
      }
    },
    sureConfirmSign () {
      let p = {
        merOrderNo: this.signItem.id,
        cardNo: this.signItem.bankCode,
        accName: this.signItem.name,
        certType: this.signItem.cardType,
        certNo: this.signItem.idCard,
        mobileNo: this.signItem.bankPhone,
        mobileAuthCode: this.phoneForm.verification,
        money: this.price
        // "orderCodeList": this.form.orderCodeList
      }
      otherPayBack({ ...p }).then((res) => {
        if (res.code == 200) {
          this.selectResult(res.data.key)
        } else { 
          debugger
          this.isShowSignDialog = false
          setTimeout(() => {
            window.location.reload()
          }, 1200)
        }
      })
    },
    sendCode () {
      // if (!this.istel(this.signItem.bankPhone)) {
      //   this.$message.warning('请输入正确的手机号')
      //   return
      // }
      let p = {
        // "merOrderNo": this.signItem.id,
        cardNo: this.signItem.bankCode,
        accName: this.signItem.name,
        certType: this.signItem.cardType,
        certNo: this.signItem.idCard,
        mobileNo: this.signItem.bankPhone,
        money: this.price,
        orderCodeList: this.form.orderCodeList,
        payType: '购买商品'
      }

      otherPaySms({ ...p }).then((res) => {
        if (res.code == 200) {
          this.sureBtnDis = false
          this.signItem.id = res.data.MerOrderNo
          if (this.btnTxt == '发送') {
            this.btnTxt = 60
          }
          if (this.btnTxt != '发送') {
            let send = setInterval(() => {
              this.btnTxt -= 1
              if (this.btnTxt == 0) {
                this.btnTxt = '发送'
                clearInterval(send)
              }
            }, 1000)
          }
        } else {
          // this.$message.warning(res.msg)
        }
      })
    },
    async signPay (item) {
      let res = await otherSignSelect({ cardNo: item.bankCode })
      if (res.code == 200) {
        this.signItem = { ...item }
        this.isShowSignDialog = true
      } else {
        // this.$message.warning(res.msg)
      }
    },
    forgetPassword () {
      this.$router.push('/commons/password/same?title=找回支付')
    },
    cancelPay () {
      this.dialogVisible = false
      clearInterval(this.timeInter)
    },
    wxpay () {
      if (window.localStorage.getItem('wxPayQrCodeUrl')) {
        let res = {
          data: {
            url: window.localStorage.getItem('wxPayQrCodeUrl'),
            key: window.localStorage.getItem('wxPayQrCodeKey')
          }
        }
        this.createQRcode(res)
        return
      }
      wxpay({
        payMethod: 3, //支付方式(1银行支付, 2支付宝3微信4对公转账5余额
        payType: '购买商品', //购买会员, 充值
        money: this.form.money, // 支付金额(单位元)
        orderCodeList: [
          {
            orderCode: this.orderCode
          }
        ] //订单号
      }).then((res) => {
        if (res.code == 400) {
          // this.$message.error(res.msg)
        } else if (res.code == 200) {
          this.createQRcode(res)
        }
      })
    },
    createQRcode (res) {
      this.dialogVisible = true
      this.isPaying = true
      setTimeout(() => {
        let url = window.localStorage.getItem('wxPayQrCodeUrl') || res.data.url
        new QRCode(document.getElementById('qrcode'), {
          text: url, // 需要转换为二维码的内容
          width: 250,
          height: 250,
          colorDark: '#000', // 生成的二维码的深色部分
          colorLight: '#fff', //生成二维码的浅色部分
          correctLevel: QRCode.CorrectLevel.H //纠错等级
        }) // 设置要生成二维码的链接
        window.localStorage.setItem('wxPayQrCodeUrl', res.data.url)
        this.isPaying = false
      }, 10)
      window.localStorage.setItem('wxPayQrCodeKey', res.data.key)
      let key = window.localStorage.getItem('wxPayQrCodeKey') || res.data.key
      this.selectResult(key)
    },
    selectResult (key) {
      this.timeInter = setInterval(() => {
        wxpayResult(key)
          .then((res) => {
            if (res.data == 100) {
              this.$message.success('支付成功')
              this.isShowSignDialog = false
              this.dialogVisible = false
              window.localStorage.setItem('wxPayQrCodeUrl', '')
              window.localStorage.setItem('wxPayQrCodeKey', '')
              setTimeout(() => {
                this.routerGo()
                clearInterval(this.timeInter)
              }, 800);
            } 
            if (res.data.code == 400) { 
              this.isShowSignDialog = false
              this.dialogVisible = false
              window.localStorage.setItem('wxPayQrCodeUrl', '')
              window.localStorage.setItem('wxPayQrCodeKey', '')
              setTimeout(() => {
                clearInterval(this.timeInter)
                window.location.reload()
              }, 800)
            }
          })
          .catch((err) => {
            this.dialogVisible = false
            this.isShowSignDialog = false
            window.localStorage.setItem('wxPayQrCodeUrl', '')
            window.localStorage.setItem('wxPayQrCodeKey', '')
            setTimeout(() => {
              window.location.reload()
              clearInterval(this.timeInter)
            }, 1200)
          })
      }, 1000)
    },
    getList () {
      getList({ bankType: 1, current: 1, size: 1000, status: '2' }).then(
        (res) => {
          this.bankList = res.data.records
        }
      )
      getList({ bankType: 2, current: 1, size: 1000 }).then((res) => {
        this.corporList = res.data.records
      })
    },
    async go (path = '', item = '') {
      // this.form.corAccountId = item.id
      this.form.payMethod = '4'
      let res = await pay(this.form)
      if (res.code != 200) {
        return
        // this.$message({
        //   type: 'error',
        //   message: res.msg
        // })
      }
                  console.log(this.orderCode)
                  debugger
      this.$store.commit('SET_PARAMS', {
        txtArray: this.txtArray,
        price: this.price,
        orderCode: this.orderCode,
        shopName: this.shopName
      })
      this.$router.push(path)
    },
    routerGo () {
      this.$store.commit('SET_PARAMS', {
        txtArray: this.txtArray,
        price: this.price,
        orderCode: this.orderCode,
        orderSource: this.orderSource
      })
      this.$router.push({
        path: '/commons/pay-success/index'
      })
    },
    handleChange () { }
  },
  beforeDestroy(){
    clearInterval(this.timer);

  }
}
</script>
<style scoped lang='scss'>
.box {
  box-sizing: border-box;
  padding: 15px 10px;

  .forget {
    color: #3096d1;
    margin-left: 10px;
    cursor: pointer;
  }

  .el-input {
    width: 300px;
  }

  .flexBox {
    display: flex;
    flex-wrap: wrap;

    .flex-btn {
      .cost {
        font-size: 30px;
      }

      div {
        margin: 0 4px;
      }

      cursor: pointer;
      margin: 5px 10px;
      box-sizing: border-box;
      padding: 3px 20px;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-left: 1px solid #eeeff0;
      border-top: 1px solid #eeeff0;
      box-shadow: 3px 2px 1px #efeff1;

      .stx {
        color: #5b5c5e;
        font-size: 12px;
      }

      img {
        height: 25px;
      }
    }

    .flex-btn:hover {
      border-left: 1px solid rgba(4, 127, 243, 0.1);
      border-top: 1px solid rgba(4, 127, 243, 0.1);
      box-shadow: 3px 2px 1px rgba(4, 127, 243, 0.1);
    }
  }

  .intrudct {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #409eff;
    background-color: rgba(184, 213, 240, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cost {
      color: #24eb12;
      font-size: 30px;
    }

    .big {
      color: #191a1b;
      font-size: 24px;
      margin: 12px;
    }

    div {
      margin: 4px;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: #303133;
        font-size: 16px;
        margin: 12px;
      }

      .orange {
        color: #eb6912;
        font-size: 26px;
      }
    }
  }

  .title {
    color: #303133;
    font-size: 14px;
    margin: 12px;
  }

  .orange {
    display: block;
    color: #e65a09;
    font-size: 22px;
  }
}

.code-img {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

::v-deep .pay-btn {
  height: 38px;
  padding: 1px 20px !important;

  .svg-style {
    margin-right: 5px;
  }
}
</style>