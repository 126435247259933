<template>
  <div class="logo" style="cursor: pointer">
    <img v-if="platformSiteData && platformSiteData.logoImg"  :src="platformSiteData.logoImg" alt=""  />
    <img v-else
      src="@/assets/lcidp-logo.png"
      alt=""
    />
    <div class="name">
      <div class="title">
         {{platformSiteData&&platformSiteData.baseTitle?platformSiteData.baseTitle:'辽宁省建筑产业数字化平台'}}
      </div>
      <div class="sub">
         {{platformSiteData&&platformSiteData.subTitle?platformSiteData.subTitle:'助力辽宁全面振兴新突破三年行动|推动辽宁建筑产业高质量发展'}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
   props: {
    platformSiteData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.logo{
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  img{
    height: 56px;
  }
  .name{
    margin-left: 14px;
    .title{
      color: #084198;
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
    }
    .sub{
      color: #7B7B7B;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      float: left;
    }
  }
}
</style>