<template>
  <div>
    <page-top-title name="对公转账"></page-top-title>
    <order-water :step="2" :txtArray="txtArray" v-if="$store.state.params.type != '充值'" />
    <div class="big-box">
      <div class="box">
        <div class="intrudct">
          <div>
            <div class="flex">
              <div class="big">
                <i class="el-icon-circle-check cost"></i> 订单已提交，请尽快完成支付！
              </div>
            </div>
            <div>
              您的汇款识别码 {{ orderCode }} 汇款填写用途/备注/摘要栏，便于快速核销款项。
            </div>
          </div>
          <div class="flex">
            <div>实付款（元）:</div>
            <div class="orange">{{(+price || 0).toFixed(2)}}</div>
          </div>
        </div>
        <div style="margin-top:10px" id="order">
          <el-table :data="datalist" border stripe :show-header="false">
            <el-table-column prop="dictValue" label=""></el-table-column>
            <el-table-column prop="remark" label=""></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="parks">
        <div class="orange">注意事项（转账必读）</div>
        <div class="title">
          线下公司转账说明：
          <div>（1）汇款时请备注汇款识别码，用于保证订单及时核销。此识别码务必填写正确，请勿增加其他文字说明；</div>
          <div>（2）汇款识别码需填至汇凭单[汇款用途] 、[附言]等内(因不同银行备注不同，最好在所有可填写备注的地方均填写)；</div>
          <div>（3）一个识别码对应一个订单和金额，请勿多转账或少转账；</div>
          <div>（4）请您在页面提示的时效内完成支付，公司转账订单款项对账日期为 7 个工作日(从支付之日算起)，超支付时(从下单之日算起)如果还未付款并到账，系统将自动取消订单；</div>
          <div>（5）公司账户代个人账户打款，需填写公司代个人账号打款声明；</div>
          <!-- <div>注：汇款公司账户信息，请以下单提示信息为准。</div> -->
        </div>
      </div>
    </div>
    <div style="margin:30px;text-align:center">
      <el-button type="primary" size="default" @click="copys">一键复制</el-button>
      <el-button type="primary" size="default" @click="uploadPicture">上传凭证</el-button>
    </div>
    <el-dialog :visible.sync="isShowSignDialog" :before-close="beforeClose" :close-on-click-modal="false" append-to-body width="400px" title="上传凭证">
      <el-form ref="urlForm" :model="urlForm" :rules="rules">
        <el-form-item label="" prop="certificateUrl">
          <upload-attachment-file-oss
          :limit="1"
                :showTip="true" 
                :acceptString="'jpg,jpeg,png,bmp,JPG,JPEG,PNG,BMP'"
                title="点击上传"
                :file-list="fileListOss"
                multiple 
                @change="handleUpload"
                @btnDisabled="uploadIng" 
                > 
                </upload-attachment-file-oss> 
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="sureConfirmSign" plain :disabled="isUploading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pay, getDGZZList,uploadCertificate} from '@/api/purchaser/myBidding'
import { initData } from './index'
export default {
  data () {
    return { 
      urlForm:{certificateUrl:''},
      isShowSignDialog:false,
      isUploading:false,
      fileListOss:[],
      ...initData(),
      rules:{
        certificateUrl: [
          { required: true, message: '请输入上传凭证', trigger: 'change' }
        ],
      }
    }
  },
  components: {

  },
  created () {

  },
  mounted () {
    this.orderCode = this.$store.state.params.orderCode
    this.price = this.$store.state.params.price
    this.txtArray = this.$store.state.params.txtArray
    this.getDGZZList()
  },
  methods: {
    uploadIng(val) {
      val == 'loading' ? (this.isUploading = true) : (this.isUploading = false)
    },
    checkAll(row) {
      this.$refs.urlForm.validateField(row)
    }, 
    beforeClose(){
      debugger
      this.isShowSignDialog=false
      this.fileListOss=[] 
      this.urlForm.certificateUrl =''
    },
    handleUpload(v,i){
      this.fileListOss=v  
      if(v.length>0){
        this.$set(this.urlForm,'certificateUrl',v[0].filePath)
      }else{
        this.urlForm.certificateUrl =''
      } 
      this.$refs.urlForm.validate()
      debugger
    },
    routerGo () {
      debugger
      this.$router.push({
        path: '/commons/pay-success/index'
      })
    },
    // 上传凭证
    sureConfirmSign(){
      this.$refs.urlForm.validate((valid) => {
      let params={}
      params.certificateUrl=this.urlForm.certificateUrl
      params.orderCode=this.orderCode
        if (valid) { 
      this.isUploading=true
          uploadCertificate(params).then(res=>{ 
            if(res.success){
            this.isShowSignDialog=false
            this.$message.success('上传成功')
            setTimeout(()=>{this.routerGo()},2000)
            }else{
              this.$message.error(res.data.msg)

              this.isUploading=false
            }
            
          }).catch(e=>{
            this.isUploading=false 
          })
        }})
     
    },
    uploadPicture(){
      this.isShowSignDialog=true
    },
    getDGZZList () {
      getDGZZList().then(res => {
        this.datalist = res.data[0].children
      })
    },
    copys () {
      this.copyText(this.selectText(document.getElementById('order')));
    },
    /**
   * 返回当前元素的文本内容
   * @parm {DOM} element 当前DOM元素
   */
    selectText (element) {
      return element.innerText;
    },
    /**
     * @param {String} text 需要复制的内容
     * @return {Boolean} 复制成功:true或者复制失败:false  执行完函数后，按ctrl + v试试
    */
    copyText (text) {
      var textValue = document.createElement('textarea');
      textValue.value = text;
      document.body.appendChild(textValue); //将textarea添加为body子元素
      textValue.select();
      var res = document.execCommand('copy');
      document.body.removeChild(textValue);//移除DOM元素
      return res;
    }
  },
}

</script>
<style scoped lang='scss'>
.big-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;

  .box {
    box-sizing: border-box;
    padding: 15px 10px;
    width: 100%;


    .intrudct {
      position: relative;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #409EFF;
      background-color: rgba(184, 213, 240, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cost {
        color: #24eb12;
        font-size: 30px;
      }

      .big {
        color: #191a1b;
        font-size: 24px;
        margin: 12px;
      }


      div {
        margin: 4px
      }

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          color: #303133;
          font-size: 16px;
          margin: 12px;
        }

        .orange {
          color: #eb6912;
          font-size: 26px;
        }
      }

    }



    .title {
      color: #303133;
      font-size: 14px;
      margin: 12px;
    }

    .orange {
      display: block;
      color: #e65a09;
      font-size: 22px;
    }
  }

  .parks {
    box-sizing: border-box;
    background-color: rgba(250, 173, 86, 0.3);
    padding: 15px;
    width: 100%;

    .orange {
      display: block;
      color: #e65a09;
      font-size: 16px;
    }

    .title {
      color: #303133;
      font-size: 14px;
      margin: 12px;
    }

    div {
      margin: 5px 0;
    }
  }
}
</style>