<template>
  <div class="box">
    <div class="order-water">
      <div class="order-water"
           v-for="(item, idx) of customArray"
           :key="idx">
        <div>
          <div :class="requireImages(idx + 1).imgStyle">
            <img :src="`${require(`@/assets/images/order/${requireImages(idx + 1).color}.png`)}`" />
          </div>
          <div class="order-remark">
            <div class="big">{{ item }}</div>
            <div class="mini">{{ txtArray[idx] }}</div>
          </div>
        </div>
        <div :class="requireImages(idx + 1).line"
             v-if="linesShow(idx)"
             :style="{ 'width': isWidthEnough }"></div>
      </div>
    </div>
    <div style="height:30px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {
    linesShow() {
      return function (val) {
        return val < this.waterArrays.length - 1
      }
    },
    requireImages() {
      return function (val) {
        if (this.step >= val) {
          return {
            color: `bl-${this.waterArrays[val - 1]}`,
            imgStyle: 'imgbox-blue',
            line: 'line-blue'
          }
        } else {
          return {
            color: `gray-${this.waterArrays[val - 1]}`,
            imgStyle: 'imgbox-black',
            line: 'line-black'
          }
        }
      }
    },
    isWidthEnough() {
      if (this.waterArrays.length == 5) {
        if (document.body.offsetWidth > 1480) {
          return '150px'
        }
        if (
          document.body.offsetWidth < 1480 &&
          document.body.offsetWidth > 1250
        ) {
          return '100px'
        }
        if (document.body.offsetWidth < 1250) {
          return '60px'
        }
      } else if (this.waterArrays.length == 3) {
        if (document.body.offsetWidth > 1480) {
          return '360px'
        }
        if (
          document.body.offsetWidth < 1480 &&
          document.body.offsetWidth > 1250
        ) {
          return '270px'
        }
        if (document.body.offsetWidth < 1250) {
          return '170px'
        }
      }
    }
  },
  watch: {
    // isPayed(val) {
    //   if (val && this.txtArray.length > 1) {
    //     this.txtArray.splice(1, 1, '已支付')
    //   }
    // }
  },
  props: {
    isPayed: {
      type: Boolean,
      default: () => false
    },
    txtArray: {
      type: Array,
      default: () => []
    },
    //遍历的数据项
    customArray: {
      type: Array,
      default: () => ['买家下单', '付款', '卖家发货', '确认收货', '完成评价']
    },
    //被切割的本地图片资源地址,文件命名必须以：bl-，gray-开头，如需改动，看：requireImages的计算属性
    waterArrays: {
      type: Array,
      default: () => ['down', 'pay', 'send', 'recive', 'comment']
    },
    //目前是第几步
    step: {
      type: Number,
      default: () => 1
    }
  }
}
</script>
<style scoped lang='scss' type="text/css">
.box {
  box-sizing: border-box;
  padding: 10px 20px;

  .order-water {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .imgbox-blue {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      border: 3px solid #0079fe;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 30px;
        width: 30px;
      }
    }

    .line-blue {
      margin-top: 30px;
      height: 2px;
      border-bottom: 2px solid #0079fe;
    }

    .imgbox-black {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      border: 3px solid #bfbfbf;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 30px;
        width: 30px;
      }
    }

    .line-black {
      height: 2px;
      border-bottom: 2px solid #bfbfbf;
      margin-top: 30px;
    }
  }

  .order-remark {
    position: relative;
    text-align: center;

    .big {
      position: absolute;
      top: 5px;
      width: 100px;
      font-size: 16px;
      left: -20px;
      text-align: center;
      white-space: nowrap;
    }

    .mini {
      position: absolute;
      top: 28px;
      width: 100px;
      font-size: 12px;
      left: -20px;
      text-align: center;
      color: #909399;
      white-space: nowrap;
    }
  }
}
</style>