import Vue from 'vue'
import App from './App.vue'

import store from './store'
import './utils/tracker.js';
import ElementUI from './globalAttributes/element';
import 'element-ui/lib/theme-chalk/index.css';
import './permission'; // permission control
// import animated from 'animate.css'
import components from './globalAttributes'
import { isTrue } from './utils/formValid'
import BaiduMap from 'vue-baidu-map'
import router from './router'
import Directives from './utils/direct'

window.Vue = Vue
// Vue.use(animated);
Vue.use(ElementUI);
Vue.use(components);
Vue.use(Directives);
Vue.use(BaiduMap, {
  ak: 'tGmW5qU0K0MIhQbo5AsPrYIr4TRVeBYo'
})

import './utils/dialogDrag.js';
import "./styles/font/iconfont.js"
import "./styles/font/iconfont.css"
import "./utils/filters.js"
import "./utils/amountCalc.js"
import './styles/common.scss';
import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI)

Vue.prototype.$bus = new Vue()

import $util from './utils/util'
Vue.use($util);

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

import * as $echarts from 'echarts';
// import vECharts from 'vue-echarts'
// Vue.component('v-chart', vECharts)

import 'swiper/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import _ from 'lodash'
let prototypes = {
  _,
  $echarts
};

Object.keys(prototypes).forEach((key) => {
  Vue.prototype[key] = prototypes[key];
});

Vue.config.productionTip = false

const install = function (Vue, opts = {}) {
  const defaultOption = {
    size: opts.size || 'medium', // element ui 大小 medium | small | mini
  }
  Vue.prototype.$Hzy = Object.assign(opts, defaultOption);
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

Vue.prototype.$isTrue = isTrue
Vue.prototype.$bus = new Vue() // 设置事件总线

// if (process.env.NODE_ENV == "development") {
//   TheUrl = "http://web.localhost.sy:3000"
//   // TheUrl = "http://10.1.2.248:3000/"
// } else if (process.env.NODE_ENV == "test") {
//   TheUrl = "http://erp.hzy.test:8070"
// } else if (process.env.NODE_ENV == "pre") {
//   TheUrl = 'http://erp.hzy.pre:8070'
// } else if (process.env.NODE_ENV == "production") {
//   TheUrl = 'https://www.huizhuyun.com'
// }else if (process.env.NODE_ENV == "local") {
//   TheUrl = 'http://industry.hzy.local:8070'
// }

// console.log(TheUrl)


// export { TheUrl }
// Vue.prototype.$JumpUrl = TheUrl
import { Quill } from 'vue-quill-editor';
import imageResize  from 'quill-image-resize-module' // 调整大小组件。
import { ImageDrop } from 'quill-image-drop-module'; // 拖动加载图片组件。
Quill.register('modules/imageResize', imageResize );
Quill.register('modules/imageDrop', ImageDrop);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
