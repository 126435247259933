<template>
  <div>
    <el-button :class="computClass" type="text" :size="$Hzy.size" @click="clickme" :disabled="disabled">
      <svg class="ali-icon" aria-hidden="true">
        <use :xlink:href="computIcon"></use>
      </svg>
      <slot></slot>
    </el-button>
  </div>
</template>
<script>
//定义类型类似枚举的对象
const clsObj = {
  w: "text-warning",
  d: "text-danger",
  s: "text-success",
  p: "text-primary",
  i: "text-info",
  pur: "text-purple"
}
const iconObj = {
  xq: "#icon-detail",//详情
  xg: "#icon-edit",//修改
  sc: "#icon-del",//删除
  kb: "#icon-kaibiao",//开标
  gb: "#icon-guanbi",//关闭
  bzjjn: "#icon-tubiaozhizuomoban-113",//保证金缴纳
  cx: "#icon-chexiao",//撤销
  cqwj: "#icon-wenjian",//澄清文件
  ss: "#icon-songshen",//送审
  xz: "#icon-xiazai1",//下载
  ck: "#icon-chakan2"//查看
}
//定义类型类似枚举的对象
export default {
  data () {
    return {
      isWidthEnough: true
    }
  },
  props: {
    cls: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  mounted () {
    if (document.body.offsetWidth < 1580) {
      this.isWidthEnough = false
    }
  },
  computed: {
    computClass () {
      return clsObj[this.cls]
    },
    computIcon () {
      return iconObj[this.icon]
    }
  },
  methods: {
    clickme () {
      this.$emit('click')
    }
  }
}
</script>