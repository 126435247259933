import { getCode, sendCode, sendPayCode } from '@/api/login'
import { identifyingCode } from '@/api/standardCertification/standardCertification'
import { ref, watch } from "@vue/composition-api";
import { Notification } from 'element-ui'
export function initSetup (props, emit) {
  let code = ref('')
  let btn = ref('发送验证码')
  let disbtn = ref(false)
  let disInput = ref(false)

  watch(() => props.tel, (newValue, oldValue) => {
  })

  watch(() => props.disabled, (newValue, oldValue) => {
    disInput.value = newValue
  }, {
    immediate: true,
    deep: true
  })

  const bindValue = (e) => {
    emit('input', e)
  }

  //判断是否是手机号
  const istel = (tel) => {
    var reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
    return reg.test(tel);
  }

  const coutnDown = () => {
    disbtn.value = true
    btn.value = 60
    let timeInter = setInterval(() => {
      btn.value -= 1
      if (btn.value == 0) {
        btn.value = '发送验证码'
        disbtn.value = false
        clearInterval(timeInter)
      }
    }, 1000);
  }

  const send = () => {
    if (!istel(props.tel)) {
      Notification.warning({
        title: '请输入正确的11位手机号',
        duration: 2000
      })
      return
    }


    if (props.type == 'register') {
      getCode({ phoneNumber: props.tel }).then(res => {
        if (res.code == 200) {
          Notification.success({
            title: '注册成功',
            message: '您好！您的帐号 ' + props.tel + ' 已注册，请前往登录页面登录！',
            duration: 2000
          })
        } else {
          return sendCode({
            phone: props.tel,
            templateId: "",
            code: ""
          })
        }
      }).then(res => {
        if (res.code == 200) {
          coutnDown()
          Notification.success({
            title: '发送成功',
            duration: 2000
          })
        } else {
          Notification.warning({
            title: res.msg,
            duration: 2000
          })
        }
      })
    } else if (props.type == 'forget') {
      sendCode({
        phone: props.tel,
        templateId: "",
        code: ""
      }).then(res => {
        if (res.code == 200) {
          coutnDown()
          Notification.success({
            title: '发送成功',
            duration: 2000
          })
        } else {
          Notification.warning({
            title: res.msg,
            duration: 2000
          })
        }
      })
    } else if (props.type == 'pay') {
      sendPayCode({
        phone: props.tel
      }).then(res => {
        if (res.code == 200) {
          coutnDown()
          Notification.success({
            title: '发送成功',
            duration: 2000
          })
        } else {
          Notification.warning({
            title: res.msg,
            duration: 2000
          })
        }
      })
    } else if (props.type == 'standardCertification') {
      identifyingCode({
        phone: props.tel
      }).then(res => {
        if (res.code == 200) {
          coutnDown()
          Notification.success({
            title: '发送成功',
            duration: 2000
          })
        } else {
          Notification.warning({
            title: res.msg,
            duration: 2000
          })
        }
      })
    }
  }

  return {
    code,
    btn,
    disbtn,
    disInput,
    send,
    bindValue,
    istel
  }
}