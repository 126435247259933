var currentSysType = 'default'


if (location.origin == process.env.VUE_APP_LCIDP_SUB_DOMAIN_CENTER) {
  currentSysType = 'lcidp'
}
if (location.origin == process.env.VUE_APP_LCIDP_JC_DOMAIN_CENTER) {
  currentSysType = 'jc'
}
if (location.origin == process.env.VUE_APP_LCIDP_LW_DOMAIN_CENTER) {
  currentSysType = 'lw'
}
if (location.origin == process.env.VUE_APP_LCIDP_SB_DOMAIN_CENTER) {
  currentSysType = 'sb'
}
if (location.origin == process.env.VUE_APP_LCIDP_XM_DOMAIN_CENTER) {
  currentSysType = 'xm'
}
if (location.origin == process.env.VUE_APP_LCIDP_JR_DOMAIN_CENTER) {
  currentSysType = 'jr'
}

const sys = {
  state: {
    currentSysType: currentSysType
  },
}

export default sys
