<template>
  <div> 
    <el-upload 
      action="#"
      v-bind="$attrs"
      v-on="$listeners" 
      :file-list="resFileList"
      :before-upload="handleBeforeUpload" 
      :headers="headers"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :http-request="handleUploadFile"
      ref="upload">
        <slot></slot>
      </el-upload>
  </div>
</template>

<script>
import { getToken, getMemberToken, getSwitchToCompanyId } from '@/utils/auth'
import SparkMD5 from 'spark-md5'
import { ossStsRegister, ossStsRegisterCallback } from './upload'
import OSS from 'ali-oss'
import { Message } from 'element-ui'
import { constantRoutes } from '@/router'

export default {
  name: '',
  props: {
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    acceptString: {
      type: String,
      default: '.pdf,.zip,.rar,.7z,.bmp,.gif,.jpeg,.png,.avi,.mov,.mp4,.mpeg,.mpg,.rm,.rmvb,.mp3,.wav,.wma,.ogg,.aac,.txt,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.txt,.ftr'
    },
  },
//   components: { ElImageViewer },
  data() {
    return {
      headers: {
      type: Object,
      default: () => {
        return {
          Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
          'Blade-Auth': 'Bearer ' + getToken(),
          token: getMemberToken(),
          SwitchToCompanyId: getSwitchToCompanyId()
        }
      }
    },
      // 视频展示弹窗-from
      videoForm: {},
      // 视频展示弹窗-显示/隐藏
      open: false,
      // 用于视频旋转方向
      directionFlag: false,
      // 视频旋转角度
      direcation: 0,
      // 组件内-文件列表展示数组
      resFileList: [],
      // el-upload组件文件列表，防止未知错误，请勿删除
      // disFileList: [],
      // 优化需求：当随便点开某一条图片类型的数据预览时，支持缩放，顺逆时针旋转，上下一张。其中上下一张是在所有已上传的附件列表中，只需要过滤出图片类型的数据上下翻页，忽略非图片类型的附件。
      // 图片预览visible
      imagePreviewVisible: false,
      // 图片预览数组
      imagePreviewList: [],
      // 被预览的图片在数组中的索引。保证预览时第一张显示的是被预览的图片
      imagePreviewIndex: 0,
      // 通过图片的后缀来从附件列表中筛选出图片
      acceptImageTypeString: '.png,.tif,.jpg,.jpeg,.gif,.bmp,.svg',
      // 通过图片的后缀来从附件列表中筛选出图片
      acceptImageType: [
        '.png',
        '.tif',
        '.jpg',
        '.jpeg',
        '.gif',
        '.bmp',
        '.svg'
      ],
      // 预览可支持的文件格式
      officePreviewType: '.doc,.xlsx,.ppt,.xls,.xlsx,.docx,.pptx',
      // 视频播放器设置
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        sources: [
          {
            type: 'video/mp4', //这里的种类支持很多种：基本视频格式、直播、流媒体等
            src: null //url地址 "../../static/vedio/test1.mp4"
          }
        ],
        poster: null, //你的封面地址 "../../static/vedio/test.jpg"
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      // 结合hide、showUploadStatus、resFileList中文件上传的状态判断是否显和更新上传状态
      uploadStatus: {
        uploadPercent: 0,
        isUploading: false
      },
      /**
       * table样式附件列表属性
       */
      selectionData: []
    }
  },
  watch: { 
    // 用于监听视频旋转方向，如果方向是360的时候，自动恢复默认值0
    direcation: {
      handler(val) {
        if (val == 360) {
          this.direcation = 0
        }
      },
      deep: true
    },
    fileList:{
      handler(val) {
        this.getListInit()
      },
      deep: true
    },
    resFileList: {
      handler(val) {
        if (val.length == 0) {
          return
        }
        let sums = 0
        let percents = 0
        let statusList = []
        val.forEach((item) => {
          sums += 1
          percents += item.percentage
          statusList.push(item.status)
        })
        // 如果中包含ready的数据表示上传尚未成功。
        if (statusList.filter((item) => item == 'ready').length != 0) {
          this.$nextTick(() => {
            this.uploadStatus.isUploading = this.hide && this.showUploadStatus
            this.uploadStatus.uploadPercent = parseFloat(
              (percents / sums).toFixed(0)
            )
          })
        } else {
          // 否则表示都上传完毕（没有统计是否都成功）
          this.$nextTick(() => {
            this.uploadStatus.isUploading = false
            this.uploadStatus.uploadPercent = 0
          })
        }
      },
      deep: true
    }
  },
  computed: {

  },
  created() {
    this.getListInit()
  },
  mounted() {
    this.getListInit()
  },
  methods: {
    // 文件删除
    handleRemove(val) { 
      for (let i = 0; i < this.resFileList.length; i++) {
        if (this.resFileList[i].resourceId === val.resourceId) {
          this.resFileList.splice(i, 1) 
        }
      }
      // 文件删除后，需要更新业务需要的文件列表数据
      let array = []
      this.resFileList.forEach((item) => {
        array.push({
          id: item.id,
          fileExt: item.suffix,
          fileName: item.fileName,
          filePath: item.filePath,
          url: item.filePath,
          fileSize: item.fileSize,
          modifyStatus: item.modifyStatus
        })
      }) 
      this.$emit('on-oss-success', array, this.$refs['upload'].uploadFiles)
    },
    // 初始化文件列表
    getListInit() { 
      if (this.fileList.length > 0) {
        // el-upload组件文件列表，防止未知错误，请勿删除 
        // 获取文件列表并渲染组件内文件列表数据
        this.resFileList = this.fileList   
      } else {
        this.$nextTick(() => {
          this.resFileList = [] 
        })
      }
    },
    // 文件上传拦截
    handleBeforeUpload(file) { 
      let array = this.resFileList.filter((item) => item.modifyStatus != 3)
      if (array.length >= Number(this.limit)) {
        Message.closeAll()
        Message.error(`最多上传${this.limit}个文件`)
        return false
      }
      let suffixArray = file.name.split('.')
      let imgtype = suffixArray[suffixArray.length - 1]
      // 判断文件类型
      if (!this.acceptString.toUpperCase().match(imgtype.toUpperCase())) {
        // this.$message.error(file.name + '文件不符合上传条件')
        this.$message.error(`请上传符合${this.acceptString}类型的文件`)
        return false
      }
    },
    // 执行上传
    handleUploadFile(option) { 
      let _self = this
      // 文件大小判断
      let sizeType = this.fileSizeFormatter(option.file.size)
      if (sizeType === '未知大小') {
        this.$message.error(option.file.name + '文件过大，请上传4G以下文件')
        return
      }
      if (sizeType === '文件太小') {
        this.$message.error(option.file.name + '文件过小，请检查后重新上传')
        return
      }
      // 文件后缀截取
      let suffixArray = option.file.name.split('.')
      let imgtype = suffixArray[suffixArray.length - 1]
      // 判断文件类型
      if (!this.acceptString.toUpperCase().match(imgtype.toUpperCase())) {
        // this.$message.error(option.file.name + '文件不符合上传条件')
        this.$message.error(`请上传符合${this.acceptString}类型的文件`)
        return
      }
      this.resFileList.push({
        fileName: option.file.name,
        fileSize: option.file.size,
        fileUid: option.file.uid,
        suffix: imgtype.toLowerCase(),
        percentage: 0,
        modifyStatus: 1,
        status: 'ready',
        fileLoading: true
      })
      // 文件上传中禁止点击保存按钮，用于业务的新增和修改页面，对“保存”“保存并送审”的处理
    //   bus.$emit('btnDisabled', 'loading')
      // 文件读取操作，用于获取文件md5操作
      var dataFile = option.file
      if (option.file.size > 1000000000) {
        const sliceLength = 10
        const chunkSize = Math.ceil(dataFile.size / sliceLength)
        const fileReader = new FileReader()
        const getmd5 = new SparkMD5()
        let index = 0
        const loadFile = () => {
          const slice = dataFile.slice(index, index + chunkSize)
          fileReader.readAsBinaryString(slice)
        }
        loadFile()
        fileReader.onload = (e) => {
          getmd5.appendBinary(e.target.result)
          if (index < dataFile.size) {
            index += chunkSize
            loadFile()
          } else {
            _self.ossStsRegisterApi(option, getmd5.end())
          }
        }
        fileReader.onerror = function (e) {
          for (let i = 0; i < _self.resFileList.length; i++) {
            if (_self.resFileList[i].fileUid == option.file.uid) {
              _self.resFileList.splice(i, 1)
            }
          }
          _self.$message.error('文件读取失败，请重新选择')
          // 解除对“保存”“保存并送审”的限制点击操作
        //   bus.$emit('btnDisabled', 'done')
        }
      } else {
        // 文件读取
        var fileReader = new FileReader()
        var spark = new SparkMD5.ArrayBuffer()
        // 获取文件二进制数据
        fileReader.readAsArrayBuffer(dataFile)
        fileReader.onload = function (e) {
          spark.append(e.target.result)
          // 获取文件文件md5
          let md5 = spark.end()
          _self.ossStsRegisterApi(option, md5)
        }
        fileReader.onerror = function (e) {
          for (let i = 0; i < _self.resFileList.length; i++) {
            if (_self.resFileList[i].fileUid == option.file.uid) {
              _self.resFileList.splice(i, 1)
            }
          }
          _self.$message.error('文件读取失败，请重新选择')
          // 解除对“保存”“保存并送审”的限制点击操作
        //   bus.$emit('btnDisabled', 'done')
        }
      }
    },
    // oss上传第一步需要，调用后端接口，判断文件是否存在
    ossStsRegisterApi(option, fileMd5) {
      // 文件后缀获取
      let suffixArray = option.file.name.split('.')
      let imgtype = suffixArray[suffixArray.length - 1]
      // 接口参数拼接
      let params = {
        fileName: option.file.name,
        extName: imgtype,
        fileSize: option.file.size,
        hashcode: fileMd5
      }
      ossStsRegister(params).then((res) => {
        if (res.code === 200) {
          const response = res.data
          // 文件不存在需重新上传
          if (response.existed != 1) {
            this.resFileList.forEach((item) => {
              if (item.fileUid == option.file.uid) {
                item.resourceId = response.resourceId
                item.resourceName = response.resourceName
                item.fileMd5 = response.fileMd5
                item.fileLoading = false
                item.filePath = response.uri
                item.url = response.uri
                item.file = option
              }
            })
            this.getFileSize(response, option)
          }
          // 文件存在
          else {
            this.resFileList.forEach((item) => {
              if (item.fileUid == option.file.uid) {
                item.resourceId = response.resourceId
                item.resourceName = response.resourceName
                item.fileMd5 = response.fileMd5
                item.fileLoading = false
                item.url = response.uri
                item.filePath = response.uri
                item.file = option
                item.percentage = 100
                item.modifyStatus = 1
                item.status = 'success'
              }
            })

            let readyArray = this.resFileList.filter(
              (item) => item.status == 'ready'
            )
            if (readyArray.length == 0) {
            //   bus.$emit('btnDisabled', 'done')
            }
            let array = []
            this.resFileList.forEach((item) => {
              let params = {
                fileExt: item.suffix,
                fileName: item.fileName,
                url: item.filePath,
                filePath: item.filePath,
                fileSize: item.fileSize,
                modifyStatus: item.modifyStatus
              }
              if (item.id != undefined) {
                params.id = item.id
              }
              array.push(params)
            })
            if (this.contractType) {
            //   this.$emit('change', array[0])
              this.$emit('on-oss-success', array[0], this.$refs['upload'].uploadFiles)
            } else {
            //   this.$emit('change', array)
              this.$emit('on-oss-success', array, this.$refs['upload'].uploadFiles)
            }
          }
        } else {
          for (let i = 0; i < this.resFileList.length; i++) {
            if (this.resFileList[i].fileUid == option.file.uid) {
              this.resFileList.splice(i, 1)
            } 
          }
          Message.error({
            showClose: true,
            message: option.file.name + '上传失败, 请重试',
            type: 'error'
          })
        }
      }).catch(()=>{
        for (let i = 0; i < this.resFileList.length; i++) {
            if (this.resFileList[i].fileUid == option.file.uid) {
              this.resFileList.splice(i, 1)
            } 
          } 
      })
    },
    // 根据文件实际大小，确定文件应该调用哪个oss方法上传（根据实际情况可进行调整）
    // 阿里云sdk地址：https://help.aliyun.com/document_detail/383952.html
    getFileSize(response, option) {
      let _self = this
      // 文件大于100k小于等于100m
      if (option.file.size > 10000000 && option.file.size <= 100000000) {
        _self.getMultipartOssClient(response, option.file)
      }
      // 文件小于100k
      else if (option.file.size > 0 && option.file.size <= 10000000) {
        _self.getOssClient(response, option.file)
      }
      // 文件大于100m
      else {
        _self.getBigMultipartOssClient(response, option.file)
      }
    },
    // 小文件（oss文件鉴权&简单上传）
    getOssClient(response, params) {
      // oss初始化
      const client = new OSS({
        region: 'oss-cn-zhangjiakou',
        accessKeyId: response.accessKey,
        accessKeySecret: response.accessSecret,
        stsToken: response.accessToken,
        bucket: response.bucketName
      })
      this.putFile(response, client, params)
    },
    // 小文件上传
    putFile(response, client, params) {
      let _self = this
      client
        .put(response.resourceName, params)
        .then(function (result) {
          // 上传成功
          if (result.res.status == 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params, 'little')
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params, 'little')
        })
    },
    // 中文件（oss文件鉴权&分片上传）
    getMultipartOssClient(response, params) {
      // oss初始化
      const client = new OSS({
        region: 'oss-cn-zhangjiakou',
        accessKeyId: response.accessKey,
        accessKeySecret: response.accessSecret,
        stsToken: response.accessToken,
        bucket: response.bucketName
      })
      this.putMultipartFile(response, client, params)
    },
    // 中文件上传
    putMultipartFile(response, client, params) {
      let _self = this
      // 调用上传方法
      client
        .multipartUpload(response.resourceName, params, {
          progress: function (p, cpt, res) {
            _self.resFileList.forEach((item) => {
              if (item.status !== 'success' && item.fileUid == cpt.file.uid) {
                _self.$nextTick(() => {
                  item.abortCheckpoint = cpt
                  let percentage = p * 100
                  item.percentage = percentage.toFixed(0)
                })
              }
            })
          }
        })
        .then(function (result) {
          // 上传成功
          if (result.res.status == 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params)
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params)
        })
    },
    // 大文件（oss文件鉴权&分片上传）
    getBigMultipartOssClient(response, params) {
      let _self = this
      // oss初始化
      const client = new OSS({
        region: 'oss-cn-zhangjiakou',
        accessKeyId: response.accessKey,
        accessKeySecret: response.accessSecret,
        stsToken: response.accessToken,
        bucket: response.bucketName
      })
      // 保存连接client用于断点续传
      _self.resFileList.forEach((item) => {
        if (item.fileUid === params.uid) {
          item.client = item.client
        }
      })
      this.putBigMultipartFile(response, client, params)
    },
    // 大文件上传
    putBigMultipartFile(response, client, params) {
      let _self = this
      // 调用上传方法
      client
        .multipartUpload(response.resourceName, params, {
          parallel: 4,
          partSize: 1024 * 1024,
          progress: function (p, cpt, res) {
            _self.resFileList.forEach((item) => {
              if (item.fileUid === cpt.file.uid) {
                _self.$nextTick(() => {
                  item.abortCheckpoint = cpt
                  let percentage = p * 100
                  item.percentage = percentage.toFixed(0)
                })
              }
            })
          }
        })
        .then(function (result) {
          // 上传成功
          if (result.res.status === 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params, 'big')
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params, 'big')
        })
    },
    // 文件上传失败回调
    handleError(err, file, type) {
      console.log('文件上传失败回调')
      // 小文件
      if (type === 'little') {
        for (let i = 0; i < this.resFileList.length; i++) {
          if (this.resFileList[i].fileUid === file.uid) {
            this.resFileList.splice(i, 1)
          }
        }
        this.$message.error('上传失败, 请重试')
      }
      // todo 中文件
      else if (type === 'middle') {
        this.handleResumeUpload(file)
      }
      // todo 大文件
      else if (type === 'big') {
      }
      // todo 断点续传后的上传失败
      else {
        for (let i = 0; i < this.resFileList.length; i++) {
          if (this.resFileList[i].fileUid === file.uid) {
            this.resFileList.splice(i, 1)
          }
        }
        this.$message.error('上传失败, 请重试')
      }

      let readyArray = this.resFileList.filter(
        (item) => item.status === 'ready'
      )
      if (readyArray.length === 0) {
        // bus.$emit('btnDisabled', 'done')
      }
    },
    // 由于某些原因导致上传失败断点续传
    handleResumeUpload(params) {
      let listItem = null
      let client = null
      const _self = this
      // 找到失败的那一项
      _self.resFileList.forEach((item) => {
        if (item.fileUid === params.uid) {
          listItem = item
          client = item.client
        }
      })
      // 执行重新上传
      client
        .multipartUpload(listItem.resourceName, params, {
          checkpoint: listItem.abortCheckpoint,
          progress: function (p, cpt, res) {
            _self.resFileList.forEach((item) => {
              if (item.fileUid === cpt.file.uid) {
                item.abortCheckpoint = cpt
                let percentage = p * 100
                item.percentage = percentage.toFixed(0)
              }
            })
          }
        })
        .then(function (result) {
          // 上传成功
          if (result.res.status === 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params, 'little')
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params, 'middle')
        })
    },
    // 文件上传成功回调
    handleSuccess(resourceName) {
      let _self = this
      _self.resFileList.filter(i=> i.resourceName).forEach((item) => {
        if (item.resourceName.search(resourceName) !== -1) {
          _self.ossRegisterSuccess(item.resourceId)
        }
      })
    },
    // 文件上传成功-后台通用回调
    ossRegisterSuccess(resourceId) {
      let _self = this
      ossStsRegisterCallback(resourceId).then((res) => {
        if (res.code === 200) {
          // 文件列表处理，设置文件上传状态
          _self.resFileList.forEach((item) => {
            if (item.resourceId === resourceId) {
              item.status = 'success'
              item.percentage = 100
            }
          })
          // 判断文件列表中是否有文件还未上传完，若没有则恢复“保存”“保存并送审”按钮可点击状态
          let readyArray = _self.resFileList.filter(
            (item) => item.status === 'ready'
          )
          if (readyArray.length === 0) {
            // bus.$emit('btnDisabled', 'done')
          }
          // 文件上传成功后，拼接数据返回页面
          let array = []
          _self.resFileList.forEach((item) => {
            let params = {
              fileExt: item.suffix,
              fileName: item.fileName,
              filePath: item.filePath,
              url: item.filePath,
              fileSize: item.fileSize,
              modifyStatus: item.modifyStatus
            }
            if (item.id !== undefined) {
              params.id = item.id
            }
            array.push(params)
          })
          // 合同相关特殊处理，返回第一条给页面
          if (this.contractType) {
            
            this.$emit('on-oss-success', array[0], this.$refs['upload'].uploadFiles)
            // uploadSuccess(response, file, fileList) {
            // this.$emit('change', array[0])
          } else {
            // this.$emit('change', array)
            this.$emit('on-oss-success', array, this.$refs['upload'].uploadFiles)
          }
        }
      })
    },
    // 电脑系统判断
    checkPcSystem() {
      if (/macintosh|mac os x/i.test(navigator.userAgent)) {
        return 'mac'
      } else if (/windows|win32/i.test(navigator.userAgent)) {
        return 'windows'
      }
    },
    // 文件大小转换
    fileSizeFormatter(size, type) {
      if (!size) {
        if (type == '1') {
          return ''
        } else {
          return '文件太小'
        }
      }
      // 电脑系统判断，因为mac和win的文件大小计算规则不同
      let checkSystem = this.checkPcSystem()
      var num = checkSystem == 'mac' ? 1000.0 : 1024.0 //byte
      if (Number(size) - num <= 0) return size + 'B'
      if (Number(size) - Math.pow(num, 2) <= 0)
        return (Number(size) / num).toFixed(2) + 'K' //kb
      if (Number(size) - Math.pow(num, 3) < 0)
        return (Number(size) / Math.pow(num, 2)).toFixed(2) + 'M' //M
      if (Number(size) - Math.pow(num, 3) * 4 <= 0)
        return (Number(size) / Math.pow(num, 3)).toFixed(2) + 'G' //G·
      return '未知大小'
    },
    // 上传成功回调
    handleUploadSuccess(response) {
      if (this.isShowSuccessTip) {
        // pass
      }
      if (response.code === 200) {
        // 将图片类型的数据筛选出来单独缓存，用来图片预览时的上下翻页。
        const path = response.data.link
        if (path) {
          this.acceptImageType.forEach((suffix) => {
            if (path.endsWith(suffix)) {
              // pass
            }
          })
        }
        if (response.data.link !== undefined) {
          // pass
        } else if (this.listButton) {
          this.$message.success('上传成功')
        }
      } else {
        // this.$message.error('文件上传失败，请重试！')
        // pass
      }
    },
  }
}
</script>


<style lang="scss" scoped>

</style>
