<template>
  <div>
    <page-top-title name="订单详情-供应商"></page-top-title>
    <div class="box">
      <div style="margin:10px">
        <el-table :data="datalist">
          <el-table-column prop="name" label="商品名称">
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量">
            <template slot-scope="scope"></template>
          </el-table-column>
          <el-table-column prop="unit" label="单价(元)">
            <template slot-scope="scope"></template>
          </el-table-column>
          <el-table-column prop="total" label="合计(元)">
            <template slot-scope="scope"></template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align:right">
        运费金额（元）：500 / 合计金额（元）：5500
      </div>
    </div>

    <div class="box bigBox">
      <div style="width:30%">
        <page-top-title name="详细信息"></page-top-title>
        <div class="intrudct textCss">
          <div>
            <div>
              详细信息
            </div>
            <div>
              收货地址：张小凡
              <div>
                需求编号：1384009564531415926
              </div>
              <div>
                创建时间：2022-12-26 12:00:00
              </div>
              <div>
                订单编号：1384009564531415926
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width:65%;margin-left:3%">
        <page-top-title name="运单信息"></page-top-title>
        <div class="right">
          <el-table :data="datalist" height="300">
            <el-table-column prop="name" label="商品名称">
            </el-table-column>
            <el-table-column prop="num" label="数量">
            </el-table-column>
            <el-table-column prop="unit" label="单价(元)">
            </el-table-column>
            <el-table-column prop="total" label="合计(元)">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      datalist: [{
        title: '哇哇哇哇哇',
        name: "轩辕剑",
        unit: "盏",
        num: '6688',
        total: '6688000'
      },
      {
        name: "轩辕剑",
        unit: "盏",
        num: '6688',
        total: '6688000'
      },
      {
        name: "轩辕剑",
        unit: "盏",
        num: '6688',
        total: '6688000'
      }]
    }
  },
  components: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  },
}

</script>
<style scoped lang='scss'>
.box {
  box-sizing: border-box;
  padding: 15px 0px;



  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: #303133;
    font-size: 14px;
    margin: 12px;
  }

  .backBox {
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(235, 120, 12, 0.2);
    margin: 10px 0;
    width: 400px;
    height: 160px;
    text-align: right;

    .flex-end {
      display: flex;
      justify-content: flex-end;

      .orange {
        display: block;
        color: #e65a09;
        font-size: 22px;
      }
    }

    .title {
      color: #606266;
      font-size: 14px;
      margin: 6px;
    }


  }

  .bigBox {
    display: flex;

    .intrudct {
      position: relative;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #409EFF;
      background-color: rgba(184, 213, 240, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 400px;

      div {
        margin: 4px
      }

      .imgbox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        img {
          height: 140px;
          width: 140px;
        }
      }

      .post {
        position: absolute;
        right: 10px;
        bottom: 5px;
        text-align: center;

        .orange {
          color: #E6A23C;
          font-size: 26px;
        }

      }

    }

    .textCss {
      width: 100%;
      color: #909399;
      font-size: 14px;
      line-height: 25px;
      word-break: break-all;
    }

    .right {
      height: 400px;
      width: 100%;
      word-break: break-all;
      border: 1px solid #409EFF;
      box-sizing: border-box;
      padding: 10px;
    }
  }

  .el-input {
    width: 330px
  }

  .el-select {
    width: 420px
  }

}
</style>