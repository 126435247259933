<style scoped>
.el-form-item__content {
  line-height: 25px !important;
  position: relative;
  font-size: 14px;
  margin-top: 7px;
}
</style>
<template>
  <div>
    <page-top-title name="取消订单记录"></page-top-title>
    <order-water :txtArray="txtArray" :waterArrays="['down', 'pay', 'comment']" :customArray="['提交申请', '退款处理中', '处理完成']"
      :step="+refundStep" />
    <div class="box" style="display: flex; flex-wrap: wrap">
      <div class="intrudct textCss">
        <div>
          <div style="font-weight: bold; color: #409eff">详细信息</div>
          <el-form label-width="100px" :inline="false">
            <el-form-item label="收货地址：" style="margin-top: 10px">
              <div class="el-form-item__content">
                {{ form.orderAddress.name }}
                {{ form.orderAddress.contactPhone }}
                {{ form.orderAddress.province }}
                {{ form.orderAddress.city }}
                {{ form.orderAddress.district }}
                {{ form.orderAddress.address }}
              </div>
            </el-form-item>
            <el-form-item v-if="form && form.orderInfo && (form.orderInfo.orderSource != 0)" label="需求编号："
              style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.demandCode }}
              </div>
            </el-form-item>
            <el-form-item label="支付方式：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ orderPayMethod[form.orderInfo.payMethod] }}
                <el-button type="text" style="padding: 0"
                  v-if="form.orderInfo.payMethod == 4 && form.orderInfo.certificateStatus != 0"
                  @click="dialogLogisticVisible = true">查看凭证</el-button>
              </div>
            </el-form-item>
            <el-dialog title="汇款凭证" append-to-body :visible.sync="dialogLogisticVisible" destroy-on-close width="400px">
              <el-image v-if="form.orderInfo.certificateUrl" style=" width: 360px" :src="form.orderInfo.certificateUrl">
                <div slot="error" class="image-slotimg">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-dialog>
            <el-form-item label="支付流水号：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.payFlowCode }}
              </div>
            </el-form-item>
            <el-form-item label="支付时间：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.payDate }}
              </div>
            </el-form-item>
            <el-form-item label="创建时间：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.createTime }}
              </div>
            </el-form-item>
            <el-form-item label="订单编号：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.orderCode }}
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="right">
        <template>
          <div class="righTopBox">
            <div style="font-size: 22px; color: #409eff; margin: 10px 0">
              订单编号：{{ form.orderInfo.orderCode }}
            </div>
            <div style="">
              支付金额（元）：
              <span style="color: #e6a23c">{{
      (+form.actualPayment || 0).toFixed(2)
    }}</span>
              &nbsp;&nbsp;&nbsp;&nbsp; 支付方式：{{
        orderPayMethod[form.orderInfo.payMethod]
      }}
            </div>
          </div>
          <div>
            <el-table :data="refundDetailEntities" border stripe height="275">
              <el-table-column prop="createTime" label="处理时间"></el-table-column>
              <el-table-column prop="refundReasonName" label="处理信息" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span :style="{ color: getRowInfo(scope.row.status, 'color') }">
                    {{ getRowInfo(scope.row.status, "label") }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="operateSource" label="操作人">
                <template slot-scope="scope">
                  {{
      { 0: "买家", 1: "卖家", 2: "平台", 4: "暂无" }[
      scope.row.operateSource === ""
        ? 4
        : scope.row.operateSource
      ]
    }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </div>
    </div>
    <template v-if="form.orderInfo.orderType == 2 && form.pinStatus == 3">
      <scrabbleDough />
    </template>
    <div class="box">
      <div style="margin: 10px">
        <div class="flex-table borderMain">
          <div> {{ (form.tenderType == '' || form.tenderType == '0') ? '商品名称' : '招标名称' }}</div>
          <div>{{ (form.tenderType == '' || form.tenderType == '0') ? '数量' : '招标项目' }}</div>
          <div v-if="form.tenderType == '' || form.tenderType == '0'">单价（元）</div>
          <div>{{ (form.tenderType == '' || form.tenderType == '0') ? '合计（元）' : '中标金额' }}</div>
          <div>物流单号</div>
        </div>
        <div v-for="(i, d) in form.shopDTOList" :key="d">
          <div class="bord-bottom">{{ i.supplierName }}</div>
          <div>
            <div v-for="(v, k) in i.goodsDTOList" :key="k" class="flex-table">
              <div :class="isWidthEnough ? 'name-box' : 'name-box-small'">
                <div><img :src="v.fileUrl" @error="dealErr($event)" /></div>
                <div class="txt">{{ form.tenderType == '' || form.tenderType == '0' ? v.skuName : form.tenderName }}</div>
              </div>
              <div>{{ form.tenderType == '' || form.tenderType == '0' ? v.skuNum : form.projectName }}</div>
              <div v-if="form.tenderType == '' || form.tenderType == '0'">{{ v.skuPriceShow ? v.skuPriceShow : '' }}</div>
              <div v-if="form.tenderType == '' || form.tenderType == '0'">{{ v.skuPriceShow ? v.skuNum * v.skuPriceShow : '' }}
              </div>
              <div style="color: #ff7826" v-if="form.tenderType != '' && form.tenderType != '0'">{{ form.skuAmount }}</div>
              <div class="waterClass">
                <div v-if="k == 0" class="waterItem" :style="{ top: 52 * (i.goodsDTOList.length - 1) + 'px' }">
                  <div v-for="item in form.orderLogistics" :key="item.id">
                    {{ item.logisticsCode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="title">给卖家留言 :</div>
            <div>
              <el-input v-model="i.orderLeaveWord" placeholder="选填，字数不可多于500" type="textarea" maxlength="500"
                show-word-limit rows="7" disabled></el-input>
            </div>
          </div>

        </div>
      </div>
      <div class="total">
        <span>商品金额(元):<span class="price">&nbsp;{{ form.skuAmount }}</span>&nbsp;&nbsp;/运费金额(元):<span
            class="price">&nbsp;{{
      form.freight }}</span>
          <span v-if="form.orderInfo.isUseVoucher">
            &nbsp;&nbsp;/{{ typeMap[form.orderInfo.voucherType] }}抵扣金额(元):<span class="price">&nbsp;{{ form.payVoucherAmount }}</span>
          </span>
          &nbsp;&nbsp;/实付款(元):<span class="price">&nbsp;{{ form.actualPayment }}</span></span>
      </div>
      <div class="box" v-if="form.orderInfo.isInvoice == 1">
        <page-top-title name="发票信息"></page-top-title>
        <el-form :model="form.orderInvoice" label-width="80px" :inline="false">
          <el-form-item label="发票要求">
            <el-select disabled v-model="form.orderInvoice.invoiceType" placeholder="请选择发票要求" clearable filterable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-row class="supplymode-menu" type="flex">
            <el-col class="active">
              {{ form.orderInvoice.invoiceClass == 2 ? "企业" : "个人"  }}
            </el-col>
          </el-row>
          <div style="height: 10px"></div>
          <div>
            <el-form :model="form.orderInvoice" label-width="90px" :inline="true" disabled>
              <el-form-item label="发票抬头 : ">
                <el-input v-model="form.orderInvoice.invoiceTitle" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="税号 : " v-if="form.orderInvoice.invoiceClass == 2">
                <el-input v-model="form.orderInvoice.invoiceRateCode" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="企业地址 : " v-if="form.orderInvoice.invoiceClass == 2">
                <el-input v-model="form.orderInvoice.companyAddress" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="企业电话 : " v-if="form.orderInvoice.invoiceClass == 2">
                <el-input v-model="form.orderInvoice.companyPhone" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="开户银行 : " v-if="form.orderInvoice.invoiceClass == 2">
                <el-input v-model="form.orderInvoice.bankName" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="银行账户 : " v-if="form.orderInvoice.invoiceClass == 2">
                <el-input v-model="form.orderInvoice.bankAccount" placeholder="请输入"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { defaultForm } from "./default";
import { detail } from "@/api/purchaser/myBiddingOrder";
export default {
  data() {
    return {
      dialogLogisticVisible:false,
      form: { ...defaultForm },
      refundDetailEntities: [],
      options: [
        { value: 2, label: "无需发票" },
        { value: 1, label: "增值税普通发票" },
        { value: 0, label: "增值税专用发票" },
      ],
      isWidthEnough: true,
      refundStep: "",
      txtArray: [],
      orderPayMethod: {
        1: "银行卡支付",
        2: "支付宝",
        3: "微信",
        4: "线下汇款",
      },
      refundInfo: [
        {
          value: 0,
          label: "卖家审核中",
          color: "#FE911D",
        },
        {
          value: 1,
          label: "卖家审核通过",
          color: "#67C23A",
        },
        {
          value: 2,
          label: "卖家拒绝退款",
          color: "#FE6477",
        },
        {
          value: 3,
          label: "平台审核中",
          color: "#0079FE",
        },
        {
          value: 4,
          label: "平台审核完成",
          color: "#67C23A",
        },
        {
          value: 5,
          label: "平台拒绝",
          color: "#f5c533",
        },
        {
          value: 6,
          label: "退款完成",
          color: "#67C23A",
        },
        {
          value: 7,
          label: "买家已撤销",
          color: "#757575",
        },
        // {
        //     value: 8,
        //     label: '供应商申请平台介入',
        //     color: '#818283'
        // },
        {
          value: 9,
          label: "待卖家收货",
          color: "#409eff",
        },
        {
          value: 10,
          label: "卖家已收货",
          color: "#67c23a",
        },
        {
          value: 11,
          label: "退款失败",
          color: "#757575",
        },
        {
          value: 12,
          label: "要求买家提供资料",
          color: "#818283",
        },
        {
          value: 13,
          label: "要求卖家提供资料",
          color: "#818283",
        },
        {
          value: 14,
          label: "卖家审核中", // 二次申请 状态
          color: "#FE911D",
        },
      ],
      typeMap: {
        1: '消费券',
        2: '账期',
        3: '智慧工地硬件消费券',
        4: '邀请消费券'
      }
    };
  },
  components: {},
  created() { },
  mounted() {
    if (document.body.offsetWidth < 1450) {
      this.isWidthEnough = false;
    }
    this.detail();
  },
  methods: {
    dealErr(e) {
      e.target.src = require("@/assets/error.svg");
    },
    formattingData(arr, group_key) {
      let map = {};
      let res = [];
      for (let i = 0; i < arr.length; i++) {
        let ai = arr[i];
        if (!map[ai[group_key]]) {
          map[ai[group_key]] = [ai];
        } else {
          map[ai[group_key]].push(ai);
        }
      }
      Object.keys(map).forEach((key) => {
        res.push({
          [group_key]: key,
          goodsDTOList: map[key],
        });
      });

      return res;
    },
    detail() {
      detail({ id: this.$route.query.id }).then((res) => {
        if (res.code != 200) {
          // this.$message.warning(res.msg);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1200);
        }
        this.form = Object.assign({ ...defaultForm }, res.data);
        this.refundDetailEntities = this.form.refund.refundDetailEntities;
        this.form.shopDTOList = [];
        this.form.shopDTOList = this.formattingData(
          this.form.orderGoods,
          "supplierName"
        );
        // "refundApplicationDate": "申请时间",
        // "refundIngDate": "处理中时间",
        // "refundEndDate": "处理完成时间",
        if (res.data.refundApplicationDate == "") {
          this.refundStep = 0;
        } else if (
          res.data.refundApplicationDate != "" &&
          res.data.refundIngDate == ""
        ) {
          this.refundStep = 1;
        } else if (
          res.data.refundApplicationDate != "" &&
          res.data.refundIngDate != "" &&
          res.data.refundEndDate == ""
        ) {
          this.refundStep = 2;
        } else if (
          res.data.refundApplicationDate != "" &&
          res.data.refundIngDate != "" &&
          res.data.refundEndDate != ""
        ) {
          this.refundStep = 3;
        }
        this.txtArray = [
          res.data.refundApplicationDate,
          res.data.refundIngDate,
          res.data.refundEndDate,
        ];
      });
    },
    getRowInfo(status, key) {
      let val = key == "color" ? "#cccccc" : "";
      if (
        this.refundInfo.filter((item) => {
          return item.value == status;
        }).length > 0
      ) {
        val = this.refundInfo.filter((item) => {
          return item.value == status;
        })[0][key];
      }
      return val;
    },
  },
};
</script>
<style scoped lang='scss'>
.total {
  font-size: 16px;
  padding: 25px 0;
  text-align: right;
  color: #666;
  margin-bottom: 10px;

  .price {
    color: #0079fe;
  }
}

.box {
  box-sizing: border-box;
  padding: 15px 0px;

  .flex-table {
    margin: 10px -40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #606266;

    div {
      width: 100%;
      text-align: center;
    }

    .waterClass {
      position: relative;
      margin-top: -20px;

      .waterItem {
        font-size: 13px;
        position: absolute;
      }
    }

    .name-box {
      box-sizing: border-box;
      padding: 0 20px;
      line-height: 20px;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 13px;

      .txt {
        text-align: left;
      }

      img {
        width: 100px;
        height: 100px;
        margin-right: 5px;
      }
    }

    .name-box-small {
      box-sizing: border-box;
      padding: 0 20px;
      line-height: 20px;
      text-align: left;
      font-size: 13px;

      .txt {
        text-align: left;
      }

      img {
        width: 150px;
        height: 80px;
        margin-bottom: 5px;
      }
    }
  }

  .borderMain {
    margin: 10px -40px;
    border-top: 1px solid #f2f6fc;
    border-bottom: 8px solid #f2f6fc;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .bord-bottom {
    margin: 0 -40px;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    border-bottom: 2px solid #f2f6fc;
  }

  .supplymode-menu {
    color: #606266;
    text-align: center;
    margin-bottom: 10px;

    .el-col {
      height: 31px;
      line-height: 31px;
      width: 110px;
      margin-right: 20px;
      cursor: pointer;

      i {
        font-size: 20px;
        color: $blue;
        vertical-align: -0.1em;
      }
    }

    .el-col:nth-child(1),
    .el-col:nth-child(2) {
      position: relative;
      line-height: 28px;
      border: 2px solid #ccc;

      &.active {
        border: 2px solid $blue;
        color: $blue;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          border: 7.5px solid $blue;
          border-top-color: transparent;
          border-left-color: transparent;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          border: none;
          width: 8px;
          height: 7px;
          background: url(./selected.svg) center center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: #303133;
    font-size: 14px;
    margin: 12px;
  }

  .backBox {
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(235, 120, 12, 0.2);
    margin: 10px 0;
    width: 400px;
    height: 160px;
    text-align: right;

    .flex-end {
      display: flex;
      justify-content: flex-end;

      .orange {
        display: block;
        color: #e65a09;
        font-size: 22px;
      }
    }

    .title {
      color: #606266;
      font-size: 14px;
      margin: 6px;
    }
  }

  .intrudct {
    position: relative;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #409eff;
    background-color: rgba(184, 213, 240, 0.1);

    .post {
      position: absolute;
      right: 10px;
      bottom: 5px;
      text-align: center;

      .orange {
        color: #e6a23c;
        font-size: 26px;
      }
    }
  }

  .textCss {
    flex-shrink: 0;
    width: 30%;
    color: #909399;
    font-size: 14px;
    line-height: 25px;
    word-break: break-all;

    .inte {
      display: flex;

      div {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .right {
    flex-shrink: 0;
    margin-left: 3%;
    width: 65%;
    word-break: break-all;
    border: 1px solid #409eff;

    .righTopBox {
      box-sizing: border-box;
      padding: 30px;
      background-color: rgba(255, 69, 0, 0.02);

      div {
        margin: 5px 0;
      }
    }
  }

  .el-input {
    width: 330px;
  }

  .el-select {
    width: 420px;
  }
}
</style>