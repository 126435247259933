import request from '@/utils/request'

//获取用户信息
export function getUserInfo (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberInfo/detail',
    method: 'get',
    params: data
  })
}
// 判断管理员信息/ecomm/memberInfo/isAdmin
export function getIsAdmin (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberInfo/isAdmin',
    method: 'get',
    params: data
  })
}
//获取账户余额
export function getBalance () {
  return request({
    url: '/api/rs-member-service/ecomm/memberAccount/accountBalance',
    method: 'get'
  })
}

//修改用户信息
export function updUserInfo (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberInfo/saveMemberInfo',
    method: 'post',
    data: data
  })
}

//uploadFile
export function uploadFile (data) {
  return request({
    url: '/api/huizhuyun-realname/realname-attachment/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}

//权益列表
export function memberHandling () {
  return request({
    url: '/api/rs-member-service/ecomm/memberVipInfo/page?current=1&size=10',
    method: 'get'
  })
}

//获取商品分类列表
export function getTypeTree () {
  return request({
    url: '/api/rs-cms-service/ecomm/front/cms/homePage/product/catagory',
    method: 'get',
  })
}

