<template>
  <div v-if="this.statuss != 1">
    <div class="scrabbleDough">
    	<div class="scrabbleDough_t">拼团</div>
    	<div class="scrabbleDough_list">
    		<div v-for="(item,index) in userArr" :key="index">
    			<img :src="item.accountHeader" alt=""><span>{{item.nickName}}</span>
    		</div>
    	</div>
    	<img src="../images/no.png" alt="" class="scrabbleDoughNo" v-if="this.statuss == 3"> 
		<img src="../images/succs.png" alt="" class="scrabbleDoughNo" v-if="this.statuss == 2">
    </div>
  </div>
</template>

<script>
import { detail } from '@/api/purchaser/myBiddingOrder'
export default {
  data () {
    return {
		status:"",
		userArr:[],
		statuss:"",
    }
  },
  components: {

  },
  created () {

  },
  mounted () {
	this.detail()
	
  },
  methods: {
    handleChange () { },
	detail () {
      detail({ id: this.$route.query.id }).then(res => {
        console.log(res,7777788888899999)
		this.status = res.data.orderInfo.status
		this.statuss = res.data.pinStatus
		this.userArr = res.data.pinOrderMember
      })
    },
  },
}

</script>
<style scoped lang='scss'>
.scrabbleDough {
	width: 100%;
	margin-top: 10px;
	padding-top: 15px;
	min-height: 150px;
	padding-bottom: 15px;
	border: 1px solid #c4d5fc;
	position: relative;
	.scrabbleDoughNo {position: absolute;z-index: 999;width: 89px;height: 91px;right: 0px;top: 13px;}
	.scrabbleDough_t {line-height: 30px;height: 30px;width: 32px; margin-left: 35px;font-size: 16px;color: #666;border-bottom: 2px solid #1367ff;}
	.scrabbleDough_list {width: 100%;margin-top: 20px;
		div {width: 78px;margin-left: 35px;display: inline-block;
			img {width: 78px;height: 78px;float: left;}
			span {width: 78px;float: left;font-size: 12px;color: #333;line-height: 34px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;text-align: center;}
		}
	}
}
</style>