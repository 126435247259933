import { isZhaobiao } from './zhaobiao'
import { isShop } from './shop'
export default function def (ctx) {
  async function switchType (windThis) {
    let type = windThis.$route.query.orderSource;
    if (type == 3) {
      await isZhaobiao(ctx, windThis)
    } else if (type == 0) {
      await isShop(ctx, windThis)
    }
  }
  return { switchType }
}
