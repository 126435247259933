
const go = {
  state: {
    breadCrumbList: [{
      name: '基本信息',
      path: '/'
    }],
    biddingIsEditedList: []
  },
  mutations: {
    //刷新页面重置面包屑
    refresh_BREAD: (state) => {
      let breadList = JSON.parse(window.localStorage.getItem('breadCrumbList')) || []
      if (breadList.length) {
        state.breadCrumbList = breadList
      }
    },
    //清空面包屑
    RESET_BREAD: (state) => {
      state.breadCrumbList = [];
    },
    //前进面包屑
    SET_BREAD: (state, path) => {
      if (state.breadCrumbList.some(item => { return item.path == path.path })) {
        return
      } else {
        state.breadCrumbList.push({
          ...path
        })
      }
      window.localStorage.setItem('breadCrumbList', JSON.stringify(state.breadCrumbList))
    },
    //后退面包屑
    DEL_BREAD: (state, pathName = window.location.href) => {
      let filterList = state.breadCrumbList.filter(item => !pathName.includes(item.path))
      state.breadCrumbList = [...filterList]
      window.localStorage.setItem('breadCrumbList', JSON.stringify(state.breadCrumbList))
    },
    SET_BIDDINGISEDITED (state, payload) {
      state.biddingIsEditedList.push(payload)
    },
    // 清空所点击标签的所有子级标签
    CLEAR_SUBLEVEL (state, p) {
      let currentIndex = -1
      state.breadCrumbList.forEach((item, index) => {
        if (item.path == p) {
          currentIndex = index
        }

      })
      if (currentIndex != -1) state.breadCrumbList = state.breadCrumbList.slice(0, currentIndex + 1)
    }
  },
}

export default go
