<template>
  <div>
    <!-- <keep-alive :includes="[]"> -->
    <keep-alive>   
      <router-view v-if="key == '/supplier/notice/index'" :key="key" />
    </keep-alive>
    <router-view v-if="key != '/supplier/notice/index'" :key="key" />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { clearCookie } from '@/utils/auth.js'
import { queryMenuList } from '@/api/login'
import { getToken } from '@/utils/auth'
import { platformSiteList} from "@/api/notice/notice";
export default {
  name: 'AppMain',
  computed: {
    key () {
      return this.$route.path
    }
  },
  async created () {
    console.log(this.$router)
    //页面防止刷新路由丢失//页面防止刷新动态长参数丢失
    if (window.localStorage.getItem('TheParams')) {
      const params = JSON.parse(window.localStorage.getItem('TheParams'));
      this.$store.commit('SET_PARAMS', params);
    }
    if (window.localStorage.getItem('menu')) {
      const menu = JSON.parse(window.localStorage.getItem('menu'));
      if (menu.length) this.$store.commit('SET_ROUTES', menu);
      if(getToken()) {
        const menuListRes = await queryMenuList()
        if(menuListRes.success) {
          window.localStorage.setItem("showMenu", JSON.stringify(menuListRes.data));
        }
      }
    } 
    // else {
    //   clearCookie()
    //   window.localStorage.clear()
    //   this.$router.push('/')
    // }

    
    //页面防止刷新面包屑、丢失
    this.$store.commit('refresh_BREAD')

    let TheUrl = process.env.VUE_APP_SHARE_FRONT_WEB_URL;

    let platData=  await platformSiteList()
    console.log(platData,'platData')
    let platformConfigData = null
    if(platData){
       platformConfigData = platData.data.find(item=> location.origin===item.baseUrl)
    }
    if(platformConfigData) {
      this.$store.commit('SET_PLATFORMCONFIGDATA', platformConfigData);
    }
    console.log(platformConfigData,'platformConfigData-app')
    if(platformConfigData&&platformConfigData.gxyCenter){
       TheUrl = platformConfigData.gxyCenter
    }else{
      if(location.origin == process.env.VUE_APP_LCIDP_SUB_DOMAIN_CENTER) {
        TheUrl = process.env.VUE_APP_LCIDP_SUB_DOMAIN
      }

      if(location.origin == process.env.VUE_APP_LCIDP_JC_DOMAIN_CENTER) {
        TheUrl = process.env.VUE_APP_LCIDP_JC_DOMAIN
      }
      if(location.origin == process.env.VUE_APP_LCIDP_LW_DOMAIN_CENTER) {
        TheUrl = process.env.VUE_APP_LCIDP_LW_DOMAIN
      }
      if(location.origin == process.env.VUE_APP_LCIDP_SB_DOMAIN_CENTER) {
        TheUrl = process.env.VUE_APP_LCIDP_SB_DOMAIN
      }
      if(location.origin == process.env.VUE_APP_LCIDP_XM_DOMAIN_CENTER) {
        TheUrl = process.env.VUE_APP_LCIDP_XM_DOMAIN
      }
      if(location.origin == process.env.VUE_APP_LCIDP_JR_DOMAIN_CENTER) {
        TheUrl = process.env.VUE_APP_LCIDP_JR_DOMAIN
      }
    }
    console.log('TheUrl',TheUrl)
    Vue.prototype.$JumpUrl = TheUrl
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
</style>
