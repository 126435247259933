import webSee from '@websee/core';
// import { getStore } from "@/util/store"; // 需要替换成移动端获取存储中菜单信息【currentCheckMenu】的
import Vue from 'vue'
import { injectTracker } from "@/api/logs"; //这个接口是下文中向后端汇报的借口 
// import func from "@/util/func";
import { getUserInfo } from '@/utils/auth';
import store from '../store'
Vue.use(webSee, {
  dsn: 'unset', // 上报的地址,这个就写 unset 就行 ，因为是自定义上报
  apikey: 'rs-xxx', // 项目唯一的id
  // userId: userInfoBean?.id || '', // 用户id
  silentWhiteScreen: false, // 开启白屏检测
  skeletonProject: false, // 项目是否有骨架屏
  maxBreadcrumbs:5,
  silentHistory:true,
  silentClick: true, // 取消监听点击报错
  silentXhr: true, // 取消监听xhr请求报错
  silentFetch: false, // 取消监听fetch请求报错
  silentError: true,
  maxEvents: 1,
  useImgUpload: true,
  silentHashchange: false, // 取消监听hashchange报错
  silentUnhandledrejection: false, // 取消监听promise报错
  beforeDataReport: (data) => {
    return false
  },
  //用户行为 0按钮、1浏览、2检索、3数据输入,4停留统计，5异常,(8,菜单点击 9,tab点击,8,9从属0)
  //beforePushBreadcrumb 现在是监控了 点击事件-0，跳转浏览-1，错误-5，因为还需要分清楚点击事件具体是主页面的 tab 还是菜单，所以又分出来 2 个状态 8 跟 状态 9，需要注意一下什么情况下分配
  beforePushBreadcrumb: (data) => {
    let action = ''
    switch (data.category) {
      case 'Route': //浏览跳转
        action = '1'
        break
      case 'Click': //点击
        if(data.data.indexOf('el-menu-item') !== -1){
          action = '8' //点击菜单
          break
        }
        else if(data.data.indexOf('el-tabs__item is-top is-closable is-focus') !== -1){
          action = '9' //点击菜单的 tab
          break
        }else{
          action = '0' //点击按钮等会监听到
          break
        }
      case 'Http':
        action = '5' // 接口错误
        break
    }
    // store.state.go.breadCrumbList
    const routeInfoArr = store.state?.go?.breadCrumbList ?? []
    let targetRouteInfo = null
    if(routeInfoArr.length > 0) {
      targetRouteInfo = routeInfoArr[routeInfoArr.length - 1]
    }
    const userInfoStr = getUserInfo()
    let userInfoBean = null;
    if(userInfoStr) {
      userInfoBean = JSON.parse(userInfoStr)
    }
    //拼装上报借口参数
    const trackData = {
      dataSource: store?.state?.platformConfigData?.dataSource,
      action:action,//上报类型，上文中的 action
      createTime:'',
      realName:userInfoBean?.niceName|| '', //上报人
      from:action === '1' ? data.data.from : action === '0' ? location.pathname : '', //类型为浏览的时候-来源路由
      to:action === '1' ? data.data.to : action === '0' ? location.pathname : '',//类型为浏览的时候-跳转路由
      resolution:window.screen.width + '*' + window.screen.height,
      stayDuration:'',
      tenantId: userInfoBean?.tenantId || '', //租户 id
      tenantName: '',
      userId: userInfoBean?.id || '', //租户名称
      viewOptionContent: (data.category === 'Route' ? data.data.to : (data.category === 'Click' ? data.data : data.status === 'error' ? data.data.message : '')),//如果是点击事件需要上报菜单名称，如果是错误直接上报自定义的错误信息。如果是路由跳转事件，则需要把跳转成功后的路由添加上
      // viewOptionName:(data.category === 'Route' ? `${getStore({name:'currentCheckMenu'})}#*${getStore({name:'tag'}).value}` : (data.category === 'Click' ?  `${getStore({name:'currentCheckMenu'})}#*${getStore({name:'tag'}).value}` :data.status === 'error' ? getStore({name:'tag'}).label : '')),//规则同上，需要在【currentCheckMenu】拼接一个路由名称
      viewOptionName:(data.category === 'Route' ? `${location.href}` : (data.category === 'Click' ?  `${location.href}` :data.status === 'error' ? `${location.href}` : '')),//规则同上，需要在【currentCheckMenu】拼接一个路由名称
      other: ``, //路由名称
    }
    if(data.category == 'Custom') {
      const customData = JSON.parse(data.data);
      trackData.other = customData.other ?? null;
      trackData.viewOptionName = customData.viewOptionName ?? null;
    } else {
      //-------------客户端用到的筛选条件-------------------
      if(action == 1) {
        trackData.toPathname = data.data.to.split('?')[0]
      } else if (action == 0) {
        trackData.pathname = location.pathname
      }
      //--------------------------------------------------
      ActionManager.getInstance().matchRuleAndChangeData(trackData)
    }
    const url = injectTracker();
    // const isProduction = process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "local";
    const isProduction = process.env.NODE_ENV === "production";
    const actionArr = ['1','0','8','9']
    // if (isProduction &&  !trackData.viewOptionName && (actionArr.includes(action) || (action === '5' && data.status === 'error'))) {
    if ((actionArr.includes(action) || (action === '5' && data.status === 'error'))) {
      navigator.sendBeacon(url, JSON.stringify(trackData));
      // 通过 sendBeacon 形式上报数据，不阻塞页面
    }
  }
});

class ActionManager {
  constructor() {
    if (!ActionManager.instance) {
      ActionManager.instance = this;
    }
    return ActionManager.instance;
  }

  static getInstance() {
    if (!ActionManager.instance) {
      ActionManager.instance = new ActionManager();
    }
    return ActionManager.instance;
  }
  rules = [];
  addRule(rule) {
    this.rules.push(rule);
  }
  matchRuleAndChangeData(data) {
    //先匹配action
    const actionFilteredRules = this.rules.filter(item=> item.action == data.action)
    //再匹配 matchProperty
    if(data.action == 1) {
      if((data.from == data.to) && document.referrer && data.from != document.referrer) {
        data.from = document.referrer
      }

    } else if(data.action == 0) {
      //按钮事件
      data.viewOptionName = removeHtmlTags(data.viewOptionContent)

    }
    for(let rule of actionFilteredRules) {
      const target = rule.matchMethod(rule, data)
      if(target) {
        rule.resetProperty.forEach(property => {
          if(typeof target[`${property}`] === 'function') {
            target[`${property}`](data, target)
          } else if (typeof target[`${property}`] === 'string') {
            data[`${property}`] = target[`${property}`]
          }
        })
        break
      }
    }
  }

}

function Rule(obj) {
  this.action = obj.action;
  this.matchProperty = obj.matchProperty;
  this.resetProperty = obj.resetProperty;
  this.ruleData = obj.ruleData;
  this.matchMethod = obj.matchMethod;
}
Rule.prototype = {
  constructor: Rule,
}

function removeHtmlTags(htmlString) {
  return htmlString.replace(/<[^>]*>/g, '');
}
function getQueryParameter(url, paramName) {
  const regex = new RegExp(`[?&]${paramName}=([^&#]*)`);
  const match = url.match(regex);
  return match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : null;
};

//根据matchProperty键值对逐项equal判等， rule: Rule, data: trackData return 是否命中
const ruleMethod_equal = (rule, data) => {
  const target = rule.ruleData.find(item=> {
    return rule.matchProperty.every(entry => {
      return item[`${entry.ruleDataKey}`] == data[`${entry.trackDataKey}`]
    })
  })
  return target
};
//根据matchProperty键值对逐项比对包含关系，trackData的key属性是否包含 rule的value属性属性， ， rule: Rule, data: trackData return 是否命中
const ruleMethod_contain = (rule, data) => {
  const target = rule.ruleData.find(item=> {
    return rule.matchProperty.every(entry => {
      return data[`${entry.trackDataKey}`] && data[`${entry.ruleDataKey}`].includes(item[`${entry.ruleDataKey}`])
    })
  })
  return target
}

//要根据to重新赋值other字段 和viewOptionName 字段
const no_conflict_with_option_pages = new Rule({action: '1', matchProperty: [{trackDataKey: 'toPathname', ruleDataKey: 'to'}], resetProperty: ['other', 'viewOptionName'], ruleData: [
  {to: '/sc-supplier/bid/bid_company_detail', other: '招标详情', viewOptionName: (trackData, ruleData) => {trackData.viewOptionName = getQueryParameter(trackData.to, 'id')} },
  
  
], matchMethod: ruleMethod_equal})
ActionManager.getInstance().addRule(no_conflict_with_option_pages)
