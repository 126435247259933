import { validatenull } from './validate';
import { MessageBox } from 'element-ui'

/**
* 验证是否存在true/false
*/
const vaildData = function (val, dafult) {
	if (typeof val === 'boolean') {
		return val;
	}
	return !validatenull(val) ? val : dafult;
}
/**
* 验证两个对象是否一样
*/
const isObjectValueEqual = function (a, b) {
	// 判断两个对象是否指向同一内存，指向同一内存返回 true
	if (a === b) return true // 获取两个对象键值数组
	let aProps = Object.getOwnPropertyNames(a)
	let bProps = Object.getOwnPropertyNames(b)
	// 判断两个对象键值数组长度是否一致，不一致返回 false
	if (aProps.length !== bProps.length) return false // 遍历对象的键值
	for (let prop in a) {
		// 判断 a 的键值，在 b 中是否存在，不存在，返回 false
		if (b.hasOwnProperty(prop)) {
			// 判断 a 的键值是否为对象，是则递归，不是对象直接判断键值是否相等，不相等返回 false
			if (typeof a[prop] === 'object') {
				if (!isObjectValueEqual(a[prop], b[prop])) return false
			} else if (a[prop] !== b[prop]) {
				return false
			}
		} else {
			return false
		}
	}
	return true
}

/**
 * 初始化数据格式
 */
// export const initVal = (value, safe) => {
// 	let { type, multiple, dataType, separator = DIC_SPLIT, alone, emitPath, range } = safe
// 	let list = value;
// 	if (
// 		(MULTIPLE_LIST.includes(type) && multiple == true) ||
// 		(ARRAY_VALUE_LIST.includes(type) && emitPath !== false) ||
// 		(RANGE_LIST.includes(type) && range == true)
// 	) {
// 		if (!Array.isArray(list)) {
// 			if (validatenull(list)) {
// 				list = [];
// 			} else {
// 				list = (list + '').split(separator) || [];
// 			}
// 		}
// 		// 数据转化
// 		list.forEach((ele, index) => {
// 			list[index] = detailDataType(ele, dataType);
// 		});
// 		if (ARRAY_LIST.includes(type) && validatenull(list) && alone) list = [''];
// 	} else {
// 		list = detailDataType(list, dataType)
// 	}
// 	return list;
// };


/**
 * 去某字段
 * data:Object 数据源
 * keys:array 要删除的key集合
 */

export function removeKey(object, keys = ['id']) {
	/* `Object.entries()`方法返回一个给定对象自身可枚举属性的键值对数组，其排列与使用 for...in 循环遍历该对象时返回的顺序一致（区别在于 for-in 循环也枚举原型链中的属性）。 以上来自MDN的介绍，经过 Object.entries 咱们能够获得一个 [key, value] 排列的数组，方便接下来的 reduce 直接使用。 */
	return Object.entries(object).reduce((acc, [key, value]) => {
		if (keys.includes(key)) {
			return acc
		}
		if (toString.call(value) === '[object Object]') {
			const filteredValue = removeKey(value)
			return { ...acc, [key]: filteredValue }
		}
		if (toString.call(value) === '[object Array]') {
			// let arr = []
			for (let i = 0; i < value.length; i++) {
				const item = value[i];
				for (let j = 0; j < keys.length; j++) {
					const _k = keys[j];
					delete item[_k]
				}
				// arr.push(item)
			}
		}
		return { ...acc, [key]: value }
	}, {})
}

/**
 * 继承对象
 */

export function extend() {
	var target = arguments[0] || {};
	var deep = false;
	var arr = Array.prototype.slice.call(arguments);
	var i = 1;
	var options, src, key, copy;
	var isArray = false;
	if (typeof target === 'boolean') {
		deep = target;
		i++;
		target = arguments[1];
	}
	for (; i < arr.length; i++) { // 循环传入的对象数组
		if ((options = arr[i]) != null) { // 如果当前值不是null，如果是null不做处理
			for (key in options) { // for in循环对象中key
				copy = options[key];
				src = target[key];
				// 如果对象中value值任然是一个引用类型
				if (deep && (toString.call(copy) === '[object Object]' || (isArray = toString.call(copy) == '[object Array]'))) {
					if (isArray) { // 如果引用类型是数组
						// 如果目标对象target存在当前key，且数据类型是数组，那就还原此值，如果不是就定义成一个空数组;
						src = toString.call(src) === '[object Array]' ? src : [];
					} else {
						// 如果目标对象target存在当前key，且数据类型是对象，那就还原此值，如果不是就定义成一个空对象;
						src = toString.call(src) === '[object Object]' ? src : {};
					}
					// 引用类型就再次调用extend，递归，直到此时copy是一个基本类型的值。
					target[key] = extend(deep, src, copy);
				} else if (copy !== undefined && copy !== src) { // 如果这个值是基本值类型，且不是undefined
					target[key] = copy;
				}
			}
		}
	}
	return target;
}

export function createObj(obj, bind) {
	let list = bind.split('.');
	let first = list.splice(0, 1)[0];
	let deep = {};
	deep[first] = {};
	if (list.length >= 2) {
		let start = '{';
		let end = '}';
		let result = '';
		list.forEach(ele => {
			result = `${result}${start}"${ele}":`;
		});
		result = `${result}""`;
		for (let i = 0; i < list.length; i++) {
			result = `${result}${end}`;
		}
		result = JSON.parse(result);
		deep[first] = result;
	}
	obj = extend(true, obj, deep);
	return obj;
}


export const isMediaType = (url, type) => {
	if (typeList.audio.test(url) || type == 'audio') {
		return 'audio'
	} else if (typeList.video.test(url) || type == 'video') {
		return 'video'
	} else if (typeList.img.test(url) || type == 'img') {
		return 'img'
	}
}

/**
 * 获取顶部地址栏地址
 */
export const getTopUrl = () => {
	return window.location.href.split("/#/")[0];
}

/**
 * 获取url参数
 * @param name 参数名
 */
export const getQueryString = (name) => {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	let r = window.location.search.substr(1).match(reg);
	if (r != null) return unescape(decodeURI(r[2]));
	return null;
}
/**
 * 替换url参数
 * @param url www.baidu.com?sex=男
 * @param arg 需要替换的参数名 eg: sex
 * @param val 参数值替换成 eg: 女
 */
export const changeUrlArg = (url, arg, val) => {
	var pattern = arg + '=([^&]*)';
	var replaceText = arg + '=' + val;
	return url.match(pattern) ? url.replace(eval('/(' + arg + '=)([^&]*)/gi'), replaceText) : (url.match('[\?]') ? url + '&' + replaceText : url + '?' + replaceText);
}


/*
  秒转成时间：xx天xx时xx分xx秒   ：  -
  formatTime(num)
	* 参数： 秒
	* 返回值： {}数据返回(灵活一点)
 */
function setDb(num) {
	//补零操作
	if (num < 10) {
		return '0' + num;
	} else {
		return '' + num;
	}
}
export const formatTime = (num) => {
	//num是秒数    98876秒  有多少天： xx天xx时xx分xx秒
	var sec = setDb(num % 60); //06 秒
	var min = setDb(Math.floor(num / 60) % 60); //Math.floor(num / 60) % 60     分
	var hour = setDb(Math.floor(num / 60 / 60) % 24); //时
	var day = setDb(Math.floor(num / 60 / 60 / 24)); //天数
	// var mon = setDb()

	return {
		secs: sec,
		mins: min,
		hours: hour,
		days: day
	}

}

/**
 * element ui 确认框
 * 使用示例: this.handleCofirm('确认删除该标签吗？', 'warning',).then(res => {
		 // 
	  }).catch(err => {
		console.log('err', err) // cancel
		// 
	  })
 */
const handleCofirm = function (text = '确定执行此操作吗？', type = 'warning', center = false) {
	return MessageBox.confirm(text, '提示', {
		cancelButtonText: '取消',
		confirmButtonText: '确定',
		type: type, //success，error，info和warning
		center: center, // 居中布局
	})
}
const numberToChinese = function (num) {
	const digitMap = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
	const unitMap = ["", "十", "百", "千", "万", "亿"];
   
	if (num < 10) {
	  return digitMap[num];
	} else if (num < 100) {
	  if (num % 10 === 0) {
		return digitMap[Math.floor(num / 10)] + unitMap[1];
	  } else {
		return digitMap[Math.floor(num / 10)] + unitMap[1] + digitMap[num % 10];
	  }
	} else {
	  let unitIndex = 0;
	  let remainder = num;
	  while (remainder >= 10) {
		remainder = Math.floor(remainder / 10);
		unitIndex++;
	  }
	  return digitMap[remainder] + unitMap[unitIndex] + numberToChinese(num % (10 ** unitIndex));
	}
  }
const util = {}
util.install = function (Vue, options) {
	Vue.prototype.vaildData = vaildData
	Vue.prototype.isObjectValueEqual = isObjectValueEqual
	Vue.prototype.handleCofirm = handleCofirm
	Vue.prototype.numberToChinese=numberToChinese
}
export default util
export const  matchType=(fileName)=> {
	// 后缀获取
	var suffix = '';
	// 获取类型结果
	var result = '';
	try {
	  var flieArr = fileName.split('.');
	  suffix = flieArr[flieArr.length - 1];
	} catch (err) {
	  suffix = '';
	}
	// fileName无后缀返回 false
	if (!suffix) {
	  result = false;
	  return result;
	}
	// 图片格式
	var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
	// 进行图片匹配
	result = imglist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'image';
	  return result;
	}
	// 匹配txt
	var txtlist = ['txt'];
	result = txtlist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'txt';
	  return result;
	}
	// 匹配 excel
	var excelist = ['xls', 'xlsx'];
	result = excelist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'excel';
	  return result;
	}
	// 匹配 word
	var wordlist = ['doc', 'docx'];
	result = wordlist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'word';
	  return result;
	}
	// 匹配 pdf
	var pdflist = ['pdf'];
	result = pdflist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'pdf';
	  return result;
	}
	// 匹配 ppt
	var pptlist = ['ppt'];
	result = pptlist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'ppt';
	  return result;
	}
	// 匹配 视频
	var videolist = ['mp4', 'm2v', 'mkv'];
	result = videolist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'video';
	  return result;
	}
	// 匹配 音频
	var radiolist = ['mp3', 'wav', 'wmv'];
	result = radiolist.some(function (item) {
	  return item == suffix;
	});
	if (result) {
	  result = 'radio';
	  return result;
	}
	// 其他 文件类型
	result = 'other';
	return result;
  }