import { setAsyncRoutes } from '@/router/async'

const permission = {
  state: {
    routes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      // 动态配置路由
      setAsyncRoutes(routes);
      // 为了防止用户刷新页面导致动态创建的路由失效，将其存储在本地中
      // 将路由存储在 store 中
      state.routes = routes;
    }
  },
}

export default permission
