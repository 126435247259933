export const KEY_COMPONENT_NAME = 'm-';
export const DIC_PROPS = {
  rowKey: 'id',
  rowParentKey: 'parentId',
  nodeKey: 'id',
  label: 'label',
  value: 'value',
  desc: 'desc',
  groups: 'groups',
  title: 'title',
  leaf: 'leaf',
  children: 'children',
  hasChildren: 'hasChildren',
  labelText: '名称',
  disabled: 'disabled'
};
export const DIC_HTTP_PROPS = {
  name: 'name',
  url: 'url',
  fileName: 'file',
  res: ''
};
export const DATE_LIST = [
  'dates',
  'date',
  'datetime',
  'datetimerange',
  'daterange',
  'time',
  'timerange',
  'week',
  'month',
  'monthrange',
  'year'
];

export const ARRAY_LIST = ['img', 'array', 'url'];
export const RANGE_LIST = ['slider']
export const ARRAY_VALUE_LIST = ARRAY_LIST.concat(['dynamic', 'checkbox', 'cascader', 'dates']);
export const MULTIPLE_LIST = ['cascader', 'tree', 'select'];
export const DIC_SPLIT = ',';

// 登录信息中identity(采购1 供应2)和数据的映射关系
export const DIC_Identity = {
  '1': '0',
  '2': '1'
};