import request from '@/utils/request'

// 登录第一步方法
export function login (data) {
  return request({
    url: `/api/blade-auth/oauth/tenant?username=${data.username}&password=${data.password}`,
    method: 'get',
    // params: data,
    // showLoad: true,//配置是否显示load,默认为true
    loadFull: true//配置是否全屏load,默认为false
  })
}

// 登录第二步方法:params:grant_type=password、scope=all、type=account
export function goLogin (data) {
  return request({
    url: `/api/blade-auth/oauth/token`,
    method: 'post',
    params: data,
    headers: { 'Tenant-Id': data.tenantId }
  })
}

// 注册方法
export function registerUrls (data) {
  return request({
    url: `/api/blade-system/tenant/register?phoneNumber=${data.phoneNumber}&code=${data.code}`,
    method: 'post',
    data: data
  })
}

// 退出方法
export function logout () {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCode (data) {
  return request({
    url: `/api/blade-system/tenant/exist/check?phoneNumber=${data.phoneNumber}`,
    method: 'post',
    data: data
  })
}
//发送验证码
export function sendCode (data) {
  return request({
    url: `/api/blade-resource/sms/endpoint/verification-code?templateId=&code=&phone=${data.phone}`,
    method: 'post',
    data: data
  })
}

//发送支付验证码
export function sendPayCode (data) {
  return request({
    url: `/api/rs-member-service/ecomm/memberInfo/send-verifyCode`,
    method: 'post',
    data: data
  })
}

//用户选择登录身份
export function choseIdent (data) {
  return request({
    url: `/api/rs-member-service/ecomm/memberInfo/selectIdentity`,
    method: 'get',
    params: data
  })
}

//获取路由列表
export function queryMenuList () {
  return request({
    url: `/api/rs-member-service/ecomm/memberInfo/menus?dataSource=1`,
    method: 'get'
  })
}

//忘记登录密码输入手机号获取所拥有手机得隶属公司
export function telInputGetComList (data) {
  return request({
    url: `/api/huizhuyun-support/common/user/tenant/list/phone?phoneNumber=${data.phoneNumber}`,
    method: 'get'
  })

}
//忘记密码第一步检测用户输入信息正确与否
export function isValidCodeTelIsRight (data) {
  return request({
    url: `/api/huizhuyun-support/common/sms/code/verify?phoneNumber=${data.phoneNumber}&code=${data.code}`,
    method: 'get'
  })
}
//忘记密码确认重置
export function sureSubmitForgetPassord (data) {
  return request({
    url: `/api/huizhuyun-support/common/user/update/password/self?userId=${data.userId}&code=${data.code}&password=${data.password}`,
    method: 'post',
    data: data
  })
}
//忘记登录密码重置
export function forgetLgPasswordReSet (data) {
  return request({
    url: `/api/blade-user/update-password?oldPassword=${data.oldPassword}&newPassword=${data.newPassword}&newPassword1=${data.newPassword1}`,
    method: 'post',
    data: data
  })
}
/**
 * 检查租户是否创建完成
 * @param phoneNumber
 * @returns {AxiosPromise}
 */
export const activeUser = (tenantId, account, code, requestCode, password, identityCode) => request({
  url: '/api/blade-user/active',
  method: 'post',
  params: {
    tenantId,
    account,
    code,
    requestCode,
    password,
    identityCode
  }
});

//获取全部路由
export function allRouteMenus () {
  return request({
    url: `/api/rs-member-service/ecomm/memberInfo/allRouteMenus`,
    method: 'get'
  })
}