export const defaultForm = {
  "actualPayment": 0,
  "createDept": 0,
  "createTime": "",
  "createUser": 0,
  "freight": 0,
  "id": 0,
  "isDeleted": 0,
  "memberAccountFlow": {
    "accountCode": "",
    "accountId": 0,
    "accountName": "",
    "businessId": 0,
    "cny": 0,
    "createDept": 0,
    "createTime": "",
    "createUser": 0,
    "dataSource": 0,
    "dealCash": 0,
    "dealTime": "",
    "dealType": "",
    "fundFlow": "",
    "id": 0,
    "isDeleted": 0,
    "operSubject": "",
    "orderCode": "",
    "paymentType": "",
    "remarks": "",
    "serialNumber": "",
    "status": 0,
    "updateTime": "",
    "updateUser": 0
  },
  "orderAddress": {
    "address": "",
    "addressType": 0,
    "city": "",
    "cityCode": "",
    "contactPhone": "",
    "createDept": 0,
    "createTime": "",
    "createUser": 0,
    "dataSource": 0,
    "district": "",
    "districtCode": "",
    "id": 0,
    "isDefault": 0,
    "isDeleted": 0,
    "name": "",
    "orderCode": "",
    "orderId": 0,
    "postalCode": "",
    "province": "",
    "provinceCode": "",
    "remarks": "",
    "status": 0,
    "updateTime": "",
    "updateUser": 0
  },
  "orderEvaluate": [
    {
      "buyerId": 0,
      "buyerMemberId": 0,
      "buyerName": "",
      "content": "",
      "createDept": 0,
      "createTime": "",
      "createUser": 0,
      "dataSource": 0,
      "evalSource": 0,
      "id": 0,
      "isDeleted": 0,
      "orderCode": "",
      "orderId": 0,
      "remarks": "",
      "score": 0,
      "status": 0,
      "supplierId": 0,
      "supplierMemberId": 0,
      "supplierName": "",
      "updateTime": "",
      "updateUser": 0
    }
  ],
  "orderGoods": [
    {
      "createDept": 0,
      "createTime": "",
      "createUser": 0,
      "dataSource": 0,
      "freightPrice": 0,
      "id": 0,
      "isDeleted": 0,
      "linkmanPhone": "",
      "orderCode": "",
      "orderGoodsFileEntity": {
        "createDept": 0,
        "createTime": "",
        "createUser": 0,
        "dataSource": 0,
        "fileName": "",
        "fileStatus": 0,
        "fileType": 0,
        "fileUrl": "",
        "id": 0,
        "isDeleted": 0,
        "orderId": 0,
        "productId": 0,
        "remarks": "",
        "status": 0,
        "updateTime": "",
        "updateUser": 0
      },
      "orderId": 0,
      "productCategoryCode": "",
      "productCategoryId": 0,
      "remarks": "",
      "skuCode": "",
      "skuId": 0,
      "skuName": "",
      "skuNum": 0,
      "skuPrice": 0,
      "skuSpec": "",
      "status": 0,
      "supplierId": 0,
      "supplierMemberId": 0,
      "supplierName": "",
      "totalPrice": 0,
      "totalPriceVO": 0,
      "unit": "",
      "updateTime": "",
      "updateUser": 0
    }
  ],
  "orderInfo": {
    "buyerId": 0,
    "buyerMemberId": 0,
    "buyerName": "",
    "corAccountId": 0,
    "createDept": 0,
    "createTime": "",
    "createUser": 0,
    "dataSource": 0,
    "demandCode": "",
    "demandId": 0,
    "demandName": "",
    "evaluateStatus": 0,
    "frightPrice": 0,
    "id": 0,
    "isDeleted": 0,
    "isInvoice": 0,
    "logisticsCode": [],
    "orderCode": "",
    "orderInfoEntities": [
      {}
    ],
    "orderLeaveWord": "",
    "orderSource": 0,
    "orderType": 0,
    "payDate": "",
    "payFlowCode": "",
    "payMethod": 0,
    "payPrice": 0,
    "payStatus": 0,
    "remarks": "",
    "shopId": 0,
    "skuName": "",
    "status": 0,
    "supplierId": 0,
    "supplierMemberId": 0,
    "supplierName": "",
    "totalPrice": 0,
    "updateTime": "",
    "updateUser": 0
  },
  "orderInvoice": {
    "bankAccount": "",
    "bankName": "",
    "companyAddress": "",
    "companyPhone": "",
    "createDept": 0,
    "createTime": "",
    "createUser": 0,
    "dataSource": 0,
    "id": 0,
    "invoiceClass": 0,
    "invoiceRateCode": "",
    "invoiceTitle": "",
    "invoiceType": 0,
    "isDeleted": 0,
    "orderCode": "",
    "orderId": 0,
    "remarks": "",
    "status": 0,
    "updateTime": "",
    "updateUser": 0
  },
  "orderLogistics": [
    {
      "createDept": 0,
      "createTime": "",
      "createUser": 0,
      "dataSource": 0,
      "id": 0,
      "isDeleted": 0,
      "logisticsCode": "",
      "logisticsCodes": [],
      "orderId": 0,
      "orderCode": "",
      "remarks": "",
      "status": 0,
      "updateTime": "",
      "updateUser": 0
    }
  ],
  "shopDTOList": [//店铺列表
    {
      "goodsDTOList": [//商品列表
        {
          "id": "",//商品id
          "skuName": "",//商品名称
          "skuSpec": "",//商品规格
          "productCategoryId": "",//商品分类id
          "productCategoryCode": "",//商品分类code
          "unit": "",//单位
          "skuNum": 0,//购买商品数量
          "skuPrice":0,//商品价格(元)
        }
      ],
      "orderLeaveWord": "",//留言
      "freightPriceReal": "",//运费(元)
      "shopName": "",//卖家名称
      "shopId": "",//卖家ID
      "memberInfoId": "",//卖家用户ID
      "businessId": "",//卖家企业ID
    }
  ],
  "skuAmount": 0,
  "status": 0,
  "updateTime": "",
  "updateUser": 0
}