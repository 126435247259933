<template>
  <div>
    <el-input v-model="code" placeholder="请输入验证码" @input="bindValue($event)" :disabled="disInput"></el-input>
    <el-button type="primary" :disabled="disbtn" @click="send">{{ btn }} {{ disbtn ? 's' : '' }}
    </el-button>
  </div>
</template>
 
<script>
import { initSetup } from './prosser'
export default {
  name: 'tel-code',
  props: {
    tel: String,
    disabled: Boolean,
    type: String
  },
  setup (props, { emit }) {
    return {
      ...initSetup(props, emit)
    }
  }
}

</script>
 
<style lang="scss" scoped>
.el-input {
  width: 120px
}

.el-button {
  margin-left: 10px;
}
</style>