<template>
  <div>
    <div class="com">
      <div class="flex">
        <div>
          慧筑云 打造中国建筑产业第一互联网平台 | 服务热线：400-8228-321
        </div>
        <div class="out_box" @click="out">退出</div>
      </div>
      <div class="flex">
        <div @click="goCenter">
          {{ userInfo.companyName || userInfo.nickName || "无" }}
        </div>
        <div style="margin-left: 40px" @click="notice">消息通知</div>
        <el-badge class="item" :value="noCount" :max="99" v-if="noCount > 0" />
        <div
          style="margin-left: 20px"
          @mouseover="showCodeImg = true"
          @mouseout="showCodeImg = false"
        >
          手机慧筑云
        </div>
      </div>
    </div>
    <transition name="qrCodeTransition">
      <div class="imgBox" v-if="showCodeImg">
        <div>
          <div>
            <img src="@/assets/images/gongzhonghao.jpg" />
          </div>
          <div>慧筑云公众号</div>
        </div>
        <div>
          <div>
            <img src="@/assets/images/dianshang.jpg" />
          </div>
          <div>慧筑云电商服务号</div>
        </div>
      </div>
    </transition>
    <div v-if="currentSysType&&currentSysType!='default'" class="nav" style="justify-content: space-between;">
      <div class="unit" @click="goHome">
        <lcidpLogo  :platformSiteData="platformSiteData"/>
      </div>
      <div class="unit-group">
        <div v-if="currentSysType == 'jc'" class="unit" @click="topToHtm(6)">慧询价</div>
        <div
          v-if="currentSysType == 'jc'"
          class="unit"
          @click="topToHtm(70)"
        >
          商城直采
        </div>
        <div v-if="currentSysType == 'xm'" class="unit" @click="topToHtm(4)">项目对接</div>
        <div v-if="currentSysType == 'sb'" class="unit" @click="topToHtm(2)">机械设备</div>
        <div v-if="currentSysType == 'lw'" class="unit" @click="topToHtm(1)">劳务用工</div>
        <div v-if="currentSysType == 'jr'" class="unit" @click="topToHtm(3)">金融服务</div>
      </div>
      <div>
        <div class="tabs">
          <div
            style="margin-left: 20px"
            @click="changeTabs(0)"
            :class="tabsIdx == 0 ? 'tabsChoose' : 'tabsNotChoose'"
          >
            商品
          </div>
          <div
            @click="changeTabs(1)"
            :class="tabsIdx == 1 ? 'tabsChoose' : 'tabsNotChoose'"
          >
            店铺
          </div>
        </div>
        <div class="condition">
          <div>
            <input
              v-model="value"
              class="sinput"
              placeholder="请输入您想要的搜索的内容"
              style="width: 400px;"
            />
          </div>
          <div class="btn" @click="search">搜索</div>
        </div>
      </div>
    </div>
    <div v-else class="nav">
      <div class="unit" @click="goHome">
        <img src="@/assets/logo.png" />
      </div>
      <div class="unit" @click="topToHtm(6)">慧询价</div>
      <div
        class="unit"
        @mouseenter="showNavs"
        @mouseleave="leaveNavs"
        @click="topToHtm(7)"
      >
        商城直采
      </div>
      <!-- <div class="unit" @click="topToHtm(4)">找项目</div> -->
      <div class="unit" @click="topToHtm(2)">找设备</div>
      <div class="unit" @click="topToHtm(1)">找人</div>
      <div class="unit" @click="topToHtm(3)">金融服务</div>
      <div class="unit" @click="topToHtm(5)">智能建造</div>
      <div style="margin-left: 20px">
        <div class="tabs">
          <div
            style="margin-left: 20px"
            @click="changeTabs(0)"
            :class="tabsIdx == 0 ? 'tabsChoose' : 'tabsNotChoose'"
          >
            商品
          </div>
          <div
            @click="changeTabs(1)"
            :class="tabsIdx == 1 ? 'tabsChoose' : 'tabsNotChoose'"
          >
            店铺
          </div>
        </div>
        <!-- <div style="height: 5px"></div> -->
        <div class="condition">
          <div>
            <input
              v-model="value"
              class="sinput"
              placeholder="请输入您想要的搜索的内容"
            />
          </div>
          <div class="btn" @click="search">搜索</div>
        </div>
      </div>
    </div>

    <transition name="qrCodeTransition">
      <div
        class="navShow"
        @mouseenter="showqrCodeTransition"
        @mouseleave="leaveqrCodeTransition"
        v-if="showNav"
        style="animation-duration: 500ms"
      >
        <div
          class="MaterialScience"
          v-for="(item, idx) in navList"
          :key="idx"
          @click="go(item)"
        >
          <div>
            <div>
              <img :src="item.url" style="width: 162px" />
            </div>
            <div style="color: #606266; font-size: 14px">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </transition>
    <div class="breadCrumb" v-if="!isHideLeft">
      <bread-crumb />
    </div>
  </div>
</template>
<script>
import { MessageBox } from "element-ui";
import { getNoReadCount ,platformSiteList} from "@/api/notice/notice";
import { clearCookie, getToken } from "@/utils/auth.js";
import { getTypeTree, getUserInfo } from "@/api/userinfo/userinfo";
import LcidpLogo from './lcidpLogo.vue';
export default {
  components: {
    LcidpLogo
  },
  data() {
    return {
      showqrCode: false,
      alreadyLogin: false,
      navList: [
        // {
        //   title: "慧询价",
        //   url: require("@/assets/images/nav/inquiry.png"),
        //   path: "/preferentialInquiry?ihl=1",
        //   isOver: true,
        // },
        // {
        //   title: "商城直采",
        //   url: require("@/assets/images/nav/mallSelf.png"),
        //   path: "/mallSelfIn?ii=",
        //   isOver: true,
        // },
        {
          title: "地材地图",
          url: require("@/assets/images/nav/map.png"),
          path: "/groundMaterialMap?ihl=1",
          isOver: true,
        },
      ],
      noCount: 0,
      value: "",
      tabsIdx: 0,
      showNav: false,
      showCodeImg: false,
      catagory: [],
      userInfo: {},
      isHideLeft: false,
      currentSysType:'',
      platformSiteData: undefined,
      cyptHomeUrl:''

    };
  },
  computed: {
    // 系统类型: default => 默认, lcidp => 产业平台
    // currentSysType() {
    //   if(location.origin == process.env.VUE_APP_LCIDP_SUB_DOMAIN_CENTER) {
    //     return 'lcidp'
    //   }
    //   if(location.origin == process.env.VUE_APP_LCIDP_JC_DOMAIN_CENTER) {
    //     return 'jc'
    //   }
    //   if(location.origin == process.env.VUE_APP_LCIDP_LW_DOMAIN_CENTER) {
    //     return 'lw'
    //   }
    //   if(location.origin == process.env.VUE_APP_LCIDP_SB_DOMAIN_CENTER) {
    //     return 'sb'
    //   }
    //   if(location.origin == process.env.VUE_APP_LCIDP_XM_DOMAIN_CENTER) {
    //     return 'xm'
    //   }
    //   if(location.origin == process.env.VUE_APP_LCIDP_JR_DOMAIN_CENTER) {
    //     return 'jr'
    //   }
    //   return 'default'
    // },
  },
  mounted() {
    //动态显示头信息
       platformSiteList().then((res) => {
          console.log('res',res)
          if (res.code == 200 && res.data) {
            let platformConfigData = res.data.find(item=> location.origin===item.baseUrl)
            if(platformConfigData){
              console.log('platformConfigData.ngCode',platformConfigData)
              this.currentSysType = platformConfigData.ngCode
              this.platformSiteData = platformConfigData
              this.cyptHomeUrl = platformConfigData.gxyDomain
              document.title = platformConfigData.baseTitle
             var els = document.head.querySelectorAll('[rel=icon]')
              els.forEach(el => {
                el.setAttribute('href', platformConfigData.logoImg)
              })
            }else{
               this.currentSysType = 'default'
            }
          }else {
            this.currentSysType = 'default'
          }
      })

    this.isHideLeft = this.$route.query["ihl"];
    this.alreadyLogin = getToken() || false;
    // let params = JSON.parse(window.localStorage.getItem('params'))
    // if (params.username == '15555555555') {
    //   this.alreadyLogin = false
    // }
    this.getNoRead();
    // this.getTypeTree()
    this.getUserInfo();
  },
  methods: {
    goCenter() {
      this.$router.push("/");
    },
    goHome() {
      //产业平台的跳转产业平台主页
      if(this.currentSysType != 'default') {
        window.open(this.cyptHomeUrl, "_self");
      }
      else{
        window.open(this.$JumpUrl, "_self");
      }
      // let params = JSON.parse(window.localStorage.getItem("params"));
      // if (params && params.isLogin) {
      //   window.open(
      //     `${this.$JumpUrl}?isLogin=true&username=${params.username}&password=${params.password}&chooseIdx=${params.chooseIdx}&choseCom=${params.choseCom}&identity=${params.identity}&userstatus=${params.userstatus}&companyOrgCode=${params.companyOrgCode}`,
      //     "_self"
      //   );
      // } else {
      //   window.open(this.$JumpUrl, "_self");
      // }
    },
    async getUserInfo() {
      let res = await getUserInfo();
      this.userInfo = res.data;
    },
    async getTypeTree() {
      let res = await getTypeTree();
      this.catagory = res.data;
    },
    search() {
      let params = JSON.parse(window.localStorage.getItem("params"));
      if (this.tabsIdx == 0) {
        let p = {
          current: 1,
          size: 40,
          isShowCarousel: "1",
          productName: this.value,
          supportType: "",
        };
        // for (let idx of this.catagory) {
        //   if (idx.name == this.value) {
        //     p.productCategoryCode = idx.code
        //   }
        //   for (let i of idx.children) {
        //     if (i.name == this.value) {
        //       p.productCategoryCode = i.code
        //     }
        //     for (let a of i.children) {
        //       if (a.name == this.value) {
        //         p.productCategoryCode = a.code
        //       }
        //     }
        //   }
        // }
        window.open(
          `${this.$JumpUrl + "/moreGoods?page=" + JSON.stringify(p)}`,
          "_self"
        );
        // window.open(
        //   `${
        //     this.$JumpUrl + "/moreGoods?page=" + JSON.stringify(p)
        //   }&isLogin=true&username=${params.username}&password=${
        //     params.password
        //   }&chooseIdx=${params.chooseIdx}&choseCom=${
        //     params.choseCom
        //   }&identity=${params.identity}&userstatus=${
        //     params.userstatus
        //   }&companyOrgCode=${params.companyOrgCode}`,
        //   "_self"
        // );
      } else {
        let p = {
          current: 1,
          size: 10,
          selfSupport: "",
          shopCityCode: "",
          shopDistrictCode: "",
          shopName: this.value,
        };
        window.open(
          `${this.$JumpUrl + "/shopList?page=" + JSON.stringify(p)}`,
          "_self"
        );
        // window.open(
        //   `${
        //     this.$JumpUrl + "/shopList?page=" + JSON.stringify(p)
        //   }&isLogin=true&username=${params.username}&password=${
        //     params.password
        //   }&chooseIdx=${params.chooseIdx}&choseCom=${
        //     params.choseCom
        //   }&companyOrgCode=${this.userInfo.companyOrgCode}&identity=${
        //     this.userInfo.identity
        //   }&identity=${params.identity}&userstatus=${
        //     params.userstatus
        //   }&companyOrgCode=${params.companyOrgCode}`,
        //   "_self"
        // );
      }
    },
    go(p) {
      if (p.path.includes("/groundMaterialMap")) {
        let page = JSON.stringify({
          size: 5,
          current: 1,
          total: 0,
          companyName: "",
          address: "",
        });
        let params = JSON.parse(window.localStorage.getItem("params"));
        let url;
        // if (params && params.isLogin) {
        //   url = `${this.$JumpUrl + p.path}&page=${page}&isLogin=true&username=${
        //     params.username
        //   }&password=${params.password}&chooseIdx=${
        //     params.chooseIdx
        //   }&choseCom=${params.choseCom}&companyOrgCode=${
        //     this.userInfo.companyOrgCode
        //   }&identity=${this.userInfo.identity}
        //   &userstatus=${params.userstatus}`;
        // } else {
        url = `${this.$JumpUrl + p.path}&page=${page}`;
        // }
        window.open(url, "_self");
        return;
      }
      if (p.isOver) {
        let params = JSON.parse(window.localStorage.getItem("params"));
        // if (params.isLogin) {
        //   let url = `${this.$JumpUrl + p.path}&isLogin=true&username=${
        //     params.username
        //   }&password=${params.password}&chooseIdx=${
        //     params.chooseIdx
        //   }&choseCom=${params.choseCom}&companyOrgCode=${
        //     this.userInfo.companyOrgCode
        //   }&identity=${this.userInfo.identity}&userstatus=${
        //     params.userstatus
        //   }&isShowAd=${window.localStorage.getItem("isShowAd")}`;
        //   window.localStorage.setItem("isShowAd", "");
        //   window.open(url, "_self");
        // } else {
        window.open(this.$JumpUrl + p.path, "_self");
        // }
        return;
      }
      this.$router.push(p.path);
    },
    topToHtm(type) {
      let toUrl = "";
      let isOver = false;
      if (type === 1) {
        //找人
        toUrl = "/labor-services?ihl=1";
        isOver = true;
      }
      if (type === 2) {
        //找设备
        toUrl = "/findDevices?ihl=1";
        isOver = true;
      }

      if (type === 3) {
        //找资金
        toUrl = "/financial-service?ihl=1";
        isOver = true;
      }

      if (type === 4) {
        //找项目
        toUrl = "/findProject?ihl=1";
        isOver = true;
      }
      if (type === 5) {
        //智慧制造
        toUrl = "https://erp.huizhuyun.com?il=1";
        isOver = true;
      }
      if (type === 6) {
        //智慧制造
        toUrl = "/preferentialInquiry?ihl=1";
        isOver = true;
      }
      if (type === 7) {
        //商城直采
        toUrl = "/mallSelfIn?ii=";
        isOver = true;
      }
      if (type === 70) {
        //商城直采
        window.open(this.$JumpUrl + "/mallSelfIn/index-lcidp", "_self");
        return;
      }
      if (isOver) {
        let params = JSON.parse(window.localStorage.getItem("params"));
        if (params.isLogin) {
          if (type == 5) {
            window.open(toUrl);
          }
          window.open(this.$JumpUrl + toUrl, "_self");
          // window.open(
          //   `${this.$JumpUrl + toUrl}&isLogin=true&username=${
          //     params.username
          //   }&password=${params.password}&chooseIdx=${
          //     params.chooseIdx
          //   }&choseCom=${params.choseCom}&identity=${params.identity}&userstatus=${params.userstatus}&companyOrgCode=${params.companyOrgCode}`,
          //   "_self"
          // );
        } else {
          window.open(this.$JumpUrl + toUrl, "_self");
        }
        return;
      }
      this.$router.push(toUrl);
    },
    showqrCodeTransition() {
      this.showqrCode = true;
    },
    leaveqrCodeTransition() {
      this.showqrCode = false;
      this.leaveNavs();
    },
    showNavs() {
      setTimeout(() => {
        this.showNav = true;
      }, 300);
    },
    leaveNavs() {
      setTimeout(() => {
        if (!this.showqrCode) {
          this.showNav = false;
        }
      }, 300);
    },
    changeTabs(idx) {
      this.tabsIdx = idx;
    },
    out() {
      // if (!this.alreadyLogin) {
      //   clearCookie()
      //   window.localStorage.clear()
      //   this.$router.replace('/login')
      //   return
      // }
      MessageBox.confirm(
        "确定退出登录？请认真检查您的表单是否保存",
        "系统提示",
        {
          confirmButtonText: "确认退出",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        clearCookie();
        window.localStorage.clear();
        window.open(
          `${this.$JumpUrl}/preferentialInquiry?ihl=1&handleLogout=true`,
          "_self"
        );
        // path: '/preferentialInquiry?ihl=1',
        // this.$router.replace('/login')
      });
    },
    notice() {
      this.$store.state.go.breadCrumbList = [];
      this.$router.push({ path: "/supplier/notice/index" });
    },
    getNoRead() {
      let params = {};
      getNoReadCount(params)
        .then((response) => {
          this.noCount = response.data.order + response.data.system;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: "系统异常",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.breadCrumb {
  box-sizing: border-box;
  padding-left: calc(16%);
  margin-top: 20px;
}

.com {
  position: relative;
  z-index: 100;
  box-sizing: border-box;
  padding: 12px 15%;
  background-color: rgb(51, 50, 50);
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .flex {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 10;

    .out_box {
      margin-left: 20px;
      color: rgb(219, 132, 17);
    }
  }
}

.qrCodeTransition-enter,
.qrCodeTransition-leave-to {
  transform: translateY(-290px);
}

.qrCodeTransition-enter-active,
.qrCodeTransition-leave-active {
  transition: all 0.7s ease;
}

.imgBox {
  position: absolute;
  right: 13%;
  width: 170px;
  height: 290px;
  box-sizing: border-box;
  padding: 5px 12px 12px 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgb(202, 201, 201);
  z-index: 18;

  div {
    text-align: center;
    margin-top: 5px;
    color: #606266;
    font-size: 16px;
  }

  img {
    width: 95px;
  }
}

.nav {
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  padding: 10px 15% 10px 15%;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;

  .unit {
    min-width: 80px;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    color: #606266;
  }
  
  .unit-group{
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0 20px;
  }

  .tabs {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(0, 121, 254, 1);
    cursor: pointer;

    .tabsChoose {
      background-color: rgba(0, 121, 254, 1);
      color: #fff;
      font-weight: bold;
      box-sizing: border-box;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      padding: 6px 13px;
    }

    .tabsNotChoose {
      font-weight: bold;
      color: rgba(0, 121, 254, 1);
      box-sizing: border-box;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      padding: 6px 13px;
    }
  }

  .condition {
    display: flex;
    align-items: center;

    .sinput {
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 250px;
      border-top-left-radius: 24px !important;
      border-bottom-left-radius: 24px !important;
    }

    .btn {
      box-sizing: border-box;
      padding: 0 18px;
      border-top-right-radius: 24px !important;
      border-bottom-right-radius: 24px !important;
      background-color: rgba(0, 121, 254, 1);
      color: #fff;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      cursor: pointer;
    }
  }
}

.navShow {
  box-shadow: 0px 1px 1px 0 rgb(202, 201, 201);
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 16%;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 7;
  border-top: 1px solid #e6e7e9;

  .MaterialScience {
    line-height: 30px;
    text-align: center;
    box-sizing: border-box;
    min-width: 300px;
    padding: 10px 20px;
    cursor: pointer;
  }
}
</style>
