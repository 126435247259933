import request from '@/utils/request'
//验证码发送
export function identifyingCode (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberInfo/send-verifyCode',
    method: 'post',
    data: data
  })
}
//个人认证提交
// export function pcBtn (data) {
//   return request({
//     url: '/api/rs-member-service/ecomm/memberInfo/submitAuth',
//     method: 'post',
//     data: data
//   })
// }
export function pcBtn (data) {
  return request({
    url: '/api/rs-member-service/ecomm/auth/personal',
    method: 'post',
    data: data
  })
}
//企业认证提交
// export function ccBtn (data) {
//   return request({
//     url: '/api/rs-member-service/ecomm/memberInfo/submitBusinessAuth',
//     method: 'post',
//     data: data
//   })
// }
export function ccBtn (data) {
  return request({
    url: '/api/rs-member-service/ecomm/auth/business',
    method: 'post',
    data: data
  })
}
//用户信息
export function pcDetail (data) {
  return request({
    url: '/api/rs-member-service/ecomm/memberInfo/detail',
    method: 'get',
    params: data
  })
}
//认证信息
export function authDetail (data) {
  return request({
    url: '/api/rs-member-service/ecomm/auth/auth/detail',
    method: 'get',
    params: data
  })
}
// 认证信息修改/ecomm/auth/auth
export function  authChange (data) {
  return request({
    url: '/api/rs-member-service/ecomm/auth/auth',
    method: 'put',
    data: data
  })
}